<template>
  <div>
    <div class="equipment-detail">
      <div class="block d-f j-b a-c">
        <div class="app-active">各时间段终端在线数（台）</div>
        <a-date-picker
          class="data-picker"
          @change="dataPicker"
          :allowClear="false"
          :disabledDate="disabledDate"
          :defaultValue="moment(getCurrentData(), 'YYYY-MM-DD')"
        />
      </div>
      <div class="equipment-detail-ec">
        <div ref="main" class="main"></div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
import moment from "moment";
export default {
  data() {
    return {
      dateString: moment().format("YYYY-MM-DD"),
      onlineList: [],
    };
  },
  watch: {
    onlineList(val, oldVal) {
      this.onlineList = val;
      setTimeout(() => {
        this.myCharts1();
      });
    },
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    moment,
    getCurrentData() {
      return new Date();
    },
    dataPicker(date, dateString) {
      this.dateString = dateString;
      this.getOnlineInfo();
    },
    myCharts1() {
      let myChart = echarts.init(this.$refs.main);
      // 指定图表的配置项和数据
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          data: [
            "0:00",
            "1:00",
            "2:00",
            "3:00",
            "4:00",
            "5:00",
            "6:00",
            "7:00",
            "8:00",
            "9:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
        },
        series: [
          {
            type: "line",
            smooth: 0.3,
            symbol: "none",
            lineStyle: {
              color: "#00AFFF",
              width: 3,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
            },
            areaStyle: {
              color: "rgba(0, 175, 255, 0.3)",
            },
            data: this.onlineList,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#00AFFF",
              type: "dashed",
            },
          },
          backgroundColor: "#009FE8",
          extraCssText: "box-shadow: 0px 2px 8px rgba(0, 159, 232, 0.3)",
          padding: 10,
          formatter: (val) => {
            //返回tooltip的内容及布局样式
            // console.log(val);
            let obj = {};
            val.forEach((r) => {
              obj.time = r.name;
              obj.value = r.value;
            });
            return `
             <div>${this.dateString} ${obj.time}</div>
             <div style="margin-top:10px">设备在线数：${obj.value}</div>
           `;
          },
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    getOnlineInfo() {
      this.$api.getOnlineInfo({ date: this.dateString }).then((res) => {
        if (res.success) {
          let arr = [];
          for (let i = 0; i < 24; i++) {
            arr[i] = 0;
          }
          for (let i = 0; i < 24; i++) {
            res.data.onlineList.forEach((r) => {
              if (i === r.timeValue) {
                arr[i] = r.onlineCount;
              }
            });
          }
          this.onlineList = arr;
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.myCharts1();
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize();
        }
      });
      resizeOb.observe(this.$refs.main);
    });
  },
  created() {
    this.getOnlineInfo();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-calendar-picker-input {
  font-size: 12px;
  height: 22px;
  line-height: 12px;
}
.equipment-detail {
  padding: 16px;
  box-sizing: border-box;
  margin-top: 24px;
  width: 100%;
  height: 318px;
  background: #ffffff;
  opacity: 1;
  border-radius: 16px;
  .block {
    .app-active {
      font-size: 16px;
      font-family: " PingFang SC";
      font-weight: bold;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      opacity: 1;
    }
    .data-picker {
      display: block;
      z-index: 99;
      width: 180px;
      height: 28px;
      background: #ffffff;
      opacity: 1;
      color: #d9dee5;
      border-radius: 2px;
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 400;
      ::v-deep .ant-calendar-picker-input {
        height: 28px;
      }
    }
  }
  .equipment-detail-ec {
    width: 100%;
    height: 260px;
    .main {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
