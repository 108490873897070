<!--
 * @Author: your name
 * @Date: 2021-05-08 11:50:32
 * @LastEditTime: 2021-06-04 16:29:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\lesson-preparation\documents\documents.vue
-->
<template>
  <div>
    <!--面包屑-->
    <BreadDocuments></BreadDocuments>

    <div class="container-documents">
      <div class="container-total">
        <a-layout>
          <a-layout-header>
            <a-menu
              theme="light"
              mode="horizontal"
              :defaultSelectedKeys="defaultSelectedKeys"
            >
              <a-menu-item
                :key="item.path"
                v-for="item in menuList"
                @click="selectMenu(item)"
                >{{ item.name }}</a-menu-item
              >
            </a-menu>
            <div class="size-container d-f">
              <div class="size-text">{{ totalUsedSize }}/{{ diskSize }}GB</div>
              <div ref="size" class="size"></div>
            </div>
          </a-layout-header>
          <a-layout-content>
            <div class="build-list">
              <!--头部导航和按钮-->
              <div class="build-manage d-f j-b a-c">
                <div>
                  <a @click="back" v-if="!isRoot">返回上一级</a>
                </div>
                <div>
                  <a-button type="primary" @click="upload"
                    ><a-icon type="upload" /> 上传</a-button
                  >
                  <a-button @click="add" class="del-all"
                    ><a-icon type="folder" /> 新建</a-button
                  >
                  <a-button class="del-all" @click="delAll"> 批量删除 </a-button>
                </div>
              </div>
              <div class="catalogue">{{ title }}</div>
              <!--列表-->
              <div class="build-space-list">
                <!--表格-->
                <a-table
                  :columns="columns"
                  rowKey="absPath"
                  :data-source="fileData"
                  :pagination="false"
                  :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                  }"
                >
                  <span slot="size" slot-scope="text">
                    <span>{{ text | cancelSize }}</span>
                  </span>
                  <span slot="name" slot-scope="text, record">
                    <img
                      src="@/assets/img/documents_dir.png"
                      v-if="record.type === 'dir'"
                    />
                    <img
                      src="@/assets/img/documents_image.png"
                      v-else-if="record.type === 'image'"
                    />
                    <img
                      src="@/assets/img/documents_audio.png"
                      v-else-if="record.type === 'audio'"
                    />
                    <img
                      src="@/assets/img/documents_video.png"
                      v-else-if="record.type === 'video'"
                    />
                    <img
                      src="@/assets/img/documents_courseware.png"
                      v-else-if="record.type === 'courseware'"
                    />
                    <img
                      src="@/assets/img/documents_file.png"
                      v-else-if="
                        record.type === 'excel' ||
                        record.type === 'doc' ||
                        record.type === 'ppt'
                      "
                    />
                    <img src="@/assets/img/documents_other.png" v-else />
                    <span :title="text" class="file-name" @click="toNext(record)">{{
                      text
                    }}</span>
                  </span>
                  <span slot="action" slot-scope="text, record">
                    <a-dropdown
                      placement="bottomLeft"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    >
                      <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                        <img src="@/assets/img/documents_ellipsis.png" width="16px" />
                      </a>
                      <a-menu slot="overlay">
                        <a-menu-item v-if="record.type != 'dir'">
                          <a href="javascript:;" @click="download(record)">下载</a>
                        </a-menu-item>
                        <a-menu-item v-if="record.type != 'dir'">
                          <a href="javascript:;" @click="push(record)">推送</a>
                        </a-menu-item>
                        <a-menu-item v-if="record.type != 'dir'">
                          <a href="javascript:;" @click="move(record)">移动到</a>
                        </a-menu-item>
                        <a-menu-item v-if="record.type != 'dir'">
                          <a href="javascript:;" @click="rename(record)">重命名</a>
                        </a-menu-item>
                        <a-menu-item>
                          <a href="javascript:;" class="file-del" @click="del(record)"
                            >删除</a
                          >
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </span>
                </a-table>
                <div class="total_file d-f j-c">共{{ total }}项</div>
              </div>
              <!--上传文件的弹出框-->
              <a-modal
                v-model="uploadVisible"
                title="上传文件"
                @ok="uploadOk"
                @cancel="uploadCancel"
                :confirm-loading="confirmLoading"
              >
                <a-upload-dragger
                  :file-list="fileList"
                  :remove="handleRemove"
                  :before-upload="beforeUpload"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    点击或将文件拖到这里上传(多个文件只上传第一个)
                  </p>
                  <p class="ant-upload-hint">
                    支持扩展名：.ppt .pptx .xlsx .xls .doc .docx .zip .pdf .png .jpg .xnwb
                  </p>
                </a-upload-dragger>
              </a-modal>
              <!--重命名的弹出框-->
              <a-modal v-model="renameVisible" title="重命名" width="345px">
                <div class="rename-img d-f a-c j-c">
                  <img src="@/assets/img/documents_image1.png" v-if="type === 'image'" />
                  <img
                    src="@/assets/img/documents_audio1.png"
                    v-else-if="type === 'audio'"
                  />
                  <img
                    src="@/assets/img/documents_video1.png"
                    v-else-if="type === 'video'"
                  />
                  <img
                    width="24px"
                    src="@/assets/img/documents_courseware1.png"
                    v-else-if="type === 'courseware'"
                  />
                  <img
                    src="@/assets/img/documents_file1.png"
                    v-else-if="type === 'excel' || type === 'doc' || type === 'ppt'"
                  />
                  <img src="@/assets/img/documents_other1.png" v-else />
                </div>
                <a-form-model
                  ref="renameForm"
                  :model="renameForm"
                  :rules="renameRules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item prop="newPath">
                    <a-input v-model="renameForm.newPath" />
                  </a-form-model-item>
                </a-form-model>
                <template slot="footer">
                  <a-button type="primary" @click="renameOk"> 确定 </a-button>
                </template>
              </a-modal>
              <!--移动到的弹出框-->
              <a-modal v-model="moveVisible" title="移动文件" @ok="moveOk" width="350px">
                <a-tree
                  show-icon
                  :replace-fields="{
                    children: 'child',
                    key: 'path',
                    title: 'name',
                  }"
                  :tree-data="treeData"
                  @select="onSelect"
                  :selectedKeys="moveForm.newPath"
                >
                  <a-icon slot="switcherIcon" type="down" />
                  <img
                    src="@/assets/img/documents_dir.png"
                    slot="folder"
                    style="margin-right: 5px"
                    width="16px"
                  />
                </a-tree>
              </a-modal>
              <!--新建的弹出框-->
              <a-modal
                v-model="addVisible"
                title="新建文件夹"
                @ok="addOk"
                @cancel="addCancel"
              >
                <a-form-model
                  ref="addForm"
                  :model="addForm"
                  :rules="addRules"
                  :label-col="labelCol1"
                  :wrapper-col="wrapperCol1"
                >
                  <a-form-model-item label="文件夹名称" prop="filePath">
                    <a-input v-model="addForm.filePath" />
                  </a-form-model-item>
                </a-form-model>
              </a-modal>
              <!--推送的弹出框-->
              <a-modal
                v-model="pushVisible"
                title="推送文件"
                @ok="pushOk"
                @cancel="pushCancel"
              >
                <!--表格-->
                <a-table
                  :columns="columns1"
                  :data-source="pushData"
                  :pagination="false"
                  :rowKey="(item) => JSON.stringify(item)"
                  size="small"
                  :row-selection="rowSelection"
                >
                  <span slot="name" slot-scope="text, record">
                    <span>{{ record.buildingName + "-" + record.classroomName }}</span>
                  </span>
                  <span slot="isOnline" slot-scope="text">
                    <span>{{ text === true ? "在线" : "离线" }}</span>
                  </span>
                </a-table>
              </a-modal>
            </div>
          </a-layout-content>
        </a-layout>
      </div>
    </div>
  </div>
</template>

<script>
import BreadDocuments from "~c/bread/BreadDocuments.vue";
let echarts = require("echarts");
import Client from "@/oss/client";
const columns = [
  {
    title: "名称",
    dataIndex: "name",
    width: "40%",
    align: "left",
    scopedSlots: { customRender: "name" },
    ellipsis: true,
  },
  {
    title: "文件大小",
    dataIndex: "size",
    width: "20%",
    align: "left",
    scopedSlots: { customRender: "size" },
  },
  {
    title: "修改日期",
    dataIndex: "modified_time",
    width: "20%",
    align: "left",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
    align: "left",
    scopedSlots: { customRender: "action" },
  },
];
const columns1 = [
  {
    title: "教室名称",
    dataIndex: "name",
    width: "40%",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "班级名称",
    dataIndex: "xnClassname",
    width: "40%",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "isOnline",
    width: "20%",
    align: "center",
    scopedSlots: { customRender: "isOnline" },
  },
];
export default {
  components: {
    BreadDocuments,
  },
  filters: {
    cancelSize(val) {
      if (val != null) {
        let KB = val / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return GB.toFixed(2) + "G";
          } else {
            return MB.toFixed(2) + "M";
          }
        } else {
          return KB.toFixed(2) + "KB";
        }
      }
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys1 } = this;
      return {
        selectedRowKeys1,
        onChange: (selectedRowKeys1) => {
          this.selectedRowKeys1 = selectedRowKeys1;
        },
        getCheckboxProps: (record) => ({
          props: {
            // 全部默认禁止选中
            // disabled: true,
            // 某几项默认禁止选中(R: 当state等于1时)
            disabled: record.isOnline === false,
            // 某几项默认选中(R: 当state等于1时) // defaultChecked: record.state == 1,
          },
        }),
      };
    },
  },
  watch: {
    arr1(val, oldVal) {
      this.arr1 = val;
      setTimeout(() => {
        this.myCharts();
      });
    },
  },
  data() {
    return {
      defaultSelectedKeys: ["/课件/"],
      menuList: [
        { path: "/课件/", name: "课件" },
        { path: "/图片/", name: "图片" },
        { path: "/音频/", name: "音频" },
        { path: "/视频/", name: "视频" },
        { path: "/文档/", name: "文档" },
        { path: "/其他/", name: "其他" },
      ],
      title: "",
      path: "",
      isRoot: true,
      diskSize: null,
      totalUsedSize: null,
      arr: [],
      arr1: [],
      labelCol: { span: 0 },
      wrapperCol: { span: 18 },
      fileData: [],
      columns,
      selectedRowKeys: [],
      uploadVisible: false,
      confirmLoading: false,
      type: "",
      renameVisible: false,
      renameForm: {
        oldPath: "",
        newPath: "",
      },
      renameRules: {
        newPath: [
          {
            required: true,
            message: "请输入文件名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 50,
            message: "文件名称不能超过50个字符",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              if (value.includes("/")) {
                callbackFn('文件名称不能包含特殊字符"/"');
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      moveVisible: false,
      moveForm: {
        oldPath: "",
        newPath: [],
      },
      treeData: [],
      labelCol1: { span: 6 },
      wrapperCol1: { span: 12 },
      addVisible: false,
      addForm: {
        filePath: "",
      },
      addRules: {
        filePath: [
          {
            required: true,
            message: "请输入文件夹名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 50,
            message: "文件夹名称不能超过50个字符",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              if (value.includes("/")) {
                callbackFn('文件夹名称不能包含特殊字符"/"');
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      pushVisible: false,
      columns1,
      pushData: [],
      selectedRowKeys1: [],
      pushForm: {
        filePath: "",
      },
      total: 0,
      fileList: [],
      dataObj: {},
    };
  },
  methods: {
    //选择菜单
    selectMenu(item) {
      let userInfo = this.$ls.get("userInfo");
      this.path = `cloudDisk/${userInfo.schoolNum}/${userInfo.id}${item.path}`;
      this.title = item.name;
      this.getFileList();
      this.isRoot = true;
    },
    //文件大小的echarts
    myCharts() {
      let myChart = echarts.init(this.$refs.size);
      let option = {
        grid: {
          bottom: 30,
          left: "0%",
          right: "0%",
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          //坐标轴线
          axisLine: {
            show: false,
          },
          type: "category",
        },
        series: [
          {
            type: "bar",
            data: this.arr1,
            barWidth: 5,
            tooltip: { show: false },
            emphasis: {
              itemStyle: {
                color: "rgba(165, 192, 204, 1)",
              },
            },
            z: 10,
            itemStyle: {
              // 柱子样式
              normal: {
                color: "rgba(165, 192, 204, 1)", // 柱状图颜色
                barBorderRadius: [20],
                legendHoverLink: false,
                label: {
                  show: false, // 显示文本
                },
              },
            },
          },
          {
            type: "bar",
            data: this.arr,
            tooltip: { show: false },
            emphasis: {
              itemStyle: {
                color: "rgba(222, 225, 228, 1)",
              },
            },
            barWidth: 5,
            barGap: "-100%", // 两个柱子之间的距离，如果要重叠设置为-100%
            itemStyle: {
              normal: {
                barBorderRadius: [20],
                color: "rgba(222, 225, 228, 1)", // 柱子颜色，作为底层背景
                label: {
                  show: false,
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //返回上一级
    back() {
      let path = this.path;
      let str = path.substr(0, path.length - 1);
      let index = path.substr(0, path.length - 1).lastIndexOf("/");
      this.path = str.substr(0, index + 1);
      this.getFileList();
      let title = this.title;
      let i = title.lastIndexOf(">");
      this.title = title.substr(0, i);
      if (i === 2) {
        this.isRoot = true;
      }
    },
    //点击文件夹进入
    toNext(record) {
      if (record.type === "dir") {
        this.path = record.absPath;
        this.getFileList();
        let str = record.absPath.substr(0, record.absPath.length - 1);
        let index = record.absPath.substr(0, record.absPath.length - 1).lastIndexOf("/");
        this.title = this.title + ">" + str.substr(index + 1);
        this.isRoot = false;
      }
    },
    //获取Sts临牌
    getStsInfo() {
      //获取Token
      this.$api.getStsInfo().then((res) => {
        // console.log(res);
        if (res.success) {
          const {
            region,
            Bucket,
            AccessKeyId,
            AccessKeySecret,
            SecurityToken,
          } = res.data.data;
          this.dataObj = {
            region: region,
            bucket: Bucket,
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
          };
          return true;
        }
      });
    },
    //上传文件
    upload() {
      this.uploadVisible = true;
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传后提交前的操作
    beforeUpload(action) {
      this.fileList = [...this.fileList, action];
      return false;
    },
    //上传文件确定
    uploadOk() {
      let files = this.fileList[0];
      if (this.fileList.length === 0) {
        this.$message.error("您上传的文件列表为空，请选择文件上传");
      } else {
        if (files.name.includes("/")) {
          this.$message.error('文件名包含特殊字符"/"');
        } else {
          let size = files.size;
          let totalUsedSize = this.totalUsedSize;
          let diskSize = this.diskSize;
          let a = size + totalUsedSize * 1024 * 1024 * 1024;
          let b = diskSize * 1024 * 1024 * 1024;
          let flag = a < b;
          if (!flag) {
            this.$message.warning("网盘内存过满，请删除一些文件后上传");
            this.uploadVisible = false;
            this.fileList = [];
          } else {
            this.confirmLoading = true;
            try {
              const client = Client(this.dataObj),
                file = files;
              //修改命名
              var fileExtension = file.name;
              const random_name = this.path + fileExtension;
              client.put(random_name, file).then((result) => {
                if (result.res.statusCode === 200) {
                  this.confirmLoading = false;
                  this.$message.success("上传成功");
                  this.uploadVisible = false;
                  this.fileList = [];
                  this.getFileList();
                  this.checkUsed();
                }
              });
            } catch (error) {
              this.$message.error("上传阿里云失败");
              this.confirmLoading = false;
            }
          }
        }
      }
    },
    //上传文件取消
    uploadCancel() {
      this.fileList = [];
    },
    //全删
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            this.$api.deleteFileAll(this.selectedRowKeys).then((res) => {
              if (res.success) {
                this.$message.success("批量删除成功");
                this.getFileList();
                this.checkUsed();
                this.selectedRowKeys = [];
              } else {
                this.$message.error(res.message);
              }
            });
          },
        });
      } else {
        this.$message.error("请选择你想删除的文件");
      }
    },
    //删除文件
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.deleteFile({ path: record.absPath }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getFileList();
              this.checkUsed();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //下载
    download(record) {
      this.$api.getDownloadUrl({ path: record.absPath }).then((res) => {
        if (res.success) {
          let url = res.data.url;
          if (record.type === "image") {
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", record.name); // 动态设置原文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            let x = new XMLHttpRequest();
            x.open("GET", url, true);
            x.responseType = "blob";
            x.onload = (e) => {
              //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
              let href = window.URL.createObjectURL(x.response);
              let link = document.createElement("a");
              link.href = href;
              link.download = record.name;
              link.click();
            };
            x.send();
          }
        }
      });
    },
    //重命名
    rename(record) {
      this.renameVisible = true;
      this.renameForm.oldPath = record.absPath;
      this.renameForm.newPath = record.name;
      this.type = record.type;
    },
    //重命名确定
    renameOk() {
      this.$refs.renameForm.validate((valid) => {
        if (valid) {
          let oldPath = this.renameForm.oldPath;
          let index = oldPath.lastIndexOf("/");
          let newPath = oldPath.substr(0, index + 1) + this.renameForm.newPath;
          this.$api
            .renameOrMoveFile({
              oldPath: this.renameForm.oldPath,
              newPath: newPath,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("重命名成功");
                this.renameVisible = false;
                this.getFileList();
              } else {
                this.$message.error(res.message);
              }
            });
        }
      });
    },
    //得到所有的文件夹列表
    getDirStruct() {
      let userInfo = this.$ls.get("userInfo");
      let path = `cloudDisk/${userInfo.schoolNum}/${userInfo.id}/`;
      this.$api
        .getDirStruct({
          path: path,
        })
        .then((res) => {
          if (res.success) {
            this.treeData = res.data.list;
            this.setIcon(this.treeData);
          }
        });
    },
    //前端给treedata赋slots值
    setIcon(datas) {
      let that = this;
      for (let i = 0; i < datas.length; i++) {
        datas[i].slots = { icon: "folder" };
        if (datas[i].child != null && datas[i].child.length > 0) {
          that.setIcon(datas[i].child);
        }
      }
    },
    //移动到
    move(record) {
      this.moveVisible = true;
      this.getDirStruct();
      this.moveForm.oldPath = record.absPath;
    },
    //选择树形控件name时触发
    onSelect(selectedKeys, info) {
      this.moveForm.newPath = selectedKeys;
    },
    //移动到确定
    moveOk() {
      if (this.moveForm.newPath.length === 0) {
        this.$message.error("请选择移动到的目标文件夹");
      } else {
        let oldPath = this.moveForm.oldPath;
        let index = oldPath.lastIndexOf("/");
        let newPath = this.moveForm.newPath.join("") + oldPath.substr(index + 1);
        if (this.moveForm.oldPath === newPath) {
          this.$message.error("您选择的是当前文件夹");
        } else {
          this.$api
            .renameOrMoveFile({
              oldPath: this.moveForm.oldPath,
              newPath: newPath,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("移动成功");
                this.moveVisible = false;
                this.getFileList();
                this.moveForm.newPath = [];
              } else {
                this.$message.error(res.message);
              }
            });
        }
      }
    },
    //新建文件夹
    add() {
      this.addVisible = true;
    },
    //新建文件夹确定
    addOk() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let path = this.path + this.addForm.filePath + "/";
          this.$api.mkdir({ filePath: path }).then((res) => {
            if (res.success) {
              this.$message.success("新建文件夹成功");
              this.addVisible = false;
              this.addForm.filePath = "";
              this.getFileList();
              this.checkUsed();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //新建文件夹取消
    addCancel() {
      this.addForm.filePath = "";
    },
    //推送
    push(record) {
      this.pushVisible = true;
      this.getTeacherDevice();
      this.pushForm.filePath = record.absPath;
    },
    //推送确定
    pushOk() {
      if (this.selectedRowKeys1.length == 0) {
        this.$message.error("请您选择想要推送的设备");
      } else {
        let userInfo = this.$ls.get("userInfo");
        this.$api.getTeacherDevice({ userId: userInfo.id }).then((result) => {
          if (result.success) {
            let serialNumList = this.selectedRowKeys1.map((r) => JSON.parse(r).serialNum);
            let isOnlineList = [];
            result.data.list.forEach((rr) => {
              serialNumList.forEach((rrr) => {
                if (rrr == rr.serialNum) {
                  isOnlineList.push(rr.isOnline);
                }
              });
            });
            if (
              isOnlineList.map((r) => r === true).length != this.selectedRowKeys1.length
            ) {
              this.$message.error("您只能勾选在线的设备");
              this.getTeacherDevice();
              this.selectedRowKeys1 = [];
            } else {
              let arr = [];
              this.selectedRowKeys1.forEach((r) => {
                let obj = JSON.parse(r);
                let str =
                  obj.buildingName +
                  "-" +
                  obj.classroomName +
                  ":::" +
                  obj.xnClassname +
                  ":::" +
                  obj.serialNum;
                arr.push(str);
              });
              this.$api
                .pushFileToDevice({
                  filePath: this.pushForm.filePath,
                  serialNums: arr,
                })
                .then((res) => {
                  console.log(res);
                  if (res.success) {
                    if (res.data.failureList.length > 0) {
                      let str = res.data.failureList.join("");
                      this.$message.error(str + "推送失败");
                    } else {
                      this.$message.success("推送成功");
                      this.pushVisible = false;
                      this.selectedRowKeys1 = [];
                    }
                  } else {
                    this.$message.error(res.message);
                  }
                });
            }
          }
        });
      }
    },
    //推送取消
    pushCancel() {
      this.selectedRowKeys1 = [];
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //老师获取设备列表
    getTeacherDevice() {
      let userInfo = this.$ls.get("userInfo");
      this.$api.getTeacherDevice({ userId: userInfo.id }).then((res) => {
        if (res.success) {
          this.pushData = res.data.list;
        }
      });
    },
    //获取文件列表
    getFileList() {
      let userInfo = this.$ls.get("userInfo");
      this.$api
        .getFileList({
          userId: userInfo.id,
          schoolNum: userInfo.schoolNum,
          path: this.path,
        })
        .then((res) => {
          if (res.success) {
            this.fileData = res.data.list;
            this.total = res.data.list.length;
          }
        });
    },
    //判断是否有网盘
    isOwnDisk() {
      let userInfo = this.$ls.get("userInfo");
      this.$api
        .isOwnDisk({
          userId: userInfo.id,
        })
        .then((res) => {
          if (res.success) {
            this.checkUsed();
            this.getFileList();
          } else {
            this.$info({
              title: "您还没有开通网盘，是否初始化网盘",
              okText: "确认",
              onOk: () => {
                this.addCloudDisk();
              },
            });
          }
        });
    },
    //新增网盘
    addCloudDisk() {
      let userInfo = this.$ls.get("userInfo");
      this.$api
        .addCloudDisk({
          userId: userInfo.id,
          schoolNum: userInfo.schoolNum,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("创建网盘成功");
            this.checkUsed();
            this.getFileList();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    //查看该用户网盘总使用量和总量
    checkUsed() {
      let userInfo = this.$ls.get("userInfo");
      this.$api
        .checkUsed({
          userId: userInfo.id,
          schoolNum: userInfo.schoolNum,
        })
        .then((res) => {
          if (res.success) {
            this.diskSize = res.data.diskSize;
            // this.diskSize = 5;
            this.totalUsedSize = res.data.totalUsedSize;
            let arr = [];
            arr.push(this.diskSize);
            let arr1 = [];
            arr1.push(this.totalUsedSize);
            this.arr = arr;
            this.arr1 = arr1;
          }
        });
    },
  },
  created() {
    this.isOwnDisk();
    this.getStsInfo();
    let userInfo = this.$ls.get("userInfo");
    this.path = `cloudDisk/${userInfo.schoolNum}/${userInfo.id}/课件/`;
    this.title = "课件";
  },
  mounted() {
    setTimeout(() => {
      this.myCharts();
    });
  },
};
</script>

<style lang="scss" scoped src="./documents.scss"></style>
