<template>
  <div>
    <div class="d-f j-b total">
      <!--第二行左边-->
      <div class="equipment-detail">
        <div class="block d-f j-b a-c">
          <div class="app-active">设备在线活跃度（台）</div>
          <a-date-picker
            class="data-picker"
            @change="dataPicker"
            :allowClear="false"
            :disabledDate="disabledDate"
            :defaultValue="moment(getCurrentData(), 'YYYY-MM-DD')"
          />
        </div>
        <div class="equipment-detail-ec">
          <div ref="main" class="main"></div>
        </div>
      </div>
      <!--第二行右边-->
      <div class="app-rank">
        <div class="d-f j-b a-c">
          <div class="common-rank">
            软件使用时长排名
            <a-tooltip placement="right">
              <template slot="title">
                <span>软件排行是根据终端黑板软件日使用时长平均值做统计计算</span>
              </template>
              <a-icon type="question-circle" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </div>
          <a-range-picker
            class="rank-data-picker"
            @change="dataPicker1"
            :allowClear="false"
            :defaultValue="[
              moment(getCurrentData1(), 'YYYY-MM-DD'),
              moment(getCurrentData(), 'YYYY-MM-DD'),
            ]"
            :disabledDate="disabledDate"
          />
        </div>
        <div class="d-f ul-and-ec">
          <a-empty v-if="hasNotData" class="has-not-data" />
          <ul class="second-ul d-f f-d j-a">
            <li v-for="(item, index) in softwareList" :key="index">
              {{ index + 1 }}
            </li>
          </ul>
          <div class="rank-detail" ref="rankMain"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
import moment from "moment";
export default {
  data() {
    return {
      softwareList: [],
      avgValueList: [],
      timeData: [],
      dateString: moment().format("YYYY-MM-DD"),
      onlineList: [],
      startTime: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endTime: moment().subtract("days").format("YYYY-MM-DD"),
      hasNotData: false,
    };
  },
  watch: {
    softwareList(val, oldVal) {
      this.softwareList = val;
      setTimeout(() => {
        this.myCharts2();
      });
    },
    avgValueList(val, oldVal) {
      this.avgValueList = val;
      setTimeout(() => {
        this.myCharts2();
      });
    },
    onlineList(val, oldVal) {
      this.onlineList = val;
      setTimeout(() => {
        this.myCharts1();
      });
    },
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    moment,
    getCurrentData() {
      return new Date();
    },
    getCurrentData1() {
      return new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    },
    dataPicker(date, dateString) {
      this.dateString = dateString;
      this.getSchoolOnlineInfo();
    },
    dataPicker1(dates, dateStrings) {
      this.startTime = dateStrings[0];
      this.endTime = dateStrings[1];
      this.getListOrder();
    },
    myCharts1() {
      let myChart = echarts.init(this.$refs.main);
      // 指定图表的配置项和数据
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          data: [
            "0:00",
            "1:00",
            "2:00",
            "3:00",
            "4:00",
            "5:00",
            "6:00",
            "7:00",
            "8:00",
            "9:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
          nameTextStyle: {
            fontSize: 12,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "10%",
        },
        series: [
          {
            type: "line",
            smooth: 0.3,
            symbol: "none",
            lineStyle: {
              color: "#5AD8A6",
              width: 3,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
            },
            areaStyle: {
              color: "rgba(90, 216, 166, 0.35)",
            },
            data: this.onlineList,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#5AD8A6",
              type: "dashed",
            },
          },
          backgroundColor: "#5AD8A6",
          extraCssText: "box-shadow: 0px 2px 8px rgba(90, 216, 166, 0.3)",
          padding: 10,
          formatter: (val) => {
            //返回tooltip的内容及布局样式
            // console.log(val);
            let obj = {};
            val.forEach((r) => {
              obj.time = r.name;
              obj.value = r.value;
            });
            return `
             <div>${this.dateString} ${obj.time}</div>
             <div style="margin-top:10px">设备在线数：${obj.value}</div>
           `;
          },
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    myCharts2() {
      let myChart = echarts.init(this.$refs.rankMain);
      let option = {
        grid: {
          top: "1%",
          bottom: "-7%",
          left: "4%",
          right: "11%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          // //坐标轴线
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0,0,0,.45)",
            },
          },
          type: "category",
          data: this.softwareList,
        },
        series: [
          {
            type: "bar",
            emphasis: {
              itemStyle: {
                color: "rgba(255, 157, 77, 1)",
              },
            },
            data: this.avgValueList,
            barWidth: 14,
            z: 10,
            tooltip: { show: false },
            itemStyle: {
              // 柱子样式
              normal: {
                color: "rgba(255, 157, 77, 1)", // 柱状图颜色
                barBorderRadius: [20],
                legendHoverLink: false,
                label: {
                  show: true, // 显示文本
                  position: ["100%", 2], // 数据值位置
                  formatter: "{c}小时",
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.65)",
                    fontSize: 12,
                  },
                },
              },
            },
          },
          {
            type: "bar",
            emphasis: {
              itemStyle: {
                color: "rgba(255, 235, 219, 1)",
              },
            },
            data: this.timeData,
            tooltip: { show: false },
            barWidth: 14,
            barGap: "-100%", // 两个柱子之间的距离，如果要重叠设置为-100%
            itemStyle: {
              normal: {
                barBorderRadius: [20],
                color: "rgba(255, 235, 219, 1)", // 柱子颜色，作为底层背景
                label: {
                  show: false,
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    getListOrder() {
      this.$api
        .getListOrder({
          startTime: this.startTime,
          endTime: this.endTime,
        })
        .then((res) => {
          if (res.success) {
            this.softwareList = res.data.list.map((r) => r.software);
            let arr = res.data.list.map((r) => r.totalDuration);
            let arr1 = arr.map((r) => {
              if (r === null) {
                r = 0;
              } else {
                r = Number(r).toFixed(1);
              }
              return r;
            });
            this.avgValueList = arr1;
            let arr2 = [];
            let startTime = new Date(this.startTime).getTime();
            let endTime = new Date(this.endTime).getTime();
            let days =
              (parseInt(endTime) - parseInt(startTime)) / (24 * 60 * 60 * 1000) + 1;
            for (let i = 0; i < this.avgValueList.length; i++) {
              arr2[i] = days * 24;
            }
            this.timeData = arr2;
            if (this.avgValueList.length === 0) {
              this.hasNotData = true;
            } else {
              this.hasNotData = false;
            }
          }
        });
    },
    getSchoolOnlineInfo() {
      this.$api.getSchoolOnlineInfo({ date: this.dateString }).then((res) => {
        console.log(res);
        let arr = [];
        for (let i = 0; i < 24; i++) {
          arr[i] = 0;
        }
        for (let i = 0; i < 24; i++) {
          res.data.onlineList.forEach((r) => {
            if (i === r.timeValue) {
              arr[i] = r.onlineCount;
            }
          });
        }
        this.onlineList = arr;
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.myCharts1();
      this.myCharts2();
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize();
        }
      });
      resizeOb.observe(this.$refs.main);
      resizeOb.observe(this.$refs.rankMain);
    });
  },
  created() {
    this.getListOrder();
    this.getSchoolOnlineInfo();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-calendar-picker-input {
  font-size: 12px;
  height: 22px;
  line-height: 12px;
}
.total {
  .equipment-detail {
    padding: 16px;
    box-sizing: border-box;
    margin-top: 24px;
    min-width: 793px;
    width: 67.5%;
    height: 318px;
    background: #ffffff;
    opacity: 1;
    border-radius: 16px;
    .block {
      .app-active {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        opacity: 1;
      }
    }
    .equipment-detail-ec {
      width: 100%;
      height: 260px;
      .main {
        width: 100%;
        height: 100%;
      }
    }
  }
  .app-rank {
    margin-top: 24px;
    width: 31.3%;
    min-width: 367px;
    height: 318px;
    background: #ffffff;
    opacity: 1;
    border-radius: 16px;
    padding: 16px;
    .rank-data-picker {
      display: block;
      z-index: 99;
      width: 170px;
      background: #ffffff;
      opacity: 1;
      color: #d9dee5;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
    }
    .common-rank {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      opacity: 1;
    }
    .ul-and-ec {
      height: 280px;
      width: 100%;
      position: relative;
      .has-not-data {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
      .second-ul {
        height: 100%;
        li {
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.65);
          text-align: center;
          background: #f0f2f5;
          border-radius: 50%;
        }
        li:nth-child(1) {
          color: transparent;
          background-color: transparent;
          background-image: url(~@/assets/img/rank1.png);
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
        }
        li:nth-child(2) {
          color: transparent;
          background-color: transparent;
          background-image: url(~@/assets/img/rank2.png);
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
        }
        li:nth-child(3) {
          color: transparent;
          background-color: transparent;
          background-image: url(~@/assets/img/rank3.png);
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .rank-detail {
        width: 90%;
        height: 100%;
      }
    }
  }
}
</style>
