<template>
  <div>
    <BreadDevice></BreadDevice>
    <div class="container-device">
      <!-- 上面的搜索和指令 -->
      <div class="device-status-con">
        <!-- 第一行 -->
        <div class="device-status d-f a-c j-b">
          <!-- 第一行左边 -->
          <div class="d-f">
            <div class="device-status-left">
              <a-form-model
                :model="statusForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="状态">
                  <a-select
                    v-model="statusForm.region"
                    @change="changeSelect"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option :value="0">离线</a-select-option>
                    <a-select-option :value="1">在线</a-select-option>
                    <a-select-option :value="2">故障</a-select-option>
                    <a-select-option :value="3">全部</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </div>
            <div class="device-status-middle d-f a-c">
              <a-input-search
                placeholder="请输入教室名称"
                style="width: 200px"
                v-model="value"
                @change="changeInput"
              />
            </div>
          </div>
          <!-- 第一行右边 -->
          <div class="d-f a-c">
            <div class="checkbox-all d-f a-c j-c">
              <input type="checkbox" v-model="checkAll" @click="onChangeAll" id="all" />
              <label for="all">全选</label>
            </div>
            <a-dropdown class="dropdown d-f a-c">
              <a-menu slot="overlay">
                <a-menu-item key="1" @click="turnOff">关机</a-menu-item>
                <a-menu-item key="2" @click="turnAgain">重启</a-menu-item>
                <a-menu-item key="3" @click="informationScreen">息屏</a-menu-item>
                <a-menu-item key="4" @click="sendLight">亮屏</a-menu-item>
              </a-menu>
              <a-button><a-icon type="carry-out" />远程指令</a-button>
            </a-dropdown>
            <a-dropdown class="dropdown d-f a-c">
              <a-menu slot="overlay">
                <a-menu-item key="1" @click="newsSend">消息推送</a-menu-item>
                <a-menu-item key="2" @click="fileSend">文件下发</a-menu-item>
              </a-menu>
              <a-button><a-icon type="desktop" />终端推送</a-button>
            </a-dropdown>
            <a-button class="dropdown d-f a-c" @click="handleTimedTask">
              <a-icon type="clock-circle" />定时任务</a-button
            >

            <!-- <a-button type="primary" @click="showModal"
              ><a-icon type="plus" />添加设备</a-button
            > -->
          </div>
        </div>
        <!-- 消息推送的弹出框 -->
        <a-modal v-model="newsVisible" @ok="newsSendOk" @cancel="newsSendCancel">
          <div slot="title">
            <span>消息推送</span>
            <span style="color: #999999">（已选择{{ checkList.length }}台设备）</span>
          </div>
          <a-form-model
            ref="ruleNewsForm"
            :model="newsForm"
            :rules="newsRules"
            :label-col="labelCol1"
            :wrapper-col="wrapperCol1"
          >
            <!-- <a-form-model-item label="展示选择" prop="showChannel">
              <a-select v-model="newsForm.showChannel">
                <a-select-option :value="'0'">PC端（推荐）</a-select-option>
                <a-select-option :value="'1'">android</a-select-option>
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="展示时长" prop="duration">
              <a-select
                v-model="newsForm.duration"
                placeholder="请选择展示时长"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option :value="'10'">10分钟</a-select-option>
                <a-select-option :value="'20'">20分钟</a-select-option>
                <a-select-option :value="'30'">30分钟</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="终端关闭通知" prop="isClose">
              <a-radio-group v-model="newsForm.isClose">
                <a-radio :value="'0'">允许</a-radio>
                <a-radio :value="'1'">不允许</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="发送消息通知" prop="content" class="content">
              <a-input
                v-model="newsForm.content"
                type="textarea"
                autosize
                placeholder="请输入消息内容"
                @change="getContentLength"
              />
              <div class="content-count">{{ contentCount }}/200</div>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
        <!-- 文件下发的弹出框 -->
        <a-modal v-model="fileVisible" :footer="null">
          <div slot="title">
            <span>文件下发</span>
            <span style="color: #999999">（已选择{{ checkList.length }}台设备）</span>
          </div>
          <a-upload-dragger
            name="file"
            :showUploadList="false"
            @change="handleChange"
            :customRequest="customRequest"
            :headers="headers"
            :before-upload="beforeUpload"
            accept=".ppt,.pptx,.xlsx,.xls,.doc,.docx,.zip,.pdf,.png,.jpg"
          >
            <p class="ant-upload-drag-icon"><a-icon type="inbox" /></p>
            <p class="ant-upload-text">点击或将文件拖到这里上传</p>
            <p class="ant-upload-hint">
              支持扩展名：.ppt .pptx .xlsx .xls .doc .docx .zip .pdf .png .jpg
            </p>
          </a-upload-dragger>
        </a-modal>
        <!-- 定时任务的弹出框 -->
        <a-modal
          v-model="timeVisible"
          @ok="timeSendOk"
          @cancel="timeSendCancel"
          width="620px"
        >
          <div slot="title">
            <span>定时任务</span>
            <span style="color: #999999">（已选择{{ checkList.length }}台设备）</span>
          </div>
          <a-form-model
            ref="ruleTimeForm"
            :model="timeForm"
            :rules="timeRules"
            :label-col="labelCol1"
            :wrapper-col="wrapperCol1"
          >
            <a-form-model-item label="指令类型" prop="type">
              <a-radio-group v-model="timeForm.type" @change="changeInvokeTarget">
                <a-radio :value="true">远程指令</a-radio>
                <a-radio :value="false">终端推送</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <div v-if="timeForm.type">
              <a-form-model-item label="指令内容" prop="invokeTarget">
                <a-radio-group v-model="timeForm.invokeTarget">
                  <a-radio value="scheduleShutdown">关机</a-radio>
                  <a-radio value="scheduleRestart">重启</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="循环模式" prop="cycleType">
                <a-radio-group v-model="timeForm.cycleType">
                  <a-radio :value="1">每天重复</a-radio>
                  <a-radio :value="2">工作日</a-radio>
                  <a-radio :value="3">周末</a-radio>
                  <a-radio :value="4">自定义</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <div v-if="timeForm.cycleType === 1">
                <a-form-model-item label="执行周期">
                  <div class="round d-f a-c j-c">周一至周日</div>
                </a-form-model-item>
                <a-form-model-item label="执行时间" prop="time">
                  <a-time-picker v-model="timeForm.time" valueFormat="HH:mm:ss" />
                </a-form-model-item>
              </div>
              <div v-else-if="timeForm.cycleType === 2">
                <a-form-model-item label="执行周期">
                  <div class="round d-f a-c j-c">周一至周五</div>
                </a-form-model-item>
                <a-form-model-item label="执行时间" prop="time">
                  <a-time-picker v-model="timeForm.time" valueFormat="HH:mm:ss" />
                </a-form-model-item>
              </div>
              <div v-else-if="timeForm.cycleType === 3">
                <a-form-model-item label="执行周期">
                  <div class="round d-f a-c j-c">周六至周日</div>
                </a-form-model-item>
                <a-form-model-item label="执行时间" prop="time">
                  <a-time-picker v-model="timeForm.time" valueFormat="HH:mm:ss" />
                </a-form-model-item>
              </div>
              <div v-else>
                <a-form-model-item label="执行周期" prop="week">
                  <a-select
                    v-model="timeForm.week"
                    placeholder="请选择执行周期"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="index"
                      v-for="(item, index) in weekList"
                      :key="index"
                    >
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="执行时间" prop="time">
                  <a-time-picker v-model="timeForm.time" valueFormat="HH:mm:ss" />
                </a-form-model-item>
              </div>
            </div>
            <div v-else>
              <a-form-model-item label="指令内容" prop="invokeTarget">
                <a-radio-group v-model="timeForm.invokeTarget">
                  <a-radio value="scheduleMessage">消息推送</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="展示时长" prop="duration">
                <a-select
                  v-model="timeForm.duration"
                  placeholder="请选择展示时长"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option :value="10">10分钟</a-select-option>
                  <a-select-option :value="20">20分钟</a-select-option>
                  <a-select-option :value="30">30分钟</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="终端关闭通知" prop="isClose">
                <a-radio-group v-model="timeForm.isClose">
                  <a-radio :value="true">允许</a-radio>
                  <a-radio :value="false">不允许</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="消息内容" prop="content" class="content">
                <a-input
                  v-model="timeForm.content"
                  type="textarea"
                  autosize
                  placeholder="请输入消息内容"
                  @change="getContentLengthToo"
                />
                <div class="content-count">{{ contentCountToo }}/200</div>
              </a-form-model-item>
              <a-form-model-item label="执行日期" prop="date">
                <a-date-picker
                  v-model="timeForm.date"
                  placeholder="请选择执行日期"
                  :disabledDate="disabledDate"
                  valueFormat="YYYY-MM-DD"
                />
              </a-form-model-item>
              <a-form-model-item label="执行时间" prop="time">
                <a-time-picker v-model="timeForm.time" valueFormat="HH:mm:ss" />
              </a-form-model-item>
            </div>
          </a-form-model>
        </a-modal>
        <!--添加设备的弹出框-->
        <a-modal v-model="visible" title="添加设备" @ok="hideModal" @cancel="cancelModal">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol1"
            :wrapper-col="wrapperCol1"
          >
            <a-form-model-item label="序列号" prop="serialNum">
              <a-input
                v-model="form.serialNum"
                placeholder="请输入设备序列号"
                type="number"
              />
            </a-form-model-item>
            <a-form-model-item label="教室所在楼栋" prop="buildingId">
              <a-select
                v-model="form.buildingId"
                placeholder="请选择楼栋"
                @change="selectClassroom"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option :value="item.id" :key="item.id" v-for="item in buildNum">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="绑定教室" prop="classroomId">
              <a-select
                v-model="form.classroomId"
                placeholder="请选择楼栋后再选择教室"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option
                  :value="item.id"
                  :key="item.id"
                  v-for="item in classroomNum"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
      <!-- 设备列表 -->
      <ul class="big-ul d-f f-w">
        <li v-for="item in buildOptions" :key="item.serialNum" class="big-li" ref="bigLi">
          <img
            :src="item.desktop"
            v-if="item.isOnline && item.desktop != null"
            class="big-image"
            @error="defImg"
          />
          <img src="@/assets/img/device-offline.png" v-else class="big-image" />
          <div
            class="dust"
            @click="
              toDetail(
                item.serialNum,
                item.isOnline,
                item.buildingName,
                item.classroomName
              )
            "
          ></div>
          <div class="checkbox d-f a-c j-c">
            <input
              type="checkbox"
              v-model="checkList"
              :value="item.serialNum"
              :id="item.serialNum"
              class="checkbox-style"
            />
            <label :for="item.serialNum">
              {{ item.buildingName + "-" + item.classroomName }}
            </label>
          </div>
          <!-- <div class="time d-f a-c" v-if="item.isOnline">
            <img src="@/assets/img/device1.png" />
            <span>开机时间：{{ item.openTime | handleTime }}</span>
          </div> -->
          <div class="status" :class="item.isOnline ? 'online' : 'offline'">
            {{ item.isOnline | handleStatus }}
          </div>
          <div class="breakdown" v-if="item.isRepair">故障</div>
          <div v-if="item.isOnline" class="enlarge" @click="enlarge(item)">
            <img src="@/assets/img/enlarge.png" />
          </div>
        </li>
      </ul>
      <!-- 大屏巡课 -->
      <div class="enlarge-and-upload" v-if="enlargeVisible">
        <div class="name-and-close d-f a-c j-b">
          <div>{{ item.buildingName + "-" + item.classroomName }}</div>
          <div @click="enlargeClose">
            <img src="@/assets/img/enlarge_close.png" />
          </div>
        </div>
        <div class="line"></div>
        <div class="enlarge-space d-f a-c j-c">
          <img
            :src="item.desktop"
            v-if="item.isOnline && item.isOnline != null"
            @error="defImg"
          />
          <img src="@/assets/img/device-offline.png" v-else />
          <button class="d-f a-c j-c" @click="uploadEnlarge(item)" v-if="item.isOnline">
            下载
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadDevice from "~c/bread/BreadDevice.vue";
import Client from "@/oss/client";
import moment from "moment";
import { getCron } from "./task";
export default {
  components: {
    BreadDevice,
  },
  filters: {
    //处理状态文字的过滤器
    handleStatus(val) {
      if (val === true) {
        return "在线";
      } else {
        return "离线";
      }
    },
    //处理运行时间的过滤器
    handleTime(val) {
      if (val === null) {
        return null;
      } else {
        var date = new Date(val);
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
          "-";
        var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
        var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      }
    },
  },
  computed: {
    //判断有没有选择框选中
    listSome() {
      if (this.checkList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    //判断有没有勾选离线或故障的设备
    selectOnline() {
      let arr = [];
      this.checkList.forEach((r) => {
        this.buildOptions.forEach((rr) => {
          if (r === rr.serialNum) {
            arr.push(rr.isOnline);
          }
        });
      });
      if (arr.every((rrr) => rrr === true)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      value: "",
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      statusForm: {
        region: 3,
      },
      labelCol1: { span: 6 },
      wrapperCol1: { span: 14 },
      form: {
        serialNum: null,
        buildingId: undefined,
        classroomId: undefined,
        deviceType: 1,
      },
      rules: {
        serialNum: [
          {
            required: true,
            message: "请输入设备序列号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        buildingId: [
          {
            required: true,
            message: "请选择楼栋",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classroomId: [
          {
            required: true,
            message: "请选择楼栋后选择教室",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      newsForm: {
        content: "",
        showChannel: "0",
        duration: undefined,
        isClose: "0",
      },
      newsRules: {
        content: [
          {
            required: true,
            message: "请输入消息内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            message: "请输入0-200之内的字符",
            max: 200,
            trigger: ["change", "blur"],
          },
        ],
        showChannel: [
          {
            required: true,
            message: "请选择展示通道",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        duration: [
          {
            required: true,
            message: "请选择展示时长",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        isClose: [
          {
            required: true,
            message: "请选择是否允许关闭",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      timeForm: {
        type: true,
        invokeTarget: "scheduleShutdown",
        cycleType: 1,
        week: 0,
        time: "",
        date: "",
        duration: 10,
        isClose: true,
        content: "",
      },
      timeRules: {
        type: [
          {
            required: true,
            type: "boolean", //表明是布尔类型
            message: "请选择指令类型",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        invokeTarget: [
          {
            required: true,
            message: "请选择指令内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        cycleType: [
          {
            required: true,
            type: "number", //表明是数字类型
            message: "请选择指令内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        time: [
          {
            required: true,
            message: "请选择执行时间",
            whitespace: true,
            trigger: "change",
          },
        ],
        week: [
          {
            required: true,
            type: "number", //表明是数字类型
            message: "请选择执行周期",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        date: [
          {
            required: true,
            message: "请选择执行日期",
            whitespace: true,
            trigger: "change",
          },
        ],
        duration: [
          {
            required: true,
            type: "number", //表明是数字类型
            message: "请选择展示时长",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        isClose: [
          {
            required: true,
            type: "boolean", //表明是布尔类型
            message: "请选择是否允许关闭",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        content: [
          {
            required: true,
            message: "请输入消息内容",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            message: "请输入0-200之内的字符",
            max: 200,
            trigger: ["change", "blur"],
          },
        ],
      },
      headers: {
        authorization: "authorization-text",
        token: this.$ls.get("token"),
      },
      weekList: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      dataObj: {},
      contentCount: 0,
      contentCountToo: 0,
      buildNum: [],
      classroomNum: [],
      checkAll: false,
      checkList: [],
      list: [],
      buildOptions: [],
      visible: false,
      newsVisible: false,
      fileVisible: false,
      timeVisible: false,
      websocket: null,
      timer: null,
      timer1: null,
      timer2: null,
      enlargeVisible: false,
      defaultImg: require("@/assets/img/device-bac.png"),
      item: null,
    };
  },
  watch: {
    checkList(val, oldVal) {
      if (val.length === this.buildOptions.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    item(val, oldVal) {
      if (val != null && oldVal != null) {
        if (val.desktop != oldVal.desktop) {
          this.item.desktop = val.desktop;
        }
      }
    },
  },

  methods: {
    defImg(event) {
      console.log(11);
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    //不可选的日期
    disabledDate(current) {
      return current < moment().subtract(1, "day");
    },
    moment,
    //input框改变
    changeInput() {
      console.log("input框改变发送信息");
      this.websocketsendSecond();
    },
    //状态改变
    changeSelect() {
      console.log("状态改变发送信息");
      this.websocketsendSecond();
    },
    //新增设备
    showModal() {
      this.visible = true;
    },
    //新增设备确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          delete this.form["buildingId"];
          // console.log(this.form);
          this.form.registerMark = this.form.serialNum;
          this.$api.addDevice(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("新增成功");
              this.visible = false;
              this.getDeviceList();
              this.form = {
                serialNum: null,
                buildingId: undefined,
                classroomId: undefined,
                deviceType: 1,
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //新增设备取消
    cancelModal() {
      this.form = {
        serialNum: null,
        buildingId: undefined,
        classroomId: undefined,
        deviceType: 1,
      };
    },
    //获取楼栋
    getBuild() {
      this.$api.getBuildList().then((res) => {
        // console.log(res);
        if (res.success) {
          this.buildNum = res.data.buildingList;
        }
      });
    },
    //新增设备时楼栋改变时获取教室
    selectClassroom() {
      (this.form.classroomId = undefined),
        this.$api
          .getAllUnBindDeviceClassroom({ buildingId: this.form.buildingId })
          .then((res) => {
            // console.log(res);
            this.classroomNum = res.data.classrooms;
          });
    },
    //关机
    turnOff() {
      if (this.listSome) {
        if (this.selectOnline) {
          this.$confirm({
            title: "您确认关机吗",
            onOk: () => {
              console.log("发送关机指令");
              let userInfo = this.$ls.get("userInfo");
              let obj3 = {
                action: 2,
                extand: "user",
                chatMsg: {
                  senderId: userInfo.id,
                  msg: "关机",
                  msgType: 1,
                  data: { devices: [] },
                },
              };
              obj3.chatMsg.data.devices = this.checkList;
              console.log(obj3);
              this.websocket.send(JSON.stringify(obj3));
            },
          });
        } else {
          this.$message.error("您只能勾选在线的设备");
        }
      } else {
        this.$message.error("你选择你需要操作的设备");
      }
    },
    //重启
    turnAgain() {
      if (this.listSome) {
        if (this.selectOnline) {
          this.$confirm({
            title: "您确认重启吗",
            onOk: () => {
              console.log("发送重启指令");
              let userInfo = this.$ls.get("userInfo");
              let obj3 = {
                action: 2,
                extand: "user",
                chatMsg: {
                  senderId: userInfo.id,
                  msg: "重启",
                  msgType: 3,
                  data: { devices: [] },
                },
              };
              obj3.chatMsg.data.devices = this.checkList;
              console.log(obj3);
              this.websocket.send(JSON.stringify(obj3));
            },
          });
        } else {
          this.$message.error("您只能勾选在线的设备");
        }
      } else {
        this.$message.error("你选择你需要操作的设备");
      }
    },
    //息屏
    informationScreen() {
      if (this.listSome) {
        if (this.selectOnline) {
          this.$confirm({
            title: "您确认息屏吗",
            onOk: () => {
              console.log("发送息屏指令");
              let userInfo = this.$ls.get("userInfo");
              let obj3 = {
                action: 2,
                extand: "user",
                chatMsg: {
                  senderId: userInfo.id,
                  msg: "息屏",
                  msgType: 2,
                  data: { devices: [] },
                },
              };
              obj3.chatMsg.data.devices = this.checkList;
              console.log(obj3);
              this.websocket.send(JSON.stringify(obj3));
            },
          });
        } else {
          this.$message.error("您只能勾选在线的设备");
        }
      } else {
        this.$message.error("你选择你需要操作的设备");
      }
    },
    //亮屏
    sendLight() {
      if (this.listSome) {
        if (this.selectOnline) {
          this.$confirm({
            title: "您确认亮屏吗",
            onOk: () => {
              console.log("发送亮屏指令");
              let userInfo = this.$ls.get("userInfo");
              let obj3 = {
                action: 2,
                extand: "user",
                chatMsg: {
                  senderId: userInfo.id,
                  msg: "亮屏",
                  msgType: 19,
                  data: { devices: [] },
                },
              };
              obj3.chatMsg.data.devices = this.checkList;
              console.log(obj3);
              this.websocket.send(JSON.stringify(obj3));
            },
          });
        } else {
          this.$message.error("您只能勾选在线的设备");
        }
      } else {
        this.$message.error("你选择你需要操作的设备");
      }
    },
    //跳转详情页
    toDetail(serialNum, isOnline, buildingName, classroomName) {
      if (isOnline === true) {
        this.$router.push(
          `/equipment/dev-detail/${serialNum}/${buildingName}/${classroomName}`
        );
      } else {
        this.$message.error("当前设备处于离线或故障状态，不能查看详情页");
      }
    },
    //消息推送的弹出框
    newsSend() {
      if (this.listSome) {
        if (this.selectOnline) {
          this.newsVisible = true;
        } else {
          this.$message.error("您只能勾选在线的设备");
        }
      } else {
        this.$message.error("你选择你需要操作的设备");
      }
    },
    //消息内容input框发生改变
    getContentLength() {
      // console.log(this.form.content.length);
      this.contentCount = this.newsForm.content.length;
    },
    //消息推送确定
    newsSendOk() {
      this.$refs.ruleNewsForm.validate((valid) => {
        if (valid) {
          // console.log(this.newsForm, this.checkList);
          let userInfo = this.$ls.get("userInfo");
          let obj2 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "消息推送",
              msgType: 5,
              data: { devices: [], msg: {} },
            },
          };
          obj2.chatMsg.data.devices = this.checkList;
          let form = {
            content: this.newsForm.content,
            showChannel: Number(this.newsForm.showChannel),
            duration: Number(this.newsForm.duration),
          };
          if (this.newsForm.isClose === "0") {
            form.isClose = true;
          } else {
            form.isClose = false;
          }
          obj2.chatMsg.data.msg = form;
          console.log(obj2);
          this.websocket.send(JSON.stringify(obj2));
          this.newsVisible = false;
          this.newsForm = {
            content: "",
            showChannel: "0",
            duration: undefined,
            isClose: "0",
          };
        }
      });
    },
    //消息推送取消
    newsSendCancel() {
      this.newsForm = {
        content: "",
        showChannel: "0",
        frequency: "1",
      };
      this.contentCount = 0;
    },
    //文件下发的弹出框
    fileSend() {
      if (this.listSome) {
        if (this.selectOnline) {
          this.fileVisible = true;
        } else {
          this.$message.error("您只能勾选在线的设备");
        }
      } else {
        this.$message.error("你选择你需要操作的设备");
      }
    },
    //获取Sts临牌
    getStsInfo() {
      //获取Token
      this.$api.getStsInfo().then((res) => {
        // console.log(res);
        if (res.success) {
          const {
            region,
            Bucket,
            AccessKeyId,
            AccessKeySecret,
            SecurityToken,
          } = res.data.data;
          this.dataObj = {
            region: region,
            bucket: Bucket,
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
          };
          return true;
        }
      });
    },
    //上传文件之前的操作
    beforeUpload(file) {
      this.getStsInfo();
      console.log(file);
      const isOk =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "aplication/zip " ||
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "application/vnd.ms-powerpoint" ||
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isOk) {
        this.$message.error("文件格式错误");
      }
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.error("文件不能大于50M");
      }
      return isLt50M && isOk;
    },
    //上传文件
    handleChange(info) {},
    //上传文件到oss
    async customRequest(action) {
      try {
        const client = Client(this.dataObj),
          file = action.file;
        //修改命名
        var fileExtension = file.name;
        let date = new Date().getTime();
        const random_name = "uploadFile/" + date + "_" + fileExtension;
        let result = await client.put(random_name, file);
        // console.log(result);
        if (result.res.statusCode === 200) {
          let userInfo = this.$ls.get("userInfo");
          let obj2 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "文件下发",
              msgType: 9,
              data: { devices: [] },
            },
          };
          obj2.chatMsg.data.devices = this.checkList;
          obj2.chatMsg.data.file = random_name;
          console.log(obj2);
          this.websocket.send(JSON.stringify(obj2));
          this.$message.success("文件下发成功");
          this.fileVisible = false;
        }
      } catch (error) {
        this.$message.error("上传失败");
      }
    },
    //文件下发确定
    fileSendOk() {},
    //文件下发取消
    fileSendCancel() {},
    //全选的复选框
    onChangeAll() {
      var _this = this;
      this.$nextTick(function () {
        // DOM 现在更新了
        // console.log(_this.checkAll);
      });
      if (_this.checkAll) {
        _this.checkList = [];
      } else {
        _this.checkList = [];
        _this.buildOptions.forEach((item) => {
          _this.checkList.push(item.serialNum);
        });
      }
    },
    //点击大屏巡课
    enlarge(item) {
      this.enlargeVisible = true;
      this.item = item;
    },
    //关闭大屏巡课
    enlargeClose() {
      this.enlargeVisible = false;
      this.item = null;
    },
    //下载大屏巡课的图片
    uploadEnlarge(item) {
      let url = "";
      if (item.isOnline && item.desktop != null) {
        url = item.desktop;
        const elink = document.createElement("a");
        elink.style.display = "none";
        elink.href = url;
        document.body.appendChild(elink);
        elink.click();
        // URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }
    },
    //定时任务点击消息推送的改变
    changeInvokeTarget() {
      if (this.timeForm.type) {
        this.timeForm.invokeTarget = "scheduleShutdown";
      } else {
        this.timeForm.invokeTarget = "scheduleMessage";
      }
    },
    //定时任务消息内容input框发生改变
    getContentLengthToo() {
      // console.log(this.form.content.length);
      this.contentCountToo = this.timeForm.content.length;
    },
    //定时任务
    handleTimedTask() {
      if (this.listSome) {
        this.timeVisible = true;
      } else {
        this.$message.error("你选择你需要操作的设备");
      }
    },
    //新增定时指令传的参数
    getParams() {
      let cronExpression = getCron(
        this.timeForm.type,
        this.timeForm.cycleType,
        this.timeForm.time,
        this.timeForm.week,
        this.timeForm.date
      );
      let job = "";
      if (this.timeForm.invokeTarget === "scheduleShutdown") {
        job = "关机";
      } else if (this.timeForm.invokeTarget === "scheduleRestart") {
        job = "重启";
      } else {
        job = "消息推送";
      }
      let scheduleType = "";
      if (this.timeForm.cycleType === 1) {
        scheduleType = "每天";
      } else if (this.timeForm.cycleType === 2) {
        scheduleType = "工作日";
      } else if (this.timeForm.cycleType === 3) {
        scheduleType = "周末";
      } else {
        scheduleType = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][
          this.timeForm.week
        ];
      }
      let checkList = this.checkList;
      let params = {
        cronExpression: cronExpression,
        invokeTarget: this.timeForm.invokeTarget,
        jobName: job,
        devList: checkList,
        executeTime: this.timeForm.time,
        jobType: job,
        scheduleType: scheduleType,
        status: true,
      };
      return params;
    },
    //新增定时推送传的参数
    getParamsToo() {
      let cronExpression = getCron(
        this.timeForm.type,
        this.timeForm.cycleType,
        this.timeForm.time,
        this.timeForm.week,
        this.timeForm.date
      );
      let job = "消息推送";
      let checkList = this.checkList;
      let params = {
        content: this.timeForm.content,
        cronExpression: cronExpression,
        invokeTarget: this.timeForm.invokeTarget,
        jobName: job,
        devList: checkList,
        executeTime: this.timeForm.time,
        jobType: job,
        scheduleType: this.timeForm.date,
        misfirePolicy: "3",
        status: true,
        isClose: this.timeForm.isClose,
        duration: this.timeForm.duration,
      };
      return params;
    },
    //定时任务确定
    timeSendOk() {
      this.$refs.ruleTimeForm.validate((valid) => {
        if (valid) {
          if (this.timeForm.type) {
            let params = this.getParams();
            console.log(params);
            this.$api.addScheduleJob(params).then((res) => {
              console.log(res);
              if (res.success) {
                this.$message.success("创建定时任务成功");
                this.timeVisible = false;
                this.$refs.ruleTimeForm.resetFields();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            let params = this.getParamsToo();
            console.log(params);
            this.$api.addMessageScheduleJob(params).then((res) => {
              console.log(res);
              if (res.success) {
                this.$message.success("创建定时任务成功");
                this.timeVisible = false;
                this.$refs.ruleTimeForm.resetFields();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    //定时任务取消
    timeSendCancel() {
      this.timeVisible = false;
      this.$refs.ruleTimeForm.resetFields();
      this.contentCountToo = 0;
    },
    //获取设备列表
    getDeviceList() {
      this.$api.getDeviceList().then((res) => {
        if (res.success) {
          let arr = res.data.devices.map((r) => r.serialNum);
          this.list = arr;
          this.websocketsendSecond();
          this.timer2 = setTimeout(this.websocketsendThird, 2000);
        }
      });
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = this.$globalVariable.wsurl; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      //连接建立之后执行send方法发送数据
      this.websocketsendFirst();
      this.getDeviceList();
      this.timer = setInterval(() => {
        this.doSend();
      }, 10 * 1000);
      this.timer1 = setInterval(() => {
        this.doSend1();
      }, 2 * 60 * 1000);
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend() {
      console.log("发送心跳");
      this.websocketsendSecond();
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend1() {
      console.log("发送心跳");
      this.websocketsendThird();
    },
    //首次发送数据 建立连接
    websocketsendFirst() {
      console.log("首次向后端发送数据");
      let userInfo = this.$ls.get("userInfo");
      let obj1 = {
        action: 1,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          receiverId: "",
          msg: "建立连接",
          msgType: 0,
        },
      };
      // console.log(JSON.stringify(obj1));
      this.websocket.send(JSON.stringify(obj1)); //发送数据，传什么数据由实际需求决定
    },
    //查看设备状态
    websocketsendSecond() {
      console.log("查询设备状态");
      let userInfo = this.$ls.get("userInfo");
      let obj2 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "查询设备状态",
          msgType: 4,
          data: { devices: [] },
        },
      };
      obj2.chatMsg.data.devices = this.list;
      if (
        obj2.chatMsg.data.devices.length === this.list.length &&
        obj2.chatMsg.data.devices.length > 0
      ) {
        console.log(obj2);
        this.websocket.send(JSON.stringify(obj2));
      }
    },
    //请求桌面上传
    websocketsendThird() {
      console.log("请求桌面上传");
      let userInfo = this.$ls.get("userInfo");
      let obj2 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "请求桌面上传",
          msgType: 18,
          data: { devices: [] },
        },
      };
      let arr = this.buildOptions.filter((r) => r.isOnline);
      this.buildOptions.forEach((r) => {
        if (r.isOnline) {
          obj2.chatMsg.data.devices.push(r.serialNum);
        }
      });
      if (
        obj2.chatMsg.data.devices.length === arr.length &&
        obj2.chatMsg.data.devices.length > 0
      ) {
        console.log(JSON.stringify(obj2));
        this.websocket.send(JSON.stringify(obj2));
      }
    },
    //数据接收
    websocketonmessage(e) {
      console.log("接收后端返回数据");
      var result = JSON.parse(e.data);
      console.log(result);
      if (result.msgType === 0) {
        if (result.data) {
          // console.log(result.data.devices);
          this.buildOptions = result.data.devices;
          //搜索功能
          if (this.statusForm.region == 3) {
            this.buildOptions = this.buildOptions.filter((rrr) =>
              (rrr.buildingName + rrr.classroomName).includes(this.value)
            );
          } else {
            this.buildOptions = this.buildOptions.filter((rrr) => {
              if (this.statusForm.region === 2) {
                return (
                  rrr.isRepair === true &&
                  (rrr.buildingName + rrr.classroomName).includes(this.value)
                );
              } else if (this.statusForm.region === 1) {
                return (
                  rrr.isOnline === true &&
                  (rrr.buildingName + rrr.classroomName).includes(this.value)
                );
              } else {
                return (
                  rrr.isOnline === false &&
                  (rrr.buildingName + rrr.classroomName).includes(this.value)
                );
              }
            });
          }
          //大屏展示图片变化功能
          if (this.item != null) {
            let arr = JSON.parse(JSON.stringify(this.buildOptions));
            let obj = JSON.parse(JSON.stringify(this.item));
            let obj1 = {};
            arr.forEach((r) => {
              if (r.serialNum === obj.serialNum) {
                obj1 = r;
              }
            });
            if (obj1.desktop != obj.desktop) {
              this.item = obj1;
            }
          }
        }
      }
    },
    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
      clearInterval(this.timer2);
    },
  },
  //vue生命周期创建后
  created() {
    this.getBuild();
    this.getStsInfo();
    this.initWebSocket();
  },
  destroyed() {
    this.websocketclose();
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scoped src="./device.scss"></style>
