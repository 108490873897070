<template>
  <div class="ContainerMessage" v-if="show">
    <div class="d-f j-b">
      <div class="titleMessage">新消息通知({{ count }}个)</div>
      <div class="historyMessage" @click="historyMessage" v-if="passReview">历史消息</div>
    </div>
    <div v-if="messageList.length > 0">
      <div class="messageList" v-for="item in messageList" :key="item.id">
        <h3>{{ item.name }}</h3>
        <p>{{ item.content }}</p>
        <div class="date">
          <span>{{ item.gmtCreate }}</span>
          <a @click="delMessage(item.id)" class="del">
            <a-icon type="delete" />删除该通知
          </a>
        </div>
        <div class="passBtns" v-if="passReview">
          <a-button type="primary" class="pass" @click="passBtn(item.id)">通过</a-button>
          <a-button class="noPass" @click="noPassBtn(item.id)">不通过</a-button>
        </div>
      </div>
      <div class="pagination d-f a-c">
        <a-pagination
          v-model="pageIndex"
          :pageSize="pageSize"
          :default-current="1"
          :total="count"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          class="pagination"
        />
      </div>
    </div>
    <div class="no-data d-f f-d a-c j-c" v-else>
      <img src="@/assets/img/new_message_nodata.png" alt="" />
      <p>暂无消息</p>
    </div>
  </div>
  <div class="ContainerMessage" v-else>
    <div class="d-f j-b">
      <div class="titleMessage">历史消息({{ historyCount }}个)</div>
      <div class="historyMessage" @click="newMessage">返回新消息通知</div>
    </div>
    <div v-if="historyMessageList.length > 0">
      <div class="messageList" v-for="item in historyMessageList" :key="item.id">
        <h3>{{ item.name }}</h3>
        <p>{{ item.content }}</p>
        <div class="date">
          <span>{{ item.gmtCreate }}</span>
          <a @click="delMessage(item.id)" class="del">
            <a-icon type="delete" />删除该通知
          </a>
        </div>
        <div class="passBtns" v-if="item.isReviewed === '1'">
          <span class="status">通过审核</span>
        </div>
        <div class="passBtns" v-else>
          <span class="status">未通过审核</span>
        </div>
      </div>
      <div class="pagination d-f a-c">
        <a-pagination
          v-model="pageIndex1"
          :pageSize="pageSize1"
          :default-current="1"
          :total="historyCount"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange1"
          :item-render="itemRender"
          class="pagination"
        />
      </div>
    </div>
    <div class="no-data d-f f-d a-c j-c" v-else>
      <img src="@/assets/img/new_message_nodata.png" alt="" />
      <p>暂无消息</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messageList: [],
      count: 0,
      pageIndex: 1,
      pageSize: 3,
      show: true,
      historyCount: 0,
      historyMessageList: [],
      pageIndex1: 1,
      pageSize1: 3,
    };
  },
  methods: {
    //通过
    passBtn(id) {
      this.$confirm({
        title: "您确认通过吗",
        onOk: () => {
          this.$api.passReview({ id: id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("审核通过成功");
              this.getMessageList();
              this.getHistoryMessageList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //不通过
    noPassBtn(id) {
      this.$confirm({
        title: "您确认不通过吗",
        onOk: () => {
          this.$api.unPassReview({ id: id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("审核不通过成功");
              this.getMessageList();
              this.getHistoryMessageList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //获取消息列表
    getMessageList() {
      this.$api
        .getMessageList({
          limit: this.pageSize,
          page: this.pageIndex,
        })
        .then((res) => {
          if (res.success) {
            if (!res.data.messages.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getMessageList({
                  page: this.pageIndex,
                  limit: this.pageSize,
                })
                .then((res) => {
                  this.messageList = res.data.messages;
                  this.count = res.data.total;
                });
            } else {
              this.messageList = res.data.messages;
              this.count = res.data.total;
            }
          }
        });
    },
    //删除消息通知
    delMessage(id) {
      this.$confirm({
        title: "您确认删除该消息吗",
        onOk: () => {
          this.$api.delMessage({ id: id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("删除成功");
              this.getMessageList();
              this.getHistoryMessageList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //新消息通知分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getMessageList();
    },
    //历史消息分页状态改变
    onChange1(pageNumber) {
      this.pageIndex1 = pageNumber;
      this.getHistoryMessageList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //点击历史消息实现tab切换
    historyMessage() {
      this.show = false;
    },
    //返回新消息通知
    newMessage() {
      this.show = true;
    },
    //获取历史消息列表
    getHistoryMessageList() {
      this.$api
        .getHistoryMessageList({
          limit: this.pageSize1,
          page: this.pageIndex1,
        })
        .then((res) => {
          if (res.success) {
            if (!res.data.messages.length && this.pageIndex1 > 1) {
              this.pageIndex1--;
              this.$api
                .getHistoryMessageList({
                  limit: this.pageSize1,
                  page: this.pageIndex1,
                })
                .then((res) => {
                  this.historyCount = res.data.total;
                  this.historyMessageList = res.data.messages;
                });
            } else {
              this.historyCount = res.data.total;
              this.historyMessageList = res.data.messages;
            }
          }
        });
    },
  },
  created() {
    this.getMessageList();
    this.getHistoryMessageList();
  },
  computed: {
    //消息审核通过
    passReview() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("user.passReview")) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scope>
@import "./new-message.scss";
</style>
