<template>
  <div>
    <!--面包屑-->
    <BreadTeacher></BreadTeacher>

    <div class="container-teacher">
      <div class="container-total">
        <!--老师总数-->
        <div class="title">
          <div class="title-text">老师（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="teacher-list">
          <!--搜索框和添加老师还有全删-->
          <div class="teacher-manage d-f j-b a-c">
            <div class="search">
              <a-input-search
                placeholder="请输入老师姓名或手机号"
                style="width: 244px"
                @search="onSearch"
                v-model="value"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <span v-if="teacherAdd">
                <a-button type="primary" @click="showModal"
                  ><a-icon type="plus" />新增老师</a-button
                >
              </span>
              <a-dropdown v-if="teacherAdd">
                <a-menu slot="overlay">
                  <a-menu-item key="1" @click="excel">Excel表格导入</a-menu-item>
                  <a-menu-item key="2" @click="copy">批量复制导入</a-menu-item>
                </a-menu>
                <a-button class="copy-all"><a-icon type="carry-out" />批量导入</a-button>
              </a-dropdown>
              <a-button @click="delAll" class="del-all" v-if="teacherRemove">
                批量删除
              </a-button>
              <!--excel导入的弹出框-->
              <a-modal v-model="excelVisible" title="批量导入" :footer="null">
                <div class="d-f f-d j-c a-c excel">
                  <p>为方便您快速添加老师，推荐您下载信息模板，</p>
                  <p>请按照模板信息录入老师资料，上传导入</p>
                  <table>
                    <tr>
                      <td>老师姓名</td>
                      <td>手机号</td>
                    </tr>
                    <tr>
                      <td>汪女士</td>
                      <td>1559886675</td>
                    </tr>
                  </table>
                  <p>（信息示范）</p>
                  <div class="excel-line"></div>
                  <div class="excel-space d-f">
                    <div class="my-button">
                      <a-button type="primary"
                        ><a @click="downloadExecl">下载Excel模板</a></a-button
                      >
                      <a-upload
                        name="file"
                        :action="action"
                        :headers="headers"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                        accept=".xlsx,.xls"
                        :showUploadList="false"
                      >
                        <a-button type="primary">上传文件</a-button>
                      </a-upload>
                    </div>
                  </div>
                </div>
              </a-modal>
              <!--批量复制的弹出框-->
              <a-modal v-model="copyVisible" title="批量复制" :footer="null">
                <div class="copy">
                  <p>可以从Excel表格中复制粘贴(用户名默认是手机号，密码默认是手机号)</p>
                  <table>
                    <tr>
                      <td>序号</td>
                      <td>老师姓名（必填）</td>
                      <td>手机号（必填）</td>
                    </tr>
                    <tr v-for="(item, index) in copyList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <input
                          type="text"
                          placeholder="请输入老师姓名"
                          v-model="item.name"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="请输入老师手机号"
                          v-model="item.phone"
                        />
                      </td>
                    </tr>
                  </table>
                  <div class="d-f f-d a-c j-c">
                    <div class="copy-line"></div>
                  </div>
                  <div class="copy-space d-f">
                    <div class="my-button">
                      <a-button type="primary" @click="copyConfirm">确定</a-button>
                    </div>
                  </div>
                </div>
              </a-modal>
              <!--新增老师的弹出框-->
              <a-modal
                v-model="visible"
                title="新增老师"
                @ok="hideModal"
                @cancel="cancelModal"
              >
                <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item label="用户名" prop="username">
                    <a-input v-model="form.username" placeholder="请输入用户名" />
                  </a-form-model-item>
                  <a-form-model-item label="老师姓名" prop="name">
                    <a-input v-model="form.name" placeholder="请输入老师姓名" />
                  </a-form-model-item>
                  <a-form-model-item label="老师手机号" prop="phone">
                    <a-input v-model="form.phone" placeholder="请输入老师手机号" />
                  </a-form-model-item>
                  <a-form-model-item label="用户角色" prop="roleId">
                    <a-select
                      v-model="form.roleId"
                      placeholder="请选择用户角色"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    >
                      <a-select-option
                        :value="item.id"
                        :key="item.id"
                        v-for="item in role"
                      >
                        {{ item.roleName }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="密码">
                    <a-input
                      type="password"
                      v-model="form.password"
                      placeholder="可不填，默认密码是手机号"
                    />
                  </a-form-model-item>
                </a-form-model>
              </a-modal>
            </div>
          </div>
          <!--列表-->
          <div class="teacher-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="teacherData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)" v-if="teacherUpdate && record.id != userInfo.id"
                  >编辑</a
                >
                <a-divider
                  type="vertical"
                  v-if="teacherUpdate && teacherRemove && record.id != userInfo.id"
                />
                <a @click="del(record)" v-if="teacherRemove && record.id != userInfo.id"
                  >删除</a
                >
                <a @click="toUser" v-if="record.id === userInfo.id">个人中心</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改老师信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="用户名" prop="username">
                  <a-input v-model="editForm.username" placeholder="请输入用户名" />
                </a-form-model-item>
                <a-form-model-item label="老师姓名" prop="name">
                  <a-input v-model="editForm.name" placeholder="请输入老师姓名" />
                </a-form-model-item>
                <a-form-model-item label="老师手机号" prop="phone">
                  <a-input v-model="editForm.phone" placeholder="请输入老师手机号" />
                </a-form-model-item>
                <a-form-model-item label="用户角色" prop="roleId">
                  <a-select
                    v-model="editForm.roleId"
                    placeholder="请选择用户角色"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option :value="item.id" :key="item.id" v-for="item in role">
                      {{ item.roleName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="密码">
                  <a-input
                    type="password"
                    v-model="editForm.password"
                    placeholder="可不填，默认是上一次密码"
                  />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadTeacher from "~c/bread/BreadTeacher.vue";
const columns = [
  {
    title: "用户名",
    dataIndex: "username",
    width: "15%",
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "name",
    width: "15%",
    align: "center",
  },
  {
    title: "手机号",
    dataIndex: "phone",
    width: "20%",
    align: "center",
  },
  {
    title: "用户角色",
    dataIndex: "roleNameList",
    width: "25%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const copyList = [
  {
    name: "",
    phone: null,
  },
  {
    name: "",
    phone: null,
  },
  {
    name: "",
    phone: null,
  },
  {
    name: "",
    phone: null,
  },
  {
    name: "",
    phone: null,
  },
  {
    name: "",
    phone: null,
  },
  {
    name: "",
    phone: null,
  },
  {
    name: "",
    phone: null,
  },
];
export default {
  components: {
    BreadTeacher,
  },
  data() {
    return {
      value: "",
      visible: false,
      editVisible: false,
      excelVisible: false,
      copyVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        username: "",
        name: "",
        phone: null,
        roleId: undefined,
        password: "",
      },
      editForm: {
        username: "",
        name: "",
        phone: null,
        roleId: undefined,
        id: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[A-Za-z][0-9A-Za-z]{2,12}$/;
              if (!reg.test(value)) {
                callbackFn("用户名只能包括数字(不以数字开头)和字母且在3~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 15,
            message: "姓名不能超过15个字符",
            trigger: ["change", "blur"],
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入老师手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callbackFn("手机号码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        roleId: [
          {
            required: true,
            message: "请选择用户角色",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            validator: (rule, value, callbackFn) => {
              const reg = /(^$)|^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,12}$/;
              if (!reg.test(value)) {
                callbackFn("密码必须包括数字和字母且在6~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[A-Za-z][0-9A-Za-z]{2,12}$/;
              if (!reg.test(value)) {
                callbackFn("用户名只能包括数字(不以数字开头)和字母且在3~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 15,
            message: "姓名不能超过15个字符",
            trigger: ["change", "blur"],
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入老师手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callbackFn("手机号码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        roleId: [
          {
            required: true,
            message: "请选择用户角色",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            validator: (rule, value, callbackFn) => {
              const reg = /(^$)|^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,12}$/;
              if (!reg.test(value)) {
                callbackFn("密码必须包括数字和字母且在6~12位之间");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      teacherData: [],
      role: [],
      columns,
      selectedRowKeys: [],
      record: {},
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
      headers: {
        authorization: "authorization-text",
        token: this.$ls.get("token"),
      },
      action: process.env.VUE_APP_BASEURL + "acl/user/uploadAddBatchTeacher/",
      copyList,
      userInfo: null,
    };
  },
  methods: {
    //验证批量复制导入的姓名
    validatorName(val) {
      if (val === "" || val.split(" ").join("").length === 0) {
        this.$message.error("姓名为空");
        return false;
      } else if (val.length > 15) {
        this.$message.error("姓名长度不能大于15个字符");
        return false;
      } else {
        return true;
      }
    },
    //验证批量复制导入的手机号
    validatorPhone(val) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(val)) {
        this.$message.error("有老师手机号码格式错误");
        return false;
      } else {
        return true;
      }
    },
    //搜索框
    onSearch() {
      if (this.value.length > 15) {
        this.$message.error("搜索内容保持在15个字符以内");
        this.value = "";
      } else {
        // console.log(this.value);
        this.getTeacherList();
      }
    },
    //新增老师
    showModal() {
      this.getRoleList();
      this.visible = true;
    },
    //新增老师确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (
            this.form.password == "" ||
            this.form.password.split(" ").join("").length === 0
          ) {
            this.form.password = this.form.phone;
          }
          // console.log(this.form);
          this.$api.teacherAdd(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("新增成功");
              this.getTeacherList();
              this.form = {
                username: "",
                name: "",
                phone: null,
                roleId: undefined,
                password: "",
              };
              this.visible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增老师
    cancelModal() {
      this.form = {
        username: "",
        name: "",
        phone: null,
        roleId: undefined,
        password: "",
      };
    },
    //编辑老师
    edit(record) {
      //   console.log(record);
      this.getRoleList();
      this.editForm.name = record.name;
      this.editForm.username = record.username;
      this.editForm.phone = record.phone;
      this.editForm.roleId = record.roleId;
      this.editForm.id = record.id;
      this.editForm.password = "";
      this.editVisible = true;
    },
    //删除老师
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.userDel({ id: record.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("删除成功");
              this.getTeacherList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          if (this.editForm.password == undefined) {
            console.log(this.editForm);
            this.$api.teacherUpdate(this.editForm).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功");
                this.getTeacherList();
                this.editVisible = false;
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            if (
              this.editForm.password == "" ||
              this.editForm.password.split(" ").join("").length === 0
            ) {
              delete this.editForm["password"];
            }
            this.$api.teacherUpdate(this.editForm).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功");
                this.getTeacherList();
                this.editVisible = false;
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        username: "",
        name: "",
        phone: null,
        roleId: undefined,
        id: "",
        password: "",
      };
    },
    //去个人中心
    toUser() {
      this.$router.push("/user");
    },
    //批量删除
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            let arr = this.selectedRowKeys;
            let arr1 = arr.filter((r) => r === this.userInfo.id);
            if (arr1.length > 0) {
              this.$message.error("您选择删除的老师中包含自己");
            } else {
              this.$api.userDelAll(this.selectedRowKeys).then((res) => {
                if (res.success) {
                  this.$message.success("批量删除成功");
                  this.getTeacherList();
                } else {
                  this.$message.error(res.message);
                }
              });
            }
          },
        });
      } else {
        this.$message.error("请选择你想删除的老师");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //分页器页数改变
    onChange(pageNumber) {
      // console.log("Page: ", pageNumber);
      this.pageIndex = pageNumber;
      this.getTeacherList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //excel表格导入
    excel() {
      this.excelVisible = true;
    },
    //批量复制导入
    copy() {
      this.copyVisible = true;
    },
    //上传文件之前的操作
    beforeUpload(file) {
      // const isJpgOrPng = file.type === 'xlsx' || file.type === 'xls';
      // if (!isJpgOrPng) {
      //   this.$message.error('你只能上传excel文件类型');
      // }
      const isOk =
        file.type === "application/vnd.ms-excel" ||
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isOk) {
        this.$message.error("文件格式错误");
      }
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.error("文件不能大于50M");
      }
      return isLt50M && isOk;
    },
    //上传文件
    handleChange(info) {
      //   if (info.file.status !== 'uploading') {
      //     console.log(info.file, info.fileList);
      //   }
      // console.log(info);
      if (info.file.response) {
        if (info.file.status === "done") {
          if (info.file.response.success) {
            this.$message.success(`${info.file.name}上传成功`);
            this.excelVisible = false;
            this.getTeacherList();
          } else {
            this.$message.error(info.file.response.message);
            this.getTeacherList();
          }
        }
      }
    },
    //下载excel模板
    downloadExecl() {
      this.$api.downloadExecl().then((res) => {
        // console.log(res);
        // console.log(res.data.url);
        if (res.success) {
          const elink = document.createElement("a");
          elink.style.display = "none";
          elink.href = res.data.url;
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    },
    //确定批量复制
    copyConfirm() {
      // console.log(this.copyList)
      let arr = this.copyList.filter((r) => {
        return r.name != "" && r.phone != "";
      });
      let arr1 = [];
      arr.forEach((r) => {
        if (!this.validatorName(r.name) || !this.validatorPhone(r.phone)) {
        } else {
          r.username = r.phone;
          r.password = r.phone;
          arr1.push(r);
        }
      });
      // console.log(arr1);
      if (arr1.length === arr.length) {
        this.$api.teacherAllAdd(arr1).then((res) => {
          console.log(res);
          if (res.success) {
            this.$message.success("新增成功");
            this.getTeacherList();
            this.copyList.forEach((r) => {
              r.name = "";
              r.phone = null;
            });
            this.copyVisible = false;
          } else {
            this.$message.error(res.error);
          }
        });
      }
    },
    //获取老师列表
    getTeacherList() {
      this.$api
        .getTeacherList({
          search: this.value,
          page: this.pageIndex,
          limit: this.pageSize,
          status: "2",
        })
        .then((res) => {
          // console.log(res);
          if (res.success) {
            if (!res.data.response.userList.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getTeacherList({
                  search: this.value,
                  page: this.pageIndex,
                  limit: this.pageSize,
                  status: "2",
                })
                .then((res) => {
                  this.teacherData = res.data.response.userList;
                  this.totalCount = res.data.response.total;
                });
            } else {
              this.teacherData = res.data.response.userList;
              this.totalCount = res.data.response.total;
            }
          }
        });
    },
    // 获取角色列表
    getRoleList() {
      this.$api.getRoleList().then((res) => {
        // console.log(res);
        if (res.success) {
          let roleCode = this.$ls.get("userInfo").roleCode;
          let arr = res.data.items.filter((r) => {
            return r.roleCode >= roleCode;
            // return r.roleCode === 100;
          });
          this.role = arr;
        }
      });
    },
  },
  computed: {
    //老师添加按钮的显示隐藏
    teacherAdd() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("user.addTeacher")) {
        return true;
      } else {
        return false;
      }
    },
    //老师删除按钮的显示隐藏
    teacherRemove() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("teacher.remove")) {
        return true;
      } else {
        return false;
      }
    },
    //老师编辑按钮的显示隐藏
    teacherUpdate() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("user.updateTeacher")) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getTeacherList();
    let userInfo = this.$ls.get("userInfo");
    this.userInfo = userInfo;
  },
  mounted() {},
};
</script>

<style lang="scss" scoped src="./teacher.scss"></style>
