<!--
 * @Author: 庄志莹
 * @Date: 2021-04-08 09:30:41
 * @LastEditTime: 2021-04-29 16:31:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\equipment\task-directive\TaskDirective.vue
-->
<template>
  <div>
    <!--面包屑-->
    <BreadTaskDirective></BreadTaskDirective>

    <div class="container-task-directive">
      <div class="container-total">
        <!--导航栏-->
        <div class="title d-f a-c">
          <nav>定时任务</nav>
        </div>
        <!--一根线条-->
        <div class="line"></div>

        <!--下边的部分-->
        <div>
          <!--日期和指令选择-->
          <div class="d-f a-c search">
            <div class="search-content d-f a-c">
              <a-form-model
                :model="statusForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                class="status-dev"
              >
                <a-form-model-item label="任务名称">
                  <a-select
                    v-model="statusForm.region"
                    @change="changeSelect"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option value="全部">全部</a-select-option>
                    <a-select-option value="关机">关机</a-select-option>
                    <a-select-option value="重启">重启</a-select-option>
                    <a-select-option value="消息推送">消息推送</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
          <!-- 操作日志的表格和分页 -->
          <div class="done-page">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="jobId"
              :data-source="data"
              :pagination="false"
            >
              <span slot="action" slot-scope="text, record">
                <a @click="desc(record)">查看详情</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
                <a-divider type="vertical" />
                <a-switch :checked="record.status" @change="actionChange(record)" />
              </span>
            </a-table>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
        <!--任务指令查看详情的弹出框-->
        <a-modal
          v-model="visible"
          title="查看详情"
          :body-style="{ height: '300px', overflowY: 'auto' }"
          :footer="null"
          class="modal-status"
          @cancel="cancel"
        >
          <div v-if="isPush" class="is-push d-f f-d a-c">
            <a-textarea v-model="content" disabled class="content" autosize />
          </div>

          <a-table
            :columns="columns1"
            rowKey="id"
            :data-source="data1"
            :pagination="false"
            size="small"
          >
            <span slot="name" slot-scope="text, record">
              {{ record.buildingName + record.classroomName }}
            </span>
          </a-table>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import BreadTaskDirective from "~c/bread/BreadTaskDirective.vue";
const columns = [
  {
    title: "指令名称",
    dataIndex: "jobType",
    width: "10%",
    align: "center",
  },
  {
    title: "执行周期",
    dataIndex: "scheduleType",
    width: "20%",
    align: "center",
  },
  {
    title: "执行时间",
    dataIndex: "executeTime",
    width: "20%",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "gmtCreate",
    width: "20%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "30%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const columns1 = [
  {
    title: "设备名称",
    dataIndex: "name",
    width: "100%",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
];
export default {
  components: {
    BreadTaskDirective,
  },
  data() {
    return {
      //消息推送根据指令名称搜索
      statusForm: {
        region: "全部",
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      //消息推送的页面显示
      data: [],
      columns,
      columns1,
      data1: [],
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
      visible: false,
      isPush: false,
      content: "",
    };
  },
  methods: {
    //操作日志指令名称改变
    changeSelect() {
      this.getScheduleJobList();
    },
    //操作日志分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getScheduleJobList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //查看详情
    desc(record) {
      this.visible = true;
      this.$api.getScheduleJobDetail({ jobId: record.jobId }).then((res) => {
        if (res.success) {
          this.data1 = res.data.list;
          console.log(this.data1);
          if (record.jobType === "消息推送") {
            this.isPush = true;
            this.content = "【消息内容】：" + res.data.content;
          } else {
            this.isPush = false;
          }
        }
      });
    },
    //查看详情
    cancel() {
      this.data1 = [];
      this.content = "";
    },
    //删除
    del(record) {
      this.$confirm({
        title: "您确认删除该定时任务吗",
        onOk: () => {
          this.$api.delScheduleJob({ jobId: record.jobId }).then((res) => {
            if (res.success) {
              this.getScheduleJobList();
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //所有设备开关状态改变
    actionChange(record) {
      this.$confirm({
        title: "您确认改变该定时任务状态吗",
        onOk: () => {
          //异步请求
          this.$api
            .changeScheduleJobStatus({ jobId: record.jobId, status: !record.status })
            .then((res) => {
              if (res.success) {
                this.$message.success("修改成功");
                this.getScheduleJobList();
              } else {
                this.$message.error(res.message);
              }
            });
        },
      });
    },
    //获取定时指令列表
    getScheduleJobList() {
      this.$api
        .getScheduleJobList({
          page: this.pageIndex,
          limit: this.pageSize,
          jobType: this.statusForm.region,
        })
        .then((res) => {
          if (res.success) {
            if (!res.data.list.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getScheduleJobList({
                  page: this.pageIndex,
                  limit: this.pageSize,
                  jobType: this.statusForm.region,
                })
                .then((res) => {
                  this.data = res.data.list;
                  this.totalCount = res.data.total;
                });
            } else {
              this.data = res.data.list;
              this.totalCount = res.data.total;
            }
          }
        });
    },
  },
  //vue生命周期创建后
  created() {
    this.getScheduleJobList();
  },
};
</script>

<style lang="scss" scoped src="./task-directive.scss"></style>
