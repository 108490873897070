<!--
 * @Author: 庄志莹
 * @Date: 2020-12-26 17:01:31
 * @LastEditTime: 2021-04-19 16:07:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: 
-->
<template>
  <div>
    <!--面包屑-->
    <BreadInfoPush></BreadInfoPush>

    <div class="container-info-push">
      <div class="container-total">
        <!--上面的tab切换-->
        <div class="title d-f a-c">
          <nav>
            <a-menu v-model="current" mode="horizontal">
              <a-menu-item @click="done" key="executed">消息推送</a-menu-item>
              <a-menu-item @click="wait" key="execute">文件下发</a-menu-item>
            </a-menu>
          </nav>
        </div>
        <!-- 消息推送 -->

        <!-- 消息推送的整体页面-->
        <div v-if="donePage">
          <!--搜索框和日期还有全删-->
          <div class="d-f a-c search">
            <div class="search-content d-f a-c">
              <div class="modal-region">
                <a-range-picker @change="selectDate1" />
              </div>
              <a-input-search
                placeholder="请输入消息内容关键字"
                style="width: 200px; margin-left: 12px"
                @change="onSearch1"
                v-model="value1"
              />
            </div>
          </div>
          <!-- 消息推送的表格和分页 -->
          <div class="done-page">
            <!--表格-->
            <a-table
              :columns="columns1"
              rowKey="id"
              :data-source="data1"
              :pagination="false"
            >
              <span slot="content" slot-scope="text">
                <span :title="text">{{ text }}</span>
              </span>
              <span slot="duration" slot-scope="text">
                <span>{{ text }}分钟</span>
              </span>
              <span slot="isClose" slot-scope="text">
                <span>{{ text | handleIsClose }}</span>
              </span>
              <span slot="status" slot-scope="text, record">
                <a-badge status="success" />{{ record.successCount }}台成功
                <span style="margin-left: 10px"></span>
                <a-badge status="error" />{{ record.failureCount }}台失败
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="desc1(record)">查看详情</a>
              </span>
            </a-table>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex1"
                :pageSize="pageSize1"
                :default-current="1"
                :total="totalCount1"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange1"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>

        <!--消息推送查看详情的弹出框-->
        <a-modal
          v-model="visible1"
          title="查看详情"
          :body-style="{ height: '300px', overflowY: 'auto' }"
          :footer="null"
          @cancel="hideVisible1"
          class="modal-status"
        >
          <button @click="unnormal" ref="unnormal" class="btn unnormal">异常</button>
          <button @click="success" ref="success" class="btn success">成功</button>
          <!-- 异常的表格 -->
          <div v-if="unnormalPage">
            <a-table
              :columns="columns11"
              rowKey="id"
              :data-source="data11"
              :pagination="false"
              size="small"
              :row-selection="rowSelection"
            >
              <span slot="device" slot-scope="text, record">
                <a-badge
                  :status="record.isClientOnline === true ? 'success' : 'error'"
                />{{ record.buildingName + record.classroomName }}
              </span>
              <span slot="failureReason" slot-scope="text" style="color: red">
                {{ text }}
              </span>
              <span slot="way" slot-scope="text, record">
                <span v-if="record.isClientOnline === true"
                  ><a @click="reon(record)">重发消息</a></span
                >
                <span v-else>——</span>
              </span>
            </a-table>
            <div class="all-reon-con">
              <a-button type="primary" class="all-reon" @click="allOn"
                >批量重发消息</a-button
              >
            </div>
          </div>
          <!-- 异常的表格 -->

          <!-- 成功的表格 -->
          <div v-if="successPage">
            <a-table
              :columns="columns12"
              rowKey="id"
              :data-source="data12"
              :pagination="false"
              size="small"
            >
              <span slot="device" slot-scope="text, record">
                {{ record.buildingName + record.classroomName }}
              </span>
              <span slot="isClientOnline" slot-scope="text">{{
                text | handeleIsClientOnline
              }}</span>
              <span slot="way"> —— </span>
            </a-table>
          </div>
          <!-- 成功的表格 -->
        </a-modal>

        <!-- 消息推送 -->

        <!-- 文件下发 -->
        <FilePush ref="getdata"></FilePush>
      </div>
    </div>
  </div>
</template>

<script>
import BreadInfoPush from "~c/bread/BreadInfoPush.vue";
import FilePush from "./file-push/file-push";
const columns1 = [
  {
    title: "消息内容",
    dataIndex: "content",
    width: "15%",
    align: "center",
    scopedSlots: { customRender: "content" },
    ellipsis: true,
  },
  {
    title: "发布者",
    dataIndex: "createUser",
    width: "10%",
    align: "center",
  },
  {
    title: "执行时间",
    dataIndex: "gmtCreate",
    width: "18%",
    align: "center",
  },
  {
    title: "展示时长",
    dataIndex: "duration",
    width: "10%",
    align: "center",
    scopedSlots: { customRender: "duration" },
  },
  {
    title: "设备关闭通知",
    dataIndex: "isClose",
    width: "12%",
    align: "center",
    scopedSlots: { customRender: "isClose" },
  },
  {
    title: "执行状态",
    dataIndex: "status",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "10%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const columns11 = [
  {
    title: "设备",
    dataIndex: "device",
    width: "30%",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "异常原因",
    dataIndex: "failureReason",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "failureReason" },
  },
  {
    title: "解决方案",
    dataIndex: "way",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "way" },
  },
];
const columns12 = [
  {
    title: "设备",
    dataIndex: "device",
    width: "30%",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "设备状态",
    dataIndex: "isClientOnline",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "isClientOnline" },
  },
  {
    title: "解决方案",
    dataIndex: "way",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "way" },
  },
];
export default {
  components: {
    BreadInfoPush,
    FilePush,
  },
  filters: {
    handeleIsClientOnline(val) {
      if (val === true) {
        return "在线";
      } else {
        return "离线";
      }
    },
    handleIsClose(val) {
      if (val === true) {
        return "允许";
      } else {
        return "不允许";
      }
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        },
        getCheckboxProps: (record) => ({
          props: {
            // 全部默认禁止选中
            // disabled: true,
            // 某几项默认禁止选中(R: 当state等于1时)
            disabled: record.isClientOnline === false,
            // 某几项默认选中(R: 当state等于1时) // defaultChecked: record.state == 1,
          },
        }),
      };
    },
  },
  data() {
    return {
      current: ["executed"], //导航菜单
      //消息推送根据关键字搜索
      value1: "",
      //消息推送根据日期搜索
      startTime1: "",
      endTime1: "",
      //消息推送的页面显示
      donePage: true,
      data1: [],
      columns1,
      totalCount1: null,
      pageIndex1: 1,
      pageSize1: 6,
      visible1: false,
      unnormalPage: true,
      successPage: false,
      columns11,
      data11: [],
      columns12,
      data12: [],
      selectedRowKeys: [],
      websocket: null,
      timer: null,
      record: {},
    };
  },
  methods: {
    //消息推送日期选择器
    selectDate1(date, dateString) {
      this.startTime1 = dateString[0];
      this.endTime1 = dateString[1];
      // console.log(this.startTime1, this.endTime1);
      this.getPushLogList();
    },
    //消息推送搜索框
    onSearch1() {
      this.getPushLogList();
    },
    //消息推送分页状态改变
    onChange1(pageNumber) {
      this.pageIndex1 = pageNumber;
      this.getPushLogList();
    },
    //消息推送查看详情
    desc1(record) {
      this.visible1 = true;
      this.record = record;
      this.initWebSocket();
    },
    //关闭消息推送查看详情
    hideVisible1() {
      this.websocketclose();
    },
    //消息推送的查看详情中异常按钮
    unnormal() {
      (this.unnormalPage = true), (this.successPage = false);
      this.$refs.success.classList.add("success");
      this.$refs.success.classList.remove("unnormal");
      this.$refs.unnormal.classList.add("unnormal");
      this.$refs.unnormal.classList.remove("success");
    },
    //消息推送的查看详情中成功按钮
    success() {
      (this.successPage = true), (this.unnormalPage = false);
      this.$refs.success.classList.add("unnormal");
      this.$refs.success.classList.remove("success");
      this.$refs.unnormal.classList.remove("unnormal");
      this.$refs.unnormal.classList.add("success");
    },
    //消息推送的查看详情中异常复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //消息推送的查看详情中异常重发指令
    reon(record) {
      this.$confirm({
        title: "您确认重发消息吗",
        onOk: () => {
          let userInfo = this.$ls.get("userInfo");
          let obj3 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "发送消息推送重发消息",
              msgType: 11,
              data: { messageId: "", ids: [] },
            },
          };
          obj3.chatMsg.data.messageId = record.messageId;
          obj3.chatMsg.data.ids.push(record.id);
          console.log(obj3);
          this.websocket.send(JSON.stringify(obj3));
          this.websocketsendSecond();
        },
      });
    },
    //消息推送的查看详情异常批量重发指令
    allOn() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认批量重发消息吗",
          onOk: () => {
            let userInfo = this.$ls.get("userInfo");
            let obj3 = {
              action: 2,
              extand: "user",
              chatMsg: {
                senderId: userInfo.id,
                msg: "发送消息推送批量重发消息",
                msgType: 11,
                data: { messageId: "", ids: [] },
              },
            };
            obj3.chatMsg.data.messageId = this.record.id;
            obj3.chatMsg.data.ids = this.selectedRowKeys;
            console.log(obj3);
            this.websocket.send(JSON.stringify(obj3));
            this.websocketsendSecond();
          },
        });
      } else {
        this.$message.error("请选择你想重发指令的设备");
      }
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //点击消息推送
    done() {
      this.donePage = true;
      this.$refs.getdata.waitPage = false;
    },
    //点击文件下发
    wait() {
      this.donePage = false;
      this.$refs.getdata.waitPage = true;
    },
    //获取消息推送列表
    getPushLogList() {
      this.$api
        .getPushLogList({
          page: this.pageIndex1,
          limit: this.pageSize1,
          search: this.value1,
          startTime: this.startTime1,
          endTime: this.endTime1,
        })
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.data1 = res.data.logList;
            this.totalCount1 = res.data.total;
          }
        });
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = this.$globalVariable.wsurl; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      //连接建立之后执行send方法发送数据
      this.websocketsendFirst();
      this.websocketsendSecond();
      this.timer = setInterval(() => {
        this.doSend();
      }, 10 * 1000);
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend() {
      console.log("发送心跳");
      this.websocketsendSecond();
    },
    //首次发送数据 建立连接
    websocketsendFirst() {
      console.log("首次向后端发送数据");
      let userInfo = this.$ls.get("userInfo");
      let obj1 = {
        action: 1,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          receiverId: "",
          msg: "建立连接",
          msgType: 0,
        },
      };
      this.websocket.send(JSON.stringify(obj1)); //发送数据，传什么数据由实际需求决定
    },
    //查看设备状态
    websocketsendSecond() {
      console.log("查询设备状态");
      let userInfo = this.$ls.get("userInfo");
      let obj2 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "查询设备状态",
          msgType: 10,
          data: {},
        },
      };
      obj2.chatMsg.data.pushLogId = this.record.id;
      console.log(obj2);
      this.websocket.send(JSON.stringify(obj2));
    },
    //数据接收
    websocketonmessage(e) {
      console.log("接收后端返回数据");
      var result = JSON.parse(e.data);
      console.log(result);
      if (result.data) {
        let data = result.data.detailList;
        if (data != undefined) {
          this.data11 = data.filter((r) => r.isSuccess === false);
          this.data12 = data.filter((r) => r.isSuccess === true);
        }
      }
    },
    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
    },
  },
  created() {
    this.getPushLogList();
  },
};
</script>

<style lang="scss" scoped src="./info-push.scss"></style>
