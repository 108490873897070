<template>
  <div>
    <!--面包屑-->
    <BreadClassroom></BreadClassroom>

    <div class="container-classroom">
      <div class="container-total">
        <!--教室总数-->
        <div class="title">
          <div class="title-text">教室（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="class-list">
          <!--搜索框和添加教室还有全删-->
          <div class="class-manage d-f j-b a-c">
            <div class="search">
              <a-input-search
                placeholder="请输入教室名称"
                style="width: 188px"
                @search="onSearch"
                v-model="value"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <a-button type="primary" @click="showModal"
                ><a-icon type="plus" /> 添加教室
              </a-button>
              <a-button @click="delAll" class="del-all"> 批量删除 </a-button>
              <!--添加教室的弹出框-->
              <a-modal
                v-model="visible"
                title="添加教室"
                @ok="hideModal"
                @cancel="cancelModal"
              >
                <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item label="教室名称" prop="name">
                    <a-input v-model="form.name" placeholder="请使用官方名称或教室编号" />
                  </a-form-model-item>
                  <a-form-model-item label="所在楼栋" prop="buildId">
                    <a-select
                      v-model="form.buildId"
                      placeholder="请选择楼栋"
                      @change="selectFloor"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    >
                      <a-select-option
                        :value="item.id"
                        v-for="item in buildList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="所在楼层">
                    <a-select
                      v-model="form.floorNum"
                      placeholder="请在选择楼栋后选择楼层"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    >
                      <a-select-option value=""> 不选楼层 </a-select-option>
                      <a-select-option
                        :value="item + ''"
                        v-for="item in floorList"
                        :key="item"
                      >
                        {{ item }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="容纳人数">
                    <a-input
                      v-model="form.studentNum"
                      placeholder="请输入容纳人数"
                      type="number"
                    />
                  </a-form-model-item>
                </a-form-model>
              </a-modal>
            </div>
          </div>
          <!--列表-->
          <div class="class-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              :data-source="classroomData"
              :pagination="false"
              :rowKey="(item) => JSON.stringify(item)"
              :scroll="{ x: 1300 }"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="equipment" slot-scope="text, record">
                <span v-if="record.serialNum != null">
                  <!-- @click="updateDev(record, $event)" -->
                  <a-tag color="blue">
                    <span v-if="record.deviceType == '1'">黑板</span>
                    ({{ record.serialNum }})
                  </a-tag>
                </span>
                <span v-else>
                  <span>无</span>
                  <!-- <a @click="add(record)">添加</a> -->
                </span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改教室信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="教室名称" prop="name">
                  <a-input
                    v-model="editForm.name"
                    placeholder="请使用官方名称或教室编号"
                  />
                </a-form-model-item>
                <a-form-model-item label="所在楼栋" prop="buildId">
                  <a-select
                    v-model="editForm.buildId"
                    placeholder="请选择楼栋"
                    @change="selectFloorToo"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.id"
                      v-for="item in buildList"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="所在楼层">
                  <a-select
                    v-model="editForm.floorNum"
                    placeholder="请在选择楼栋后选择楼层"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option value="">不选楼层 </a-select-option>
                    <a-select-option
                      :value="item + ''"
                      v-for="item in floorListToo"
                      :key="item"
                    >
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="容纳人数">
                  <a-input
                    v-model="editForm.studentNum"
                    placeholder="请输入容纳人数"
                    type="number"
                  />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--添加设备的弹出框-->
            <a-modal
              v-model="addVisible"
              title="添加设备"
              @ok="hideAdd"
              @cancel="cancelAdd"
            >
              <a-form-model
                ref="addForm"
                :model="addForm"
                :rules="addRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="序列号" prop="serialNum">
                  <a-input
                    v-model="addForm.serialNum"
                    placeholder="请输入序列号"
                    type="number"
                  />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--修改设备的弹出框-->
            <a-modal
              v-model="updateDevVisible"
              title="修改设备信息"
              @ok="hideUpdateDevVisible"
              @cancel="cancelUpdateDevVisible"
            >
              <a-form-model
                ref="updateDevForm"
                :model="updateDevForm"
                :rules="updateDevRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="序列号" prop="serialNum">
                  <a-input
                    v-model="updateDevForm.serialNum"
                    placeholder="请输入序列号"
                    type="number"
                  />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadClassroom from "~c/bread/BreadClassroom.vue";
const columns = [
  {
    title: "编号",
    dataIndex: "id",
    width: 200,
    align: "center",
  },
  {
    title: "教室名称",
    dataIndex: "name",
    width: 150,
    align: "center",
  },
  {
    title: "楼栋",
    dataIndex: "buildingName",
    width: 150,
    align: "center",
  },
  {
    title: "楼层",
    dataIndex: "floorNum",
    width: 150,
    align: "center",
  },
  {
    title: "容纳人数",
    dataIndex: "studentNum",
    width: 150,
    align: "center",
  },
  {
    title: "设备管理",
    dataIndex: "equipment",
    width: "25%",
    align: "center",
    fixed: "right",
    width: 300,
    scopedSlots: { customRender: "equipment" },
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "25%",
    align: "center",
    fixed: "right",
    width: 150,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadClassroom,
  },
  data() {
    return {
      value: "",
      visible: false,
      editVisible: false,
      addVisible: false,
      updateDevVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        buildId: undefined,
        floorNum: undefined,
        studentNum: null,
      },
      editForm: {
        id: "",
        name: "",
        buildId: undefined,
        floorNum: undefined,
        studentNum: null,
        serialNum: "",
        buildingName: "",
      },
      addForm: {
        serialNum: null,
      },
      updateDevForm: {
        serialNum: null,
        oldSerialNum: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入教室名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "教室名称不能超过20个字符",
            trigger: ["change", "blur"],
          },
        ],
        buildId: [
          {
            required: true,
            message: "请选择楼栋",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        name: [
          {
            required: true,
            message: "请输入教室名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "教室名称不能超过20个字符",
            trigger: ["change", "blur"],
          },
        ],
        buildId: [
          {
            required: true,
            message: "请选择楼栋",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      addRules: {
        serialNum: [
          {
            required: true,
            message: "请输入序列号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      updateDevRules: {
        serialNum: [
          {
            required: true,
            message: "请输入序列号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      classroomData: [],
      buildList: [],
      floorList: [],
      floorListToo: [],
      classList: [],
      columns,
      selectedRowKeys: [],
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
    };
  },
  methods: {
    //搜索框
    onSearch() {
      if (this.value.length > 20) {
        this.$message.error("搜索内容保持在20个字符以内");
      } else {
        // console.log(this.value);
        this.getClassroomList();
      }
    },
    //新增教室
    showModal() {
      this.visible = true;
    },
    //新增教室确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.floorNum = Number(this.form.floorNum);
          console.log(this.form);
          this.$api.classroomAdd(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("新增成功");
              this.getClassroomList();
              this.visible = false;
              this.form = {
                name: "",
                buildId: undefined,
                floorNum: undefined,
                studentNum: null,
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        name: "",
        buildId: undefined,
        floorNum: undefined,
        studentNum: null,
      };
    },
    //编辑教室
    edit(record) {
      this.editForm.name = record.name;
      this.editForm.buildingName = record.buildingName;
      this.editForm.buildId = record.buildId;
      if (record.floorNum == null || record.floorNum == 0) {
        this.editForm.floorNum = "";
      } else {
        this.editForm.floorNum = record.floorNum + "";
      }
      if (record.studentNum == null) {
        this.editForm.studentNum = "";
      } else {
        this.editForm.studentNum = record.studentNum + "";
      }
      this.editForm.id = record.id;
      this.editForm.serialNum = record.serialNum;
      // console.log(record.room);
      this.$api.getBuildList().then((res) => {
        if (res.success) {
          let obj = res.data.buildingList.find((r) => r.id === this.editForm.buildId);
          this.floorListToo = obj.totalFloor;
          this.editVisible = true;
        }
      });
    },
    //删除教室
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          let serialNum = "";
          if (record.serialNum != null) {
            serialNum = record.serialNum;
          }
          this.$api.classroomDel({ id: record.id, serialNum: serialNum }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getClassroomList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //添加设备
    add(record) {
      // console.log(record);
      this.addVisible = true;
      this.addForm.classroomId = record.id;
    },
    //添加设备确定
    hideAdd() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addForm.deviceType = 1;
          this.addForm.registerMark = this.addForm.serialNum;
          this.$api.addDevice(this.addForm).then((res) => {
            if (res.success) {
              this.$message.success("添加设备成功");
              this.getClassroomList();
              this.addVisible = false;
              this.addForm = {
                serialNum: null,
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消添加设备
    cancelAdd() {
      this.addForm = {
        serialNum: null,
      };
    },
    //tag标签设备修改
    updateDev(record, e) {
      this.updateDevVisible = true;
      this.updateDevForm.serialNum = record.serialNum;
      this.updateDevForm.oldSerialNum = record.serialNum;
      this.updateDevForm.classroomId = record.id;
    },
    //tag标签设备修改取消
    cancelUpdateDevVisible() {
      this.updateDevVisible = false;
      this.updateDevForm = {
        serialNum: null,
        oldSerialNum: null,
      };
    },
    //tag标签设备修改确定
    hideUpdateDevVisible() {
      this.$refs.updateDevForm.validate((valid) => {
        if (valid) {
          let params = {
            classroomId: this.updateDevForm.classroomId,
            oldSerialNum: this.updateDevForm.oldSerialNum,
            newSerialNum: this.updateDevForm.serialNum,
          };
          this.$api.updateDevice(params).then((res) => {
            if (res.success) {
              this.$message.success("修改设备成功");
              this.getClassroomList();
              this.updateDevVisible = false;
              this.updateDevForm = {
                serialNum: null,
                oldSerialNum: null,
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          console.log(this.editForm);
          this.editForm.floorNum = Number(this.editForm.floorNum);
          this.editForm.studentNum = Number(this.editForm.studentNum);
          this.$api.classroomUpdate(this.editForm).then((res) => {
            if (res.success) {
              this.$message.success("编辑成功");
              this.getClassroomList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        id: "",
        name: "",
        buildId: undefined,
        floorNum: undefined,
        studentNum: null,
      };
    },
    //全删
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            let selectedRowKeys = this.selectedRowKeys;
            let arr = [];
            selectedRowKeys.forEach((r) => {
              arr.push(JSON.parse(r));
            });
            let newlist = [];
            arr.forEach((r) => {
              newlist.push({ serialNum: r.serialNum, id: r.id });
            });
            newlist.forEach((r) => {
              if (r.serialNum === null) {
                r.serialNum = "";
              }
            });
            this.$api.classroomDelAll(newlist).then((res) => {
              if (res.success) {
                this.$message.success("批量删除成功");
                this.getClassroomList();
              } else {
                this.$message.error(res.message);
              }
            });
          },
        });
      } else {
        this.$message.error("请选择你想删除的教室");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //分页状态改变
    onChange(pageNumber) {
      // console.log("Page: ", pageNumber);
      this.pageIndex = pageNumber;
      this.getClassroomList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取教室列表
    getClassroomList() {
      this.$api
        .getClassroomList({
          page: this.pageIndex,
          limit: this.pageSize,
          search: this.value,
        })
        .then((res) => {
          // console.log(res);
          if (res.success) {
            if (!res.data.classroomList.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getClassroomList({
                  page: this.pageIndex,
                  limit: this.pageSize,
                  search: this.value,
                })
                .then((res) => {
                  this.totalCount = res.data.total;
                  this.classroomData = res.data.classroomList;
                });
            } else {
              this.totalCount = res.data.total;
              this.classroomData = res.data.classroomList;
            }
          }
        });
    },
    //获取教学楼
    getBuildList() {
      this.$api.getBuildList().then((res) => {
        // console.log(res);
        this.buildList = res.data.buildingList;
      });
    },
    //新增教室时选择楼栋改变
    selectFloor() {
      this.form.floorNum = undefined;
      this.$api.getBuildList().then((res) => {
        // console.log(res);
        if (res.success) {
          let obj = res.data.buildingList.find((r) => r.id === this.form.buildId);
          this.floorList = obj.totalFloor;
        }
      });
    },
    //编辑教室时选择楼栋改变
    selectFloorToo() {
      this.editForm.floorNum = undefined;
      this.$api.getBuildList().then((res) => {
        console.log(res);
        if (res.success) {
          let obj = res.data.buildingList.find((r) => r.id === this.editForm.buildId);
          this.floorListToo = obj.totalFloor;
          console.log(this.floorListToo);
        }
      });
    },
    //获取班级
    getClassList() {
      this.$api.getClassList().then((res) => {
        // console.log(res);
        this.classList = res.data.xnClassList;
      });
    },
  },
  created() {
    this.getClassroomList();
    this.getBuildList();
    this.getClassList();
  },
};
</script>

<style lang="scss" scoped src="./classroom.scss"></style>
