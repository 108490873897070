<template>
  <div>
    <!--面包屑-->
    <BreadRole></BreadRole>

    <div class="container-role">
      <div class="container-total">
        <!--角色总数-->
        <div class="title">
          <div class="title-text">角色管理（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="user-list">
          <!--搜索框和添加角色还有全删-->
          <div class="user-manage d-f a-c">
            <div style="text-align: right; flex: 1">
              <span class="add-user" v-if="roleAdd">
                <a-button type="primary" @click="showModal"
                  ><a-icon type="plus" /> 添加角色
                </a-button>
              </span>
            </div>
            <!--添加角色的弹出框-->
            <a-modal
              v-model="visible"
              title="添加角色"
              @ok="hideModal"
              @cancel="cancelModal"
            >
              <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="角色名称" prop="roleName">
                  <a-input v-model="form.roleName" placeholder="请输入角色名称" />
                </a-form-model-item>
                <a-form-model-item label="权限字符" prop="roleCode">
                  <a-input
                    v-model="form.roleCode"
                    placeholder="请输入权限字符"
                    type="number"
                  />
                </a-form-model-item>
                <a-form-model-item label="状态" prop="status">
                  <a-radio-group v-model="form.status">
                    <a-radio :value="true">通过</a-radio>
                    <a-radio :value="false">不通过</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="菜单权限" prop="perIdList">
                  <div class="tree">
                    <a-tree
                      v-model="form.perIdList"
                      checkable
                      :replace-fields="{
                        children: 'children',
                        key: 'id',
                        title: 'name',
                      }"
                      :expanded-keys="expandedKeys"
                      :selected-keys="selectedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @check="onCheck"
                      @select="onSelect"
                    >
                    </a-tree>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="重定向" prop="redirectPerId">
                  <a-select
                    v-model="form.redirectPerId"
                    placeholder="请选择重定向的菜单"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.id"
                      v-for="item in menuListToo"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="备注">
                  <a-input v-model="form.remark" type="textarea" autosize />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
          </div>
          <!--列表-->
          <div class="user-space-list">
            <!--表格-->
            <a-table
              v-if="roleList"
              :columns="columns"
              :data-source="roleData"
              :pagination="false"
              rowKey="id"
            >
              <!-- <span slot="status" slot-scope="text">
                <span :class="text === true ? 'ok' : 'no'">{{
                  text | handleReviewed
                }}</span>
              </span> -->
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)" v-if="roleUpdate">编辑</a>
                <a-divider type="vertical" v-if="roleUpdate && roleRemove" />
                <a @click="del(record)" v-if="roleRemove">删除</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改角色信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="角色名称" prop="roleName">
                  <a-input v-model="editForm.roleName" placeholder="请输入角色名称" />
                </a-form-model-item>
                <a-form-model-item label="权限字符" prop="roleCode">
                  <a-input
                    v-model="editForm.roleCode"
                    placeholder="请输入权限字符"
                    type="number"
                  />
                </a-form-model-item>
                <a-form-model-item label="状态" prop="status">
                  <a-radio-group v-model="editForm.status">
                    <a-radio :value="true">通过</a-radio>
                    <a-radio :value="false">不通过</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="菜单权限" prop="perIdList">
                  <div class="tree">
                    <a-tree
                      v-model="editForm.perIdList"
                      checkable
                      :replace-fields="{
                        children: 'children',
                        key: 'id',
                        title: 'name',
                      }"
                      :expanded-keys="expandedKeys"
                      :selected-keys="selectedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @check="onCheck"
                      @select="onSelect"
                    >
                    </a-tree>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="重定向" prop="redirectPerId">
                  <a-select
                    v-model="editForm.redirectPerId"
                    placeholder="请选择重定向的菜单"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.id"
                      v-for="item in menuListToo"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="备注">
                  <a-input v-model="editForm.remark" type="textarea" autosize />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                show-less-items
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadRole from "~c/bread/BreadRole.vue";
const columns = [
  {
    title: "角色名称",
    dataIndex: "roleName",
    width: "20%",
    align: "center",
  },
  {
    title: "权限字符",
    dataIndex: "roleCode",
    width: "10%",
    align: "center",
  },
  {
    title: "重定向",
    dataIndex: "redirectPerName",
    width: "15%",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "gmtCreate",
    width: "25%",
    align: "center",
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: "10%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    width: "20%",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadRole,
  },
  filters: {
    handleReviewed(val) {
      if (val === true) {
        return "通过";
      } else if (val === false) {
        return "不通过";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      labelCol1: { span: 5 },
      wrapperCol1: { span: 14 },
      visible: false,
      editVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        roleName: "",
        roleCode: "",
        status: Boolean,
        perIdList: [],
        redirectPerId: undefined,
        remark: "",
      },
      editForm: {
        id: "",
        roleName: "",
        roleCode: "",
        status: Boolean,
        perIdList: [],
        redirectPerId: undefined,
        remark: "",
      },
      rules: {
        roleName: [
          {
            required: true,
            message: "请输入角色名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        roleCode: [
          {
            required: true,
            message: "请输入角色名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        status: [
          {
            required: true,
            message: "请选择角色状态",
            trigger: ["change", "blur"],
          },
        ],
        perIdList: [
          {
            required: true,
            message: "请选择菜单权限",
            trigger: ["change", "blur"],
          },
        ],
        redirectPerId: [
          {
            required: true,
            message: "请选择重定向菜单",
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        roleName: [
          {
            required: true,
            message: "请输入角色名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        roleCode: [
          {
            required: true,
            message: "请输入角色名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        status: [
          {
            required: true,
            message: "请选择角色状态",
            trigger: ["change", "blur"],
          },
        ],
        redirectPerId: [
          {
            required: true,
            message: "请选择重定向菜单",
            trigger: ["change", "blur"],
          },
        ],
        perIdList: [
          {
            required: true,
            message: "请选择菜单权限",
            trigger: ["change", "blur"],
          },
        ],
      },
      roleData: [],
      columns,
      treeData: [],
      menuListToo: [],
      expandedKeys: ["1"],
      selectedKeys: [],
      perIdList: [],
      arrList: [],
      fartherList: [],
      treeChildren: [],
      record: {},
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
    };
  },
  methods: {
    //展开折叠触发事件
    onExpand(expandedKeys) {
      // console.log("onExpand", expandedKeys);
      this.expandedKeys = expandedKeys;
    },
    //选择树形控件复选框时触发
    onCheck(checkedKeys, e) {
      // console.log("onCheck", checkedKeys);
      // console.log(e.halfCheckedKeys);
      this.fartherList = e.halfCheckedKeys;
    },
    //选择树形控件name时触发
    onSelect(selectedKeys, info) {
      // console.log("onSelect", info);
      this.selectedKeys = selectedKeys;
    },
    //新增角色
    async showModal() {
      this.visible = true;
      this.menuListToo = [];
      this.fartherList = [];
      this.getMenuListToo();
      this.getMenuList();
    },
    //新增角色确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          Number(this.form.roleCode);
          // console.log(this.fartherList);
          this.form.perIdList = this.form.perIdList.concat(this.fartherList);
          // console.log(this.form);
          this.$api.addRole(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("新增角色成功");
              this.getRoleList();
              this.form = {
                roleName: "",
                roleCode: "",
                status: Boolean,
                perIdList: [],
                redirectPerId: undefined,
                remark: "",
              };
              this.menuListToo = [];
              this.fartherList = [];
            } else {
              this.$message.error(res.message);
            }
            this.visible = false;
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        roleName: "",
        roleCode: "",
        status: Boolean,
        perIdList: [],
        redirectPerId: undefined,
        remark: "",
      };
      this.menuListToo = [];
      this.fartherList = [];
    },
    //递归数组，得到所有菜单的子节点
    getTreeChildren(data) {
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          this.getTreeChildren(item.children);
        } else {
          this.treeChildren.push(item.id);
        }
        return null;
      });
      return this.treeChildren;
    },
    //编辑角色
    async edit(record) {
      // console.log(record);
      this.getMenuList();
      this.menuListToo = [];
      this.getMenuListToo();
      let containChildren = [];
      let res = await this.$api.getRoleInfo({ id: record.id });
      //得到当前角色拥有的权限值列表（包括子节点和父节点）
      containChildren = res.data.role.perIdList;
      //得到当前角色拥有的权限值列表（包括子节点和父节点）
      this.perIdList = res.data.role.perIdList;
      let res1 = await this.$api.getMenuList();
      //得到所有菜单最下层子节点
      this.treeChildren = [];
      this.getTreeChildren(res1.data.permissions);
      //得到当前角色拥有的权限值列表（只包括子节点）
      let arr = [];
      containChildren.forEach((r) => {
        this.treeChildren.forEach((rr) => {
          if (rr === r) {
            arr.push(r);
          }
        });
      });

      this.editForm.perIdList = arr;
      this.editForm.roleName = record.roleName;
      this.editForm.roleCode = record.roleCode + "";
      this.editForm.status = record.status;
      this.editForm.remark = record.remark;
      this.editForm.id = record.id;
      this.editForm.redirectPerId = record.redirectPerId;
      this.record = record;
      this.editVisible = true;
    },
    //删除用户
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          // console.log(record);
          this.$api.removeRole({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getRoleList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          Number(this.editForm.roleCode);
          // console.log(this.fartherList);
          if (this.fartherList.length === 0 && this.editForm.perIdList.length > 0) {
            this.editForm.perIdList = this.perIdList;
          } else {
            this.editForm.perIdList = this.editForm.perIdList.concat(this.fartherList);
          }
          // console.log(this.editForm);
          this.$api.upDataRole(this.editForm).then((res) => {
            if (res.success) {
              this.$message.success("编辑成功");
              this.menuListToo = [];
              this.fartherList = [];
              this.getRoleList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        id: "",
        roleName: "",
        roleCode: "",
        status: Boolean,
        perIdList: [],
        redirectPerId: undefined,
        remark: "",
      };
      this.menuListToo = [];
      this.fartherList = [];
    },
    //分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getRoleList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取所有用户角色
    getRoleList() {
      this.$api
        .getRoleList({
          page: this.pageIndex,
          limit: this.pageSize,
        })
        .then((res) => {
          // console.log(res);
          if (!res.data.items.length && this.pageIndex > 1) {
            this.pageIndex--;
            this.$api
              .getRoleList({
                page: this.pageIndex,
                limit: this.pageSize,
              })
              .then((res) => {
                this.roleData = res.data.items;
                this.totalCount = res.data.total;
              });
          } else {
            this.roleData = res.data.items;
            this.totalCount = res.data.total;
          }
        });
    },
    //获取所有菜单
    getMenuList() {
      this.$api.getMenuList().then((res) => {
        this.treeData = res.data.permissions;
      });
    },
    //获取所有二级菜单
    getMenuListToo() {
      this.$api.getMenuList().then((res) => {
        res.data.permissions[0].children.forEach((r) => {
          if (r.children && r.children.length > 0) {
            r.children.forEach((rr) => {
              this.menuListToo.push(rr);
            });
          }
        });
      });
    },
  },
  created() {
    this.getRoleList();
  },
  computed: {
    //角色添加按钮的显示隐藏
    roleAdd() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("role.add")) {
        return true;
      } else {
        return false;
      }
    },
    //角色删除按钮的显示隐藏
    roleRemove() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("role.remove")) {
        return true;
      } else {
        return false;
      }
    },
    //角色编辑按钮的显示隐藏
    roleUpdate() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("role.update")) {
        return true;
      } else {
        return false;
      }
    },
    //角色列表的显示隐藏
    roleList() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("role.list")) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped src="./role.scss"></style>
