<!--
 * @Author: 庄志莹
 * @Date: 2020-12-08 16:57:50
 * @LastEditTime: 2021-05-19 15:00:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\record\analysis\analysis.vue
-->
<template>
  <div class="container-analysis">
    <!--第一行-->
    <analysisFirstLine :obj="obj"></analysisFirstLine>
    <!--第二行-->
    <analysisSecondLine></analysisSecondLine>
    <!--第三行-->
    <analysisThirdLine ref="scoreList"></analysisThirdLine>
  </div>
</template>

<script>
import analysisFirstLine from "~c/analysis/analysisFirstLine.vue";
import analysisSecondLine from "~c/analysis/analysisSecondLine.vue";
import analysisThirdLine from "~c/analysis/analysisThirdLine.vue";
export default {
  components: {
    analysisFirstLine,
    analysisSecondLine,
    analysisThirdLine,
  },
  data() {
    return {
      websocket: null,
      timer: null,
      obj: {},
      timer1: null,
    };
  },
  watch: {
    obj(val, oldVal) {
      this.obj = val;
    },
  },
  methods: {
    //webscoket 初始化
    initWebSocket() {
      const wsurl = this.$globalVariable.wsurl; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      //连接建立之后执行send方法发送数据
      this.websocketsendFirst();
      this.websocketsendSecond();
      this.websocketsendThird();
      this.$refs.scoreList.getScoreList();
      this.timer = setInterval(() => {
        this.doSend();
      }, 10 * 1000);
      this.timer1 = setInterval(() => {
        this.doSend1();
      }, 30 * 60 * 1000);
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend() {
      console.log("发送心跳");
      this.websocketsendSecond();
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend1() {
      console.log("发送心跳");
      this.websocketsendThird();
    },
    //首次发送数据 建立连接
    websocketsendFirst() {
      console.log("首次向后端发送数据");
      let userInfo = this.$ls.get("userInfo");
      let obj1 = {
        action: 1,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          receiverId: "",
          msg: "建立连接",
          msgType: 0,
        },
      };
      this.websocket.send(JSON.stringify(obj1)); //发送数据，传什么数据由实际需求决定
    },
    //获取数据分析数据信息
    websocketsendSecond() {
      console.log("获取数据分析数据信息");
      let userInfo = this.$ls.get("userInfo");
      let obj2 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "获取数据分析数据信息",
          msgType: 15,
        },
      };
      this.websocket.send(JSON.stringify(obj2));
    },
    //获取数据分析健康评分
    websocketsendThird() {
      console.log("获取数据分析健康评分");
      let userInfo = this.$ls.get("userInfo");
      let obj3 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "获取数据分析健康评分",
          msgType: 17,
          receiverId: "",
          data: {},
        },
      };
      this.websocket.send(JSON.stringify(obj3));
    },
    //数据接收
    websocketonmessage(e) {
      console.log("接收后端返回数据");
      var result = JSON.parse(e.data);
      console.log(result);
      if (result.data) {
        this.obj = result.data;
      }
    },
    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
      clearInterval(this.timer1);
    },
  },
  //vue生命周期创建后
  created() {
    this.initWebSocket();
  },
  destroyed() {
    this.websocketclose();
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scoped>
.container-analysis {
  width: 100%;
  padding: 24px;
}
</style>
