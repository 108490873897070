<template>
  <a-form id="components-form-demo-normal-login" :form="form" class="login-form">
    <a-form-item>
      <a-input
        v-decorator="[
          'mobile',
          {
            rules: [
              {
                required: true,
                message: '请输入你的手机号!',
              },
              {
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: '不是正确的手机格式!',
              },
            ],
          },
        ]"
        placeholder="手机号"
        type="phone"
      >
        <a-icon slot="prefix" type="mobile" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
        v-decorator="[
          'code',
          {
            rules: [
              {
                required: true,
                message: '请输入验证码!',
              },
              {
                pattern: /^\d{4}$/,
                message: '验证码格式不正确!',
              },
            ],
          },
        ]"
        class="CaptchaIpnt"
        type="code"
        placeholder="验证码"
      >
        <a-icon slot="prefix" type="code" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
      <!-- 发送验证吗按钮 -->
      <div class="authCodeBtn-login">
        <a-button @click="sendSmsCode" v-if="isSend">获取验证码</a-button>
        <a-button v-if="!isSend" disabled>{{ count }}s后可再次发送</a-button>
      </div>
    </a-form-item>
    <a-form-item>
      <a class="login-form-forgot" @click="goForget()"> 忘记密码 </a>
      <a-button type="primary" @click="handleLogin()" class="login-form-button">
        登录
      </a-button>
    </a-form-item>
    <div class="loginItems">
      <span class="resLogin">其他方式登录</span>
      <img @click="goscanCode()" class="WxLogo" src="@/assets/img/WxLogo.png" alt="" />
      <a @click="goRegister()" class="signIn">注册账户</a>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      count: 60,
      isSend: true,
      timer: null,
      referrer: "",
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    //发送验证码
    sendSmsCode() {
      this.form.validateFields(["mobile"], (err, values) => {
        if (!err) {
          console.log(values.mobile);
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //这里可以插入$axios调用后台接口
            //验证手机号是否注册
            this.$api.isExist({ phone: values.mobile }).then((res) => {
              if (res.success) {
                // console.log(values); //表单的值
                const params = {};
                params.phone = values.mobile;
                params.smsCodeType = 3;
                // console.log(params);
                //发送短信验证码
                this.$api.sendSmsCode(params).then((res) => {
                  this.$message.info(res.message);
                });
              } else {
                this.$message.error(res.message);
              }
            });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //手机号 登录
    handleLogin() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values);
          const params = {};
          params.phone = values.mobile;
          params.smsCode = values.code;
          this.$api.TextLogin(params).then((res) => {
            if (res.success) {
              this.$ls.set("token", res.data.token);
              this.setCookie("token", res.data.token, 1);
              this.$api.getUserInfo().then((result) => {
                if (result.success) {
                  this.$ls.set("userInfo", result.data);
                  //路由跳转
                  this.$message.success("登录成功");
                  if (this.referrer.includes("https://resource.jsxniu.com")) {
                    window.open("https://resource.jsxniu.com", "_self");
                  } else {
                    this.$router.push("/main");
                  }
                }
              });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },

    //跳转忘记密码
    goForget() {
      this.$router.push({ name: "forget" });
    },
    //跳转注册
    goRegister() {
      this.$router.push({ name: "register" });
    },
    //扫码登录
    goscanCode() {
      this.$router.push({ name: "scan" });
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString()) +
        ";path=/;domain=jsxniu.com";
    },
  },
  created() {
    let referrer = document.referrer;
    this.referrer = referrer;
  },
  destroyed() {
    clearInterval(this.timer);
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>
<style lang="scss" scope>
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.CaptchaIpnt {
  width: 200px !important;
}
.authCodeBtn-login {
  position: absolute;
  top: -8px;
  right: -150px;
}
.login-form-forgot {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #009fe8;
  opacity: 1;
  text-decoration: none !important;
}
.login-form-button {
  width: 348px;
  height: 36px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 20px;
  background: #009fe8;
  border-color: #009fe8;
  border-radius: 18px;
}
</style>
