<template>
  <div>
    <!--面包屑-->
    <BreadMenu></BreadMenu>

    <div class="container-menu">
      <div class="container-total">
        <!--菜单总数-->
        <div class="title">
          <div class="title-text">菜单管理</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="user-list">
          <!--搜索框和添加菜单还有全删-->
          <div class="user-manage d-f j-b a-c">
            <div class="search d-f a-c">
              <a-form-model
                :model="statusForm"
                :label-col="labelCol1"
                :wrapper-col="wrapperCol1"
                class="modal"
              >
                <a-form-model-item label="菜单状态">
                  <a-select
                    v-model="statusForm.region"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option :value="1">正常</a-select-option>
                    <a-select-option :value="0">停用</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
              <a-input-search
                placeholder="请输入菜单名称"
                style="width: 188px; margin-left: 40px"
                @search="onSearch"
                v-model="value"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <span class="add-user" v-if="menuAdd"
                ><a-button type="primary" @click="showModal">
                  <a-icon type="plus" />添加菜单
                </a-button></span
              >
              <!--添加用户的弹出框-->
              <a-modal
                v-model="visible"
                title="添加菜单"
                @ok="hideModal"
                @cancel="cancelModal"
              >
                <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item label="菜单类型" prop="type">
                    <a-radio-group v-model="form.type">
                      <a-radio value="1"> 目录 </a-radio>
                      <a-radio value="2"> 菜单 </a-radio>
                      <a-radio value="3"> 按钮 </a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                  <!--tab切换-->
                  <div v-if="form.type === '1'">
                    <a-form-model-item label="上级菜单" prop="pid">
                      <a-select
                        v-model="form.pid"
                        placeholder="请选择上级菜单"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      >
                        <a-select-option value="1"> 主目录 </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="图标">
                      <a-input v-model="form.icon" placeholder="请输入图标字符串" />
                    </a-form-model-item>
                    <a-form-model-item label="菜单名称" prop="name">
                      <a-input v-model="form.name" placeholder="请输入菜单名称" />
                    </a-form-model-item>
                    <a-form-model-item label="显示排序" prop="sortNum">
                      <a-input
                        v-model="form.sortNum"
                        placeholder="请输入显示排序"
                        type="number"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="组件路径" prop="path">
                      <a-input v-model="form.path" placeholder="请输入组件路径" />
                    </a-form-model-item>
                    <a-form-model-item label="组件名称" prop="component">
                      <a-input v-model="form.component" placeholder="请输入菜单名称" />
                    </a-form-model-item>
                    <a-form-model-item label="菜单状态" prop="status">
                      <a-radio-group v-model="form.status">
                        <a-radio value="0"> 停用 </a-radio>
                        <a-radio value="1"> 正常 </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                  </div>
                  <div v-else-if="form.type === '2'">
                    <a-form-model-item label="上级菜单" prop="pid">
                      <a-select
                        v-model="form.pid"
                        placeholder="请选择上级菜单"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      >
                        <a-select-option
                          :value="item.id"
                          v-for="item in menuData"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="菜单名称" prop="name">
                      <a-input v-model="form.name" placeholder="请输入菜单名称" />
                    </a-form-model-item>
                    <a-form-model-item label="显示排序" prop="sortNum">
                      <a-input
                        v-model="form.sortNum"
                        placeholder="请输入显示排序"
                        type="number"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="组件路径" prop="path">
                      <a-input v-model="form.path" placeholder="请输入组件路径" />
                    </a-form-model-item>
                    <a-form-model-item label="组件名称" prop="component">
                      <a-input v-model="form.component" placeholder="请输入菜单名称" />
                    </a-form-model-item>
                    <a-form-model-item label="菜单状态" prop="status">
                      <a-radio-group v-model="form.status">
                        <a-radio value="0"> 停用 </a-radio>
                        <a-radio value="1"> 正常 </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                  </div>
                  <div v-else>
                    <a-form-model-item label="上级菜单" prop="pid">
                      <a-select
                        v-model="form.pid"
                        placeholder="请选择上级菜单"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      >
                        <a-select-option
                          :value="item.id"
                          v-for="item in secMenuData"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="按钮名称" prop="name">
                      <a-input v-model="form.name" placeholder="请输入按钮名称" />
                    </a-form-model-item>
                    <a-form-model-item label="接口路径" prop="path">
                      <a-input v-model="form.path" placeholder="请输入接口路径" />
                    </a-form-model-item>
                    <a-form-model-item label="接口名称" prop="component">
                      <a-input v-model="form.component" placeholder="请输入接口名称" />
                    </a-form-model-item>
                    <a-form-model-item label="权限值" prop="permissionValue">
                      <a-input
                        v-model="form.permissionValue"
                        placeholder="请输入权限值"
                      />
                    </a-form-model-item>
                    <a-form-model-item label="菜单状态">
                      <a-radio-group v-model="form.status">
                        <a-radio value="0"> 停用 </a-radio>
                        <a-radio value="1"> 正常 </a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                  </div>
                </a-form-model>
              </a-modal>
            </div>
          </div>
          <!--列表-->
          <div class="user-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              :data-source="menuData"
              :pagination="false"
              :scroll="{ x: 1300 }"
              rowKey="id"
              v-if="menuList"
            >
              <span slot="icon" slot-scope="text, record">
                <a-icon :type="record.icon" />
              </span>
              <span slot="type" slot-scope="text">
                <span>{{ text | handleType }}</span>
              </span>
              <span slot="status" slot-scope="text">
                <span :class="text === 1 ? 'ok' : 'no'">{{ text | handleStatus }}</span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)" v-if="menuUpdate">编辑</a>
                <a-divider type="vertical" v-if="menuUpdate && menuRemove" />
                <a @click="del(record)" v-if="menuRemove">删除</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改菜单信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="菜单名称" prop="name">
                  <a-input v-model="editForm.name" placeholder="请输入菜单名称" />
                </a-form-model-item>
                <a-form-model-item label="图标" v-if="record.type === 1">
                  <a-input v-model="editForm.icon" placeholder="请输入图标字符串" />
                </a-form-model-item>
                <a-form-model-item
                  label="显示排序"
                  prop="sortNum"
                  v-if="record.type === 1 || record.type === 2"
                >
                  <a-input
                    v-model="editForm.sortNum"
                    placeholder="请输入显示排序"
                    type="number"
                  />
                </a-form-model-item>
                <a-form-model-item label="权限值" v-if="record.type === 3">
                  <a-input
                    v-model="editForm.permissionValue"
                    placeholder="请输入权限值"
                  />
                </a-form-model-item>
                <a-form-model-item
                  label="组件路径"
                  prop="path"
                  v-if="record.type === 1 || record.type === 2"
                >
                  <a-input v-model="editForm.path" placeholder="请输入组件路径" />
                </a-form-model-item>
                <a-form-model-item
                  label="组件名称"
                  prop="component"
                  v-if="record.type === 1 || record.type === 2"
                >
                  <a-input v-model="editForm.component" placeholder="请输入组件名称" />
                </a-form-model-item>
                <a-form-model-item label="接口路径" prop="path" v-if="record.type === 3">
                  <a-input v-model="editForm.path" placeholder="请输入接口路径" />
                </a-form-model-item>
                <a-form-model-item
                  label="接口名称"
                  prop="component"
                  v-if="record.type === 3"
                >
                  <a-input v-model="editForm.component" placeholder="请输入接口名称" />
                </a-form-model-item>
                <a-form-model-item label="状态">
                  <a-radio-group v-model="editForm.status">
                    <a-radio value="0">停用</a-radio>
                    <a-radio value="1">正常</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadMenu from "~c/bread/BreadMenu.vue";
const columns = [
  {
    title: "菜单名称",
    dataIndex: "name",
    width: 300,
    align: "left",
  },
  {
    title: "图标",
    dataIndex: "icon",
    width: 100,
    align: "left",
    scopedSlots: { customRender: "icon" },
  },
  {
    title: "类型",
    dataIndex: "type",
    width: 150,
    align: "left",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "排序",
    dataIndex: "sortNum",
    width: 100,
    align: "left",
  },
  {
    title: "权限值",
    dataIndex: "permissionValue",
    width: 200,
    align: "center",
  },
  {
    title: "组件路径",
    dataIndex: "path",
    width: 300,
    align: "left",
  },
  {
    title: "组件名称",
    dataIndex: "component",
    width: 300,
    align: "left",
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 100,
    align: "left",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "创建时间",
    dataIndex: "gmtCreate",
    width: 200,
    align: "left",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "left",
    fixed: "right",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadMenu,
  },
  filters: {
    handleStatus(val) {
      return ["停用", "正常"][val];
    },
    handleType(val) {
      return ["目录", "菜单", "按钮"][val - 1];
    },
  },
  data() {
    return {
      value: "",
      labelCol1: { span: 10 },
      wrapperCol1: { span: 14 },
      statusForm: {
        region: 1,
      },
      visible: false,
      editVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        pid: undefined,
        sortNum: null,
        type: "",
        icon: "",
        name: "",
        path: "",
        permissionValue: "",
        status: "",
        component: "",
      },
      editForm: {
        sortNum: null,
        icon: "",
        name: "",
        path: "",
        permissionValue: "",
        status: "",
        component: "",
      },
      rules: {
        pid: [
          {
            required: true,
            message: "请输入上级菜单",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        type: [
          {
            required: true,
            message: "请选择菜单类型",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入菜单或按钮名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        sortNum: [
          {
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        path: [
          {
            required: true,
            message: "请输入组件或接口路径",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        permissionValue: [
          {
            required: true,
            message: "请输入权限值",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        component: [
          {
            required: true,
            message: "请输入组件或接口名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        name: [
          {
            required: true,
            message: "请输入菜单或按钮名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        sortNum: [
          {
            required: true,
            message: "请输入显示排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        path: [
          {
            required: true,
            message: "请输入组件或接口路径",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        permissionValue: [
          {
            required: true,
            message: "请输入权限值",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        component: [
          {
            required: true,
            message: "请输入组件或接口名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      menuData: [],
      secMenuData: [],
      columns,
      record: {},
    };
  },
  methods: {
    //搜索框
    onSearch() {
      this.$api
        .getMenuList({
          status: this.statusForm.region,
          search: this.value,
        })
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.menuData = res.data.permissions[0].children;
          }
        });
    },
    //新增菜单
    showModal() {
      this.visible = true;
    },
    //新增菜单确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.$api.menuAdd(this.form).then((res) => {
            if (res.success) {
              this.$message.success("新增成功");
              this.getMenuList();
              this.form = {
                pid: undefined,
                sortNum: null,
                type: "",
                icon: "",
                name: "",
                path: "",
                permissionValue: "",
                status: "",
                component: "",
              };
            } else {
              this.$message.error(res.message);
            }
          });
          this.visible = false;
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        pid: undefined,
        sortNum: null,
        type: "",
        icon: "",
        name: "",
        path: "",
        permissionValue: "",
        status: "",
        component: "",
      };
    },
    //编辑菜单
    edit(record) {
      // console.log(record);
      this.editForm.name = record.name;
      this.editForm.icon = record.icon;
      this.editForm.sortNum = record.sortNum + "";
      this.editForm.permissionValue = record.permissionValue;
      this.editForm.path = record.path;
      this.editForm.component = record.component;
      if (record.status === 1) {
        this.editForm.status = "1";
      } else {
        this.editForm.status = "0";
      }
      this.record = record;
      this.editVisible = true;
    },
    //删除菜单
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.menuDel({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getMenuList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          this.record.name = this.editForm.name;
          this.record.icon = this.editForm.icon;
          this.record.sortNum = this.editForm.sortNum;
          this.record.permissionValue = this.editForm.permissionValue;
          this.record.path = this.editForm.path;
          this.record.component = this.editForm.component;
          this.record.status = this.editForm.status;

          this.$api.menuUpdate(this.record).then((res) => {
            if (res.success) {
              this.$message.success("编辑成功");
              this.getMenuList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        sortNum: null,
        icon: "",
        name: "",
        path: "",
        permissionValue: "",
        status: "",
        component: "",
      };
    },
    //获取菜单列表
    getMenuList() {
      this.$api.getMenuList().then((res) => {
        // console.log(res);
        if (res.success) {
          this.menuData = res.data.permissions[0].children;
          let arr = [];
          this.menuData.map((r) => {
            r.children.forEach((rr) => {
              arr.push(rr);
              this.secMenuData = arr;
            });
          });
        }
      });
    },
  },
  created() {
    this.getMenuList();
  },
  computed: {
    //菜单添加按钮的显示隐藏
    menuAdd() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("permission.save")) {
        return true;
      } else {
        return false;
      }
    },
    //菜单删除按钮的显示隐藏
    menuRemove() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("permission.remove")) {
        return true;
      } else {
        return false;
      }
    },
    //菜单编辑按钮的显示隐藏
    menuUpdate() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("permission.update")) {
        return true;
      } else {
        return false;
      }
    },
    //菜单列表的显示隐藏
    menuList() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("permission.list")) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // console.log(this.info);
  },
};
</script>

<style lang="scss" scoped src="./menus.scss"></style>
