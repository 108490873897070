<!--
 * @Author: 庄志莹
 * @Date: 2020-12-09 10:20:35
 * @LastEditTime: 2021-03-16 17:58:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\components\analysis\analysisFirstLine.vue
-->
<template>
  <div>
    <ul class="d-f j-b a-c first-ul">
      <li class="d-f f-d a-c j-c">
        <div class="detail-title">设备总数（台）</div>
        <div class="num">{{ obj.totalDeviceCount }}</div>
      </li>
      <li class="d-f f-d a-c j-c">
        <div class="detail-title">当前设备在线台数（台）</div>
        <div class="num">{{ obj.onlineCount }}</div>
      </li>
      <li class="d-f f-d a-c j-c">
        <div class="detail-title">服务师生总数（人）</div>
        <div class="num">{{ obj.totalUser }}</div>
      </li>
      <li class="d-f f-d a-c j-c">
        <div class="detail-title">服务学校总数（个）</div>
        <div class="num">{{ obj.allSchoolCount }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    obj: Object,
  },
  watch: {
    obj(val, oldVal) {
      let isEqual = JSON.stringify(val) == JSON.stringify(oldVal);
      if (!isEqual) {
        this.obj = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first-ul {
  width: 100%;
  margin-bottom: 0;
}
li {
  width: 23.7%;
  height: 100%;
  min-width: 278px;
  min-height: 101px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  background-color: #ffffff;
  border-radius: 6px;
}
.detail-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}
.num {
  font-size: 24px;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 32px;
  color: #00afff;
  margin-top: 10px;
}
</style>
