<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangqinggang
 * @Date: 2020-11-25 13:42:16
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-12 20:14:29
-->
<template>
  <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules">
    <a-form-model-item has-feedback prop="pass">
      <a-input
        placeholder="请输入密码"
        v-model="ruleForm.pass"
        type="password"
        autocomplete="off"
      >
        <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item has-feedback prop="checkPass">
      <a-input
        placeholder="请再次输入密码"
        v-model="ruleForm.checkPass"
        type="password"
        autocomplete="off"
      >
        <a-icon
          slot="prefix"
          type="safety-certificate"
          style="color: rgba(0, 0, 0, 0.25)"
        />
      </a-input>
    </a-form-model-item>

    <a-form-model-item>
      <a-button class="complete" round type="primary" @click="submitForm('ruleForm')">
        完成
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import MD5 from "~l/md5.js";
export default {
  data() {
    let checkPending;
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入你的密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认你的密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次密码不一样"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: "change" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含数字和英文，长度6-20",
          },
          { required: true, message: "请输入你的密码!" },
        ],
        checkPass: [
          { validator: validatePass2, trigger: "change" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含数字和英文，长度6-20",
          },
          { required: true, message: "请确认你的密码!" },
        ],
      },
    };
  },
  methods: {
    //完成密码重置
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            phone: this.$store.state.phone,
            // newPassword:MD5.md5(this.ruleForm.pass),
            // confirmNewPassword:MD5.md5(this.ruleForm.checkPass),
            newPassword: this.ruleForm.pass,
            confirmNewPassword: this.ruleForm.checkPass,
          };
          // console.log(params)
          this.$api.updatePasswordByPhone(params).then((res) => {
            if (res.success) {
              //路由跳转
              this.$message.success("修改成功，请重新登录");
              this.$router.push("login");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.complete {
  width: 348px;
  height: 36px;
  background: #009fe8;
  border-color: #009fe8;
  opacity: 1;
  border-radius: 18px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 20px;
}
</style>
