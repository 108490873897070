<template>
  <div>
    <!--面包屑-->
    <BreadStudent></BreadStudent>

    <div class="container-student">
      <div class="container-total">
        <!--学生总数-->
        <div class="title">
          <div class="title-text">学生（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="student-list">
          <!--搜索框和添加学生还有全删-->
          <div class="student-manage d-f j-b a-c">
            <div class="d-f a-c">
              <a-form-model layout="inline" :model="queryForm">
                <a-form-model-item>
                  <a-input-search
                    placeholder="请输入学生姓名或学号"
                    style="width: 188px"
                    @search="onSearch"
                    v-model="queryForm.searchValue"
                  />
                </a-form-model-item>
                <a-form-model-item>
                  <a-select
                    v-model="queryForm.classId"
                    style="width: 148px"
                    placeholder="请选择班级名称"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    allowClear
                  >
                    <a-select-option
                      :value="item.id"
                      v-for="item in classList"
                      :key="item.id"
                      >{{ item.xnClassname }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-form-model>
              <a-button type="primary" @click="onSearch" style="margin-left: 20px"
                >查询</a-button
              >
            </div>
            <div>
              <a-button type="primary" @click="add" v-if="studentAdd"
                ><a-icon type="plus" />新增学生</a-button
              >
              <a-button class="copy-all" v-if="studentDownload" @click="downloadTemplate"
                ><a-icon type="download" />下载模板</a-button
              >
              <a-button class="copy-all" v-if="studentAdd" @click="addAll"
                ><a-icon type="carry-out" />批量导入</a-button
              >
              <a-button @click="delAll" class="del-all" v-if="studentRemove"
                >批量删除</a-button
              >
            </div>
          </div>
          <!--列表-->
          <div class="student-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="studentData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="name" slot-scope="text, record">
                <img
                  :src="record.avatar"
                  v-if="record.avatar != null"
                  width="30px"
                  height="30px"
                  style="vertical-align: -8px; margin-right: 8px; border-radius: 50%"
                />
                <img
                  :src="require('@/assets/img/' + handleAvatar(record))"
                  v-else
                  width="30px"
                  height="30px"
                  style="vertical-align: -8px; margin-right: 8px"
                />{{ text }}
              </span>
              <span slot="gender" slot-scope="text">
                {{ text | handleGender }}
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)" v-if="studentUpdate">编辑</a>
                <a-divider type="vertical" v-if="studentUpdate && studentRemove" />
                <a @click="del(record)" v-if="studentRemove">删除</a>
              </span>
            </a-table>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="queryForm.pageNum"
                :pageSize="queryForm.pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>

        <!--下载模板的弹出框-->
        <a-modal v-model="downloadVisible" title="下载模板" :footer="null">
          <div class="d-f f-d j-c a-c excel">
            <p>为方便您快速添加学生，推荐您下载信息模板，</p>
            <p>请按照模板信息录入学生资料，上传导入</p>
            <table>
              <tr>
                <td>学生姓名</td>
                <td>学号</td>
                <td>性别</td>
              </tr>
              <tr>
                <td>王同学</td>
                <td>20211215</td>
                <td>女</td>
              </tr>
            </table>
            <p>（信息示范）</p>
            <div class="excel-line"></div>
            <div class="excel-space d-f">
              <div class="my-button">
                <a-button type="primary"
                  ><a @click="downloadExecl">下载Excel模板</a></a-button
                >
              </div>
            </div>
          </div>
        </a-modal>
        <!--批量导入的弹出框-->
        <a-modal
          v-model="excelVisible"
          title="批量导入"
          @ok="addAllStudents"
          :confirm-loading="confirmLoading"
        >
          <a-form-model
            ref="form"
            :model="addForm"
            :rules="addRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="student-model"
          >
            <a-form-model-item label="所属班级" prop="classId">
              <a-select
                v-model="addForm.classId"
                placeholder="请选择所属班级"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in classList"
                  :key="item.id"
                  >{{ item.xnClassname }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="选择文件" prop="file" ref="file">
              <a-upload-dragger
                :file-list="fileList"
                :remove="handleRemove"
                @change="handleChange"
                :before-upload="beforeUploadFile"
                accept=".xlsx,.xls"
                :multiple="false"
              >
                <p class="ant-upload-drag-icon"><a-icon type="inbox" /></p>
                <p class="ant-upload-text">点击或将文件拖到这里上传</p>
                <p class="ant-upload-hint">支持扩展名： .xlsx .xls</p>
              </a-upload-dragger>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
        <!--新增或编辑学生的弹出框-->
        <a-modal v-model="visible" :title="title" @ok="submit" width="640px">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="student-model"
          >
            <a-form-model-item label="学号" prop="studentNum">
              <a-input v-model="form.studentNum" placeholder="请输入学号" />
            </a-form-model-item>
            <a-form-model-item label="头像">
              <a-row>
                <a-col :span="12" :style="{ height: '180px' }">
                  <vue-cropper
                    ref="cropper"
                    :img="options.img"
                    :info="true"
                    :autoCrop="options.autoCrop"
                    :autoCropWidth="options.autoCropWidth"
                    :autoCropHeight="options.autoCropHeight"
                    :fixedBox="options.fixedBox"
                    @realTime="realTime"
                  />
                </a-col>
                <a-col :span="12" :style="{ height: '180px' }">
                  <div class="avatar-upload-preview" v-if="canShow">
                    <img :src="previews.url" :style="previews.img" />
                  </div>
                  <div class="avatar-upload-preview" v-else>
                    <img :src="form.avatar" class="avatar" />
                  </div>
                </a-col>
              </a-row>
              <br />
              <a-row>
                <a-col :lg="2" :md="2">
                  <a-upload
                    action="#"
                    :http-request="requestUpload"
                    :showUploadList="false"
                    :before-upload="beforeUpload"
                    accept="image/*"
                  >
                    <a-button>选择<a-icon type="upload" /></a-button>
                  </a-upload>
                </a-col>
                <a-col :lg="{ span: 1, offset: 7 }" :md="2">
                  <a-button icon="plus" @click="changeScale(1)"></a-button>
                </a-col>
                <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                  <a-button icon="minus" @click="changeScale(-1)"></a-button>
                </a-col>
                <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                  <a-button icon="undo" @click="rotateLeft()"></a-button>
                </a-col>
                <a-col :lg="{ span: 1, offset: 3 }" :md="2">
                  <a-button icon="redo" @click="rotateRight()"></a-button>
                </a-col>
              </a-row>
            </a-form-model-item>
            <a-form-model-item label="学生姓名" prop="name">
              <a-input v-model="form.name" placeholder="请输入学生姓名" />
            </a-form-model-item>
            <a-form-model-item label="性别" prop="gender">
              <a-select
                v-model="form.gender"
                placeholder="请选择性别"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option :value="0"> 女 </a-select-option>
                <a-select-option :value="1"> 男 </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="年龄">
              <a-input-number
                v-model="form.age"
                placeholder="请输入学生年龄"
                :min="1"
                style="width: 100%"
              />
            </a-form-model-item>
            <a-form-model-item label="所属班级" prop="classId">
              <a-select
                v-model="form.classId"
                placeholder="请选择所属班级"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in classList"
                  :key="item.id"
                  >{{ item.xnClassname }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import BreadStudent from "~c/bread/BreadStudent.vue";
import { VueCropper } from "vue-cropper";
import Client from "@/oss/client";
const columns = [
  {
    title: "学生姓名",
    dataIndex: "name",
    width: "15%",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "学号",
    dataIndex: "studentNum",
    width: "20%",
    align: "center",
  },
  {
    title: "性别",
    dataIndex: "gender",
    width: "10%",
    align: "center",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "所属班级",
    dataIndex: "xnClassName",
    width: "20%",
    align: "center",
  },
  {
    title: "年龄",
    dataIndex: "age",
    width: "10%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadStudent,
    VueCropper,
  },
  filters: {
    handleGender(val) {
      return ["女", "男"][val];
    },
  },
  data() {
    return {
      //查询参数
      queryForm: {
        pageNum: 1,
        pageSize: 6,
        searchValue: null,
        classId: undefined,
      },
      //新增or编辑弹出框显示隐藏
      visible: false,
      //新增or编辑弹出框标题
      title: "",
      //下载模板的显示隐藏
      downloadVisible: false,
      //批量导入的显示隐藏
      excelVisible: false,
      //新增和编辑表单的文字距离
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        studentNum: null,
        avatar: "",
        name: null,
        gender: undefined,
        age: null,
        classId: undefined,
      },
      rules: {
        studentNum: [
          {
            required: true,
            message: "请输入学号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "学号不能超过20个字符",
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入学生姓名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        gender: [
          {
            required: true,
            type: "number",
            message: "请选择性别",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classId: [
          {
            required: true,
            message: "请选择所属班级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      addForm: {
        classId: undefined,
        file: null,
      },
      addRules: {
        classId: [
          {
            required: true,
            message: "请选择所属班级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        file: [{ required: true, message: "请选择文件", trigger: "change" }],
      },
      //批量导入的loading效果
      confirmLoading: false,
      //文件列表
      fileList: [],
      columns,
      //表格数据
      studentData: [],
      //班级列表
      classList: [],
      //多选框的值
      selectedRowKeys: [],
      //学生总数
      totalCount: null,
      //个人信息
      userInfo: null,
      //头像裁剪
      options: {
        img: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 120, // 默认生成截图框宽度
        autoCropHeight: 120, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      },
      previews: {},
      dataObj: null,
      //头像图片文件
      file: null,
      canShow: true,
    };
  },
  methods: {
    //处理表格随机头像
    handleAvatar(record) {
      //女生
      if (record.gender === 0) {
        let random = Math.round(Math.random());
        return ["student_random_avatar1.png", "student_random_avatar2.png"][random];
      }
      //男生
      else if (record.gender === 1) {
        let random = Math.round(Math.random());
        return ["student_random_avatar3.png", "student_random_avatar4.png"][random];
      }
    },
    //搜索查询
    onSearch() {
      this.getStudentList();
    },
    //新增学生
    add() {
      this.form = {};
      this.options = {
        img: "", //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 120, // 默认生成截图框宽度
        autoCropHeight: 120, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
      };
      this.canShow = true;
      this.title = "新增学生";
      this.visible = true;
    },
    //编辑学生
    edit(record) {
      this.visible = true;
      this.form = record;
      this.options.img = "";
      this.canShow = false;
      this.title = "编辑学生";
      this.visible = true;
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$message.error("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        //如果编辑的时侯上传头像
        if (this.canShow === false) {
          this.canShow = true;
        }
        this.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.img = reader.result;
        };
      }
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    //提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //新增
          if (this.form.id == null) {
            //没有上传学生头像
            if (this.options.img === "") {
              this.$api.studentAdd(this.form).then((res) => {
                // console.log(res);
                if (res.success) {
                  this.$message.success("新增成功");
                  this.getStudentList();
                  this.visible = false;
                } else {
                  this.$message.error(res.message);
                }
              });
            }
            //上传了学生头像
            else {
              this.$refs.cropper.getCropBlob(async (data) => {
                //修改命名
                const fileExtension = this.file.name.substring(
                  this.file.name.lastIndexOf(".") + 1
                );
                const random_name = `studentAvatar/${this.userInfo.schoolNum}/avatar_${this.form.studentNum}.${fileExtension}`;
                // blob转file
                let file = new window.File([data], random_name, {
                  type: data.type,
                });
                //获取Sts临牌
                let res = await this.$api.getStsInfo();
                if (res.success) {
                  const {
                    region,
                    Bucket,
                    AccessKeyId,
                    AccessKeySecret,
                    SecurityToken,
                  } = res.data.data;
                  this.dataObj = {
                    region: region,
                    bucket: Bucket,
                    accessKeyId: AccessKeyId,
                    accessKeySecret: AccessKeySecret,
                    stsToken: SecurityToken,
                  };
                  try {
                    const client = Client(this.dataObj);
                    let result = await client.put(random_name, file);
                    // console.log(result);
                    if (result.res.statusCode === 200) {
                      this.form.avatar = random_name;
                      this.$api.studentAdd(this.form).then((res) => {
                        // console.log(res);
                        if (res.success) {
                          this.$message.success("新增成功");
                          this.getStudentList();
                          this.visible = false;
                        } else {
                          this.$message.error(res.message);
                        }
                      });
                    }
                  } catch (error) {
                    this.$message.error("上传失败");
                  }
                }
              });
            }
          }
          //编辑
          else {
            //没有上传头像
            if (!this.canShow) {
              let form = JSON.parse(JSON.stringify(this.form));
              form.avatar = null;
              this.$api.studentUpdate(form).then((res) => {
                if (res.success) {
                  this.$message.success("编辑成功");
                  this.getStudentList();
                  this.visible = false;
                } else {
                  this.$message.error(res.message);
                }
              });
            }
            //上传了头像
            else {
              this.$refs.cropper.getCropBlob(async (data) => {
                //修改命名
                const fileExtension = this.file.name.substring(
                  this.file.name.lastIndexOf(".") + 1
                );
                const random_name = `studentAvatar/${this.userInfo.schoolNum}/avatar_${this.form.studentNum}.${fileExtension}`;
                // blob转file
                let file = new window.File([data], random_name, {
                  type: data.type,
                });
                //获取Sts临牌
                let res = await this.$api.getStsInfo();
                if (res.success) {
                  const {
                    region,
                    Bucket,
                    AccessKeyId,
                    AccessKeySecret,
                    SecurityToken,
                  } = res.data.data;
                  this.dataObj = {
                    region: region,
                    bucket: Bucket,
                    accessKeyId: AccessKeyId,
                    accessKeySecret: AccessKeySecret,
                    stsToken: SecurityToken,
                  };
                  try {
                    const client = Client(this.dataObj);
                    let result = await client.put(random_name, file);
                    // console.log(result);
                    if (result.res.statusCode === 200) {
                      this.form.avatar = random_name;
                      this.$api.studentUpdate(this.form).then((res) => {
                        if (res.success) {
                          this.$message.success("编辑成功");
                          this.getStudentList();
                          this.visible = false;
                        } else {
                          this.$message.error(res.message);
                        }
                      });
                    }
                  } catch (error) {
                    this.$message.error("上传失败");
                  }
                }
              });
            }
          }
        }
      });
    },
    //下载模板
    downloadTemplate() {
      this.downloadVisible = true;
    },
    //下载excel模板
    downloadExecl() {
      this.$api.getStudentModel().then((res) => {
        if (res.success) {
          const elink = document.createElement("a");
          elink.style.display = "none";
          elink.href = res.data.url;
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    },
    //批量导入
    addAll() {
      this.excelVisible = true;
      this.confirmLoading = false;
      this.fileList = [];
      this.addForm = {};
    },
    //移除选择的文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //文件状态改变
    handleChange(info) {
      this.$nextTick(() => {
        if (this.fileList.length > 0) {
          this.$refs.file.clearValidate();
          this.addForm.file = info.file;
        } else {
          this.addForm.file = null;
        }
      });
    },
    //上传后提交前的操作
    beforeUploadFile(file) {
      if (this.fileList.length > 0) {
        this.$message.error("您只能选择一个文件上传，请删除后继续上传");
        return false;
      } else {
        let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const isExcel = fileExtension === "xlsx" || fileExtension === "xls";
        if (!isExcel) {
          this.$message.error("文件格式错误");
        }
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          this.$message.error("文件不能大于50M");
        }
        if (isLt50M && isExcel) {
          this.fileList = [...this.fileList, file];
        } else {
          this.fileList = [];
          this.$refs.file.validate();
        }
        return false;
      }
    },
    //批量导入确定
    addAllStudents() {
      this.$refs["form"].validateField("classId", (error) => {
        if (!error) {
          if (this.fileList.length > 0) {
            this.confirmLoading = true;
            let formData = new FormData();
            formData.append("classId", this.addForm.classId);
            formData.append("file", this.addForm.file);
            this.$api.studentAddAll(formData).then((res) => {
              console.log(res);
              if (res.success) {
                this.$message.success("上传成功");
                this.excelVisible = false;
                this.confirmLoading = false;
                this.getStudentList();
              } else {
                this.fileList = [];
                this.$refs.file.validate();
                this.confirmLoading = false;
                this.$message.error(res.message);
              }
            });
          } else {
            this.$message.error("请上传文件");
          }
        }
      });
    },
    //删除学生
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.studentDel({ id: record.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("删除成功");
              this.getStudentList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //批量删除
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            this.$api.studentDelAll(this.selectedRowKeys).then((res) => {
              if (res.success) {
                this.$message.success("批量删除成功");
                this.getStudentList();
              } else {
                this.$message.error(res.message);
              }
            });
          },
        });
      } else {
        this.$message.error("请选择你想删除的学生");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //分页器页数改变
    onChange(pageNumber) {
      // console.log("Page: ", pageNumber);
      this.queryForm.pageNum = pageNumber;
      this.getStudentList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取学生列表
    getStudentList() {
      this.$api.getStudentList(this.queryForm).then((res) => {
        if (res.success) {
          if (!res.data.list.length && this.queryForm.pageNum > 1) {
            this.queryForm.pageNum--;
            this.$api.getTeacherList(this.queryForm).then((res) => {
              this.studentData = res.data.list;
              this.totalCount = res.data.total;
            });
          } else {
            this.studentData = res.data.list;
            this.totalCount = res.data.total;
          }
        }
      });
    },
    // 根据不同的角色返回班级列表
    getQueryClassList() {
      this.$api.getQueryClassList({ roleCode: this.userInfo.roleCode }).then((res) => {
        if (res.success) {
          this.classList = res.data.list;
        }
      });
    },
  },
  computed: {
    //学生添加按钮的显示隐藏
    studentAdd() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("student_add")) {
        return true;
      } else {
        return false;
      }
    },
    //学生删除按钮的显示隐藏
    studentRemove() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("student_remove")) {
        return true;
      } else {
        return false;
      }
    },
    //学生编辑按钮的显示隐藏
    studentUpdate() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("student_update")) {
        return true;
      } else {
        return false;
      }
    },
    //学生下载模板的显示隐藏
    studentDownload() {
      let userInfo = this.$ls.get("userInfo");
      if (userInfo.permissionValueList.includes("student_download")) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    let userInfo = this.$ls.get("userInfo");
    this.userInfo = userInfo;
    this.getStudentList();
    this.getQueryClassList();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped src="./student.scss"></style>
<style lang="scss" scoped>
.student-model {
  .ant-form-item {
    display: flex;
    justify-content: center;
  }
}
.avatar-upload-preview {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(20%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
