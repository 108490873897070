<!--
 * @Author: 庄志莹
 * @Date: 2021-03-29 10:48:55
 * @LastEditTime: 2021-05-12 11:35:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\afterSales\tag\tag.vue
-->
<template>
  <div>
    <!--面包屑-->
    <BreadTag></BreadTag>

    <div class="container-tag">
      <div class="container-total">
        <!--身份标签总数-->
        <div class="title">
          <div class="title-text">标签（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="build-list">
          <!--搜索框和全删-->
          <div class="build-manage d-f j-b a-c">
            <div class="search"></div>
            <div>
              <a-button type="primary" @click="showModal">
                <a-icon type="plus" />添加标签
              </a-button>
            </div>
          </div>
          <!--添加标签的弹出框-->
          <a-modal
            v-model="visible"
            title="添加标签"
            @ok="hideModal"
            @cancel="cancelModal"
          >
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="标签名称" prop="tagName">
                <a-input v-model="form.tagName" placeholder="请输入标签名称" />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!--列表-->
          <div class="build-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="tagData"
              :pagination="false"
            >
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改标签信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="标签名称" prop="name">
                  <a-input v-model="editForm.tagName" placeholder="请输入标签名称" />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadTag from "~c/bread/BreadTag.vue";
const columns = [
  {
    title: "标签id",
    dataIndex: "id",
    width: "20%",
    align: "center",
  },
  {
    title: "标签名称",
    dataIndex: "tagName",
    width: "30%",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "gmtCreate",
    width: "20%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "30%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadTag,
  },
  data() {
    return {
      value: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      tagData: [],
      columns,
      visible: false,
      editVisible: false,
      form: {
        tagName: "",
      },
      rules: {
        tagName: [
          {
            required: true,
            message: "请输入标签名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editForm: {
        tagName: "",
      },
      editRules: {
        tagName: [
          {
            required: true,
            message: "请输入标签名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      totalCount: null,
    };
  },
  methods: {
    //搜索框
    onSearch() {
      this.getTagList();
    },
    //新增标签
    showModal() {
      this.visible = true;
    },
    //新增标签确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.$api.addContag(this.form).then((res) => {
            if (res.success) {
              this.$message.success("新增成功");
              this.getTagList();
              this.visible = false;
              this.form = {
                tagName: "",
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        tagName: "",
      };
    },
    //编辑标签
    edit(record) {
      this.editForm.tagName = record.tagName;
      this.editForm.id = record.id;
      // console.log(record);
      this.editVisible = true;
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          this.$api.updateContag(this.editForm).then((res) => {
            if (res.success) {
              this.$message.success("编辑成功");
              this.getTagList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        tagName: "",
      };
    },
    //删除标签
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.delContag({ id: record.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("删除成功");
              this.getTagList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //获取设备报修列表
    getTagList() {
      this.$api.getContagList().then((res) => {
        if (res.success) {
          this.tagData = res.data.list;
          this.totalCount = res.data.list.length;
        }
      });
    },
  },
  created() {
    this.getTagList();
  },
};
</script>

<style lang="scss" scoped src="./tag.scss"></style>
