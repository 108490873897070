<!--
 * @Author: 庄志莹
 * @Date: 2021-03-29 10:48:55
 * @LastEditTime: 2021-06-10 09:42:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\afterSales\tag\tag.vue
-->
<template>
  <div>
    <!--面包屑-->
    <BreadUpgrade></BreadUpgrade>

    <div class="container-upgrade">
      <div class="container-total">
        <!--软件升级总数-->
        <div class="title">
          <div class="title-text">软件版本</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="build-list">
          <!--上传按钮-->
          <div class="build-manage d-f j-b a-c">
            <div></div>
            <div>
              <a-button type="primary" @click="showModal">
                <a-icon type="plus" />上传版本
              </a-button>
            </div>
          </div>
          <!--上传版本的弹出框-->
          <a-modal
            v-model="visible"
            title="上传版本"
            @ok="hideModal"
            @cancel="cancelModal"
            :confirm-loading="confirmLoading"
          >
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="主板厂家" prop="motherBoardFactory">
                <a-select
                  v-model="form.motherBoardFactory"
                  placeholder="请选择主板厂家"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="item in motherBoardFactoryData"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.vname }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="主板型号" prop="motherBoardModel">
                <a-select
                  v-model="form.motherBoardModel"
                  placeholder="请选择主板型号"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="item in motherBoardModelData"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.vname }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="平台" prop="platform">
                <a-radio-group v-model="form.platform">
                  <a-radio
                    :value="item.value"
                    v-for="item in platformData"
                    :key="item.value"
                  >
                    {{ item.vname }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="软件类型" prop="softwareType">
                <a-select
                  v-model="form.softwareType"
                  placeholder="请选择软件类型"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="item in softwareTypeData"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.vname }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="软件版本" prop="softwareVersion">
                <a-select
                  v-model="form.softwareVersion"
                  placeholder="请选择软件版本"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="item in softwareVersionData"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.vname }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="最新版本" prop="isNew">
                <a-radio-group v-model="form.isNew">
                  <a-radio :value="true">是</a-radio>
                  <a-radio :value="false">否</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="环境" prop="environment">
                <a-select
                  v-model="form.environment"
                  placeholder="请选择环境"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="item in environmentData"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.vname }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 12, offset: 6 }">
                <a-upload
                  :file-list="fileList"
                  :remove="handleRemove"
                  :before-upload="beforeUpload"
                >
                  <a-button> <a-icon type="upload" /> 上传版本 </a-button>
                </a-upload>
              </a-form-model-item>
              <a-form-model-item label="软件版本号" prop="softwareVersionCode">
                <a-input
                  v-model="form.softwareVersionCode"
                  placeholder="请输入软件版本号"
                  disabled
                />
              </a-form-model-item>
              <a-form-model-item label="软件版本名称" prop="softwareVersionName">
                <a-input
                  v-model="form.softwareVersionName"
                  placeholder="请输入软件版本名称"
                  disabled
                />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!--列表-->
          <div class="build-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="upgradeData"
              :pagination="false"
              :scroll="{ x: 1900 }"
            >
              <span slot="platform" slot-scope="text">
                {{ platformFormat(text) }}
              </span>
              <span slot="motherBoardFactory" slot-scope="text">
                {{ motherBoardFactoryFormat(text) }}
              </span>
              <span slot="motherBoardModel" slot-scope="text">
                {{ motherBoardModelFormat(text) }}
              </span>
              <span slot="softwareType" slot-scope="text">
                {{ softwareTypeFormat(text) }}
              </span>
              <span slot="isNew" slot-scope="text, record">
                <a-switch :checked="text" @change="onChange(record)" />
              </span>
              <span slot="environment" slot-scope="text">
                {{ environmentFormat(text) }}
              </span>
              <span slot="softwareVersion" slot-scope="text">
                {{ softwareVersionFormat(text) }}
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="upload(record)">下载</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadUpgrade from "~c/bread/BreadUpgrade.vue";
import Client from "@/oss/client";
import md5 from "js-md5";
const columns = [
  {
    title: "平台",
    dataIndex: "platform",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "platform" },
  },
  {
    title: "主板厂家",
    dataIndex: "motherBoardFactory",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "motherBoardFactory" },
  },
  {
    title: "主板型号",
    dataIndex: "motherBoardModel",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "motherBoardModel" },
  },
  {
    title: "软件类型",
    dataIndex: "softwareType",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "softwareType" },
  },
  {
    title: "软件版本",
    dataIndex: "softwareVersion",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "softwareVersion" },
  },
  {
    title: "最新版本",
    dataIndex: "isNew",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "isNew" },
  },
  {
    title: "环境",
    dataIndex: "environment",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "environment" },
  },
  {
    title: "md5",
    dataIndex: "md5",
    width: 200,
    align: "center",
  },
  {
    title: "软件版本号",
    dataIndex: "softwareVersionCode",
    width: 200,
    align: "center",
    fixed: "right",
  },
  {
    title: "软件版本名称",
    dataIndex: "softwareVersionName",
    width: 200,
    align: "center",
    fixed: "right",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 300,
    align: "center",
    scopedSlots: { customRender: "action" },
    fixed: "right",
  },
];
export default {
  components: {
    BreadUpgrade,
  },
  data() {
    return {
      value: "",
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      upgradeData: [],
      columns,
      //上传的文件列表
      fileList: [],
      visible: false,
      form: {
        platform: undefined,
        motherBoardFactory: undefined,
        motherBoardModel: undefined,
        softwareType: undefined,
        softwareVersion: undefined,
        softwareVersionCode: undefined,
        softwareVersionName: undefined,
        isNew: true,
        environment: undefined,
      },
      rules: {
        platform: [
          {
            required: true,
            message: "请选择平台",
            trigger: ["change", "blur"],
          },
        ],
        motherBoardFactory: [
          {
            required: true,
            message: "请选择主板厂家",
            trigger: ["change", "blur"],
          },
        ],
        motherBoardModel: [
          {
            required: true,
            message: "请选择主板型号",
            trigger: ["change", "blur"],
          },
        ],
        softwareType: [
          {
            required: true,
            message: "请选择软件类型",
            trigger: ["change", "blur"],
          },
        ],
        softwareVersion: [
          {
            required: true,
            message: "请输入软件版本",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        softwareVersionCode: [
          {
            required: true,
            message: "请输入软件版本号",
            type: "number",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        softwareVersionName: [
          {
            required: true,
            message: "请输入软件版本名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        isNew: [
          {
            required: true,
            message: "请选择最新版本",
            trigger: ["change", "blur"],
          },
        ],
        environment: [
          {
            required: true,
            message: "请选择环境",
            trigger: ["change", "blur"],
          },
        ],
      },
      //平台参数数据
      platformData: "",
      //主板厂家参数数据
      motherBoardFactoryData: "",
      //主板型号参数数据
      motherBoardModelData: "",
      //软件类型参数数据
      softwareTypeData: "",
      //软件版本参数数据
      softwareVersionData: "",
      //环境参数数据
      environmentData: "",
      dataObj: {},
      confirmLoading: false,
      isBig: false,
      fileMD51: "",
      fileMD52: "",
    };
  },
  methods: {
    //获取Sts临牌
    getStsInfo() {
      //获取Token
      this.$api.getStsInfo().then((res) => {
        // console.log(res);
        if (res.success) {
          const {
            region,
            Bucket,
            AccessKeyId,
            AccessKeySecret,
            SecurityToken,
          } = res.data.data;
          this.dataObj = {
            region: region,
            bucket: Bucket,
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
          };
          return true;
        }
      });
    },
    //新增软件版本
    showModal() {
      this.visible = true;
    },
    //移除选择的版本
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    //上传后提交前的操作
    beforeUpload(action) {
      this.isBig = false;
      let index = action.name.lastIndexOf(".");
      // 判断上传的文件是否是 以apk结尾的apk文件
      if (action.name.substr(index + 1) == "apk") {
        this.fileList = [...this.fileList, action];
        let files = this.fileList[0];
        console.log(files);
        const ApkParser = require("app-info-parser/src/apk");
        const parser = new ApkParser(files); //file:所上传的文件内容
        parser
          .parse()
          .then((res) => {
            console.log(res); //打印获取所需apk文件信息
            this.form.softwareVersionCode = res.versionCode;
            this.form.softwareVersionName = res.versionName;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // 判断上传的文件是否是 以zip结尾的包文件
      else if (action.name.substr(index + 1) == "zip") {
        this.fileList = [...this.fileList, action];
        let files = this.fileList[0];
        let i = files.name.lastIndexOf("-");
        let fileName = files.name.substring(i + 1, index);
        let ii = fileName.indexOf(".");
        this.form.softwareVersionCode = Number(fileName.substring(ii + 1));
        this.form.softwareVersionName = fileName.substr(0, ii);
        let flagSize = 10 * 1024 * 1024;
        if (files.size < flagSize) {
          let fileRederInstance = new FileReader();
          fileRederInstance.readAsArrayBuffer(files);
          fileRederInstance.addEventListener("load", (e) => {
            let fileBolb = e.target.result;
            let fileMD5 = md5(fileBolb);
            this.form.md5 = fileMD5;
          });
        } else {
          this.isBig = true;
          let chunk = this.getChunkInfo(files);
          let fileRederInstance1 = new FileReader();
          let fileRederInstance2 = new FileReader();
          fileRederInstance1.readAsArrayBuffer(chunk.startChunk);
          fileRederInstance2.readAsArrayBuffer(chunk.endChunk);
          fileRederInstance1.addEventListener("load", (e) => {
            let fileBolb1 = e.target.result;
            let fileMD51 = md5(fileBolb1);
            this.fileMD51 = fileMD51;
          });
          fileRederInstance2.addEventListener("load", (event) => {
            let fileBolb2 = event.target.result;
            let fileMD52 = md5(fileBolb2);
            this.fileMD52 = fileMD52;
          });
        }
      } else {
        this.$message.error("请上传正确格式的文件");
      }
      return false;
    },
    //得到文件第一个5M和最后一个5M
    getChunkInfo(file) {
      const chunkSize = 5 * 1024 * 1024; // 5MB一片
      const startChunk = file.slice(0, chunkSize);
      const endChunk = file.slice(file.size - chunkSize);
      let chunk = {
        startChunk,
        endChunk,
      };
      return chunk;
    },
    //新增确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isBig) {
            this.form.md5 = md5(this.fileMD51 + this.fileMD52);
            console.log(this.form.md5);
          }
          if (this.form.platform === "Android" && this.form.md5 != null) {
            this.confirmLoading = true;

            let files = this.fileList[0];
            try {
              const client = Client(this.dataObj),
                file = files;
              //修改命名
              var fileExtension = file.name;
              let date = new Date().getTime();
              let realName = date + "_" + fileExtension;
              const random_name = "OTA/android/" + realName;
              client
                .multipartUpload(random_name, file, {
                  partSize: 5 * 1024 * 1024,
                  progress: (p) => {},
                })
                .then((result) => {
                  if (result.res.statusCode === 200) {
                    let params = JSON.parse(JSON.stringify(this.form));
                    params.filePath = random_name;
                    params.fileName = realName;
                    console.log(params);
                    this.$api.uploadVersion(params).then((res) => {
                      if (res.success) {
                        this.$message.success("上传成功");
                        this.confirmLoading = false;
                        this.visible = false;
                        this.$refs.ruleForm.resetFields();
                        this.fileList = [];
                        this.getVersionList();
                      } else {
                        this.$message.error(res.message);
                        this.confirmLoading = false;
                      }
                    });
                  }
                });
            } catch (error) {
              this.$message.error("上传阿里云失败");
              this.confirmLoading = false;
            }
          } else {
            this.$message.error("不是android或者md5为null");
          }
        }
      });
    },
    //取消新增
    cancelModal() {
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
    },
    //下载版本
    upload(record) {
      this.$api.downloadVersion({ id: record.id }).then((res) => {
        if (res.success) {
          const elink = document.createElement("a");
          elink.style.display = "none";
          elink.href = res.data.url;
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    },
    //删除版本
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.delVersion({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getVersionList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //按钮状态改变
    onChange(record) {
      this.$confirm({
        title: "您确认改变最新版本状态吗",
        onOk: () => {
          this.$api.changeVersion({ id: record.id, isNew: !record.isNew }).then((res) => {
            if (res.success) {
              this.$message.success("成功");
              this.getVersionList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //获取软件版本列表
    getVersionList() {
      this.$api.getVersionList().then((res) => {
        if (res.success) {
          this.upgradeData = res.data.list;
        }
      });
    },
    //平台翻译
    platformFormat(value) {
      let vname = "";
      if (this.platformData.length > 0) {
        this.platformData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //主板厂家翻译
    motherBoardFactoryFormat(value) {
      let vname = "";
      if (this.motherBoardFactoryData.length > 0) {
        this.motherBoardFactoryData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //主板型号翻译
    motherBoardModelFormat(value) {
      let vname = "";
      if (this.motherBoardModelData.length > 0) {
        this.motherBoardModelData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //软件类型翻译
    softwareTypeFormat(value) {
      let vname = "";
      if (this.softwareTypeData.length > 0) {
        this.softwareTypeData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //软件版本翻译
    softwareVersionFormat(value) {
      let vname = "";
      if (this.softwareVersionData.length > 0) {
        this.softwareVersionData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
    //环境翻译
    environmentFormat(value) {
      let vname = "";
      if (this.environmentData.length > 0) {
        this.environmentData.forEach((r) => {
          if (r.value == value) {
            vname = r.vname;
          }
        });
      }
      return vname;
    },
  },
  created() {
    //主板厂家的参数
    this.$api.getValues({ paramName: "motherBoardFactory" }).then((res) => {
      if (res.success) {
        this.motherBoardFactoryData = res.data.list;
      }
    });
    //主板型号的参数
    this.$api.getValues({ paramName: "motherBoardModel" }).then((res) => {
      if (res.success) {
        this.motherBoardModelData = res.data.list;
      }
    });
    //平台的参数
    this.$api.getValues({ paramName: "platform" }).then((res) => {
      if (res.success) {
        this.platformData = res.data.list;
      }
    });
    //软件类型的参数
    this.$api.getValues({ paramName: "softwareType" }).then((res) => {
      if (res.success) {
        this.softwareTypeData = res.data.list;
      }
    });
    //软件版本的参数
    this.$api.getValues({ paramName: "softwareVersion" }).then((res) => {
      if (res.success) {
        this.softwareVersionData = res.data.list;
      }
    });
    //环境的参数
    this.$api.getValues({ paramName: "environment" }).then((res) => {
      if (res.success) {
        this.environmentData = res.data.list;
      }
    });
    this.getStsInfo();
    this.getVersionList();
  },
};
</script>

<style lang="scss" scoped src="./upgrade.scss"></style>
