<template>
  <div>
    <a-breadcrumb class="bread">
      <a-breadcrumb-item class="bread-left">权限管理</a-breadcrumb-item>
      <a-breadcrumb-item class="bread-right">菜单管理</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.bread {
  width: 100%;
  height: 54px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 24px;
  box-sizing: border-box;
  background: #ffffff;
}
.bread-left {
  font-size: 14px;
  font-family: "PingFang SC";
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}
.bread-right {
  font-size: 14px;
  font-family: "PingFang SC";
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
</style>
