/*
 * @Author: 庄志莹
 * @Date: 2021-04-08 19:37:19
 * @LastEditTime: 2021-04-09 15:01:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\equipment\device\task.js
 */
export function getCron(type,cycleType,time,week,date) {
  if (type) {
    //每天
    if (cycleType === 1) {
      let timeCurrent = time;
      let hh = timeCurrent.substr(0, 2);
      let mm = timeCurrent.substr(3, 2);
      let ss = timeCurrent.substr(6, 2);
      if (hh[0] === "0") {
        hh = hh[1];
      }
      if (mm[0] === "0") {
        mm = mm[1];
      }
      if (ss[0] === "0") {
        ss = ss[1];
      }
      let res = ss + " " + mm + " " + hh + " " + "*" + " " + "*" + " " + "?";
      return res
    } 
    //工作日，周一到周五
    else if (cycleType === 2) {
      let timeCurrent = time;
      let hh = timeCurrent.substr(0, 2);
      let mm = timeCurrent.substr(3, 2);
      let ss = timeCurrent.substr(6, 2);
      if (hh[0] === "0") {
        hh= hh[1];
      }
      if (mm[0] === "0") {
        mm = mm[1];
      }
      if (ss[0] === "0") {
        ss = ss[1];
      }
      let res = ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "2-6";
      return res
    } 
    //工作日，周一到周五
    else if (cycleType === 3) {
      let timeCurrent = time;
      let hh = timeCurrent.substr(0, 2);
      let mm = timeCurrent.substr(3, 2);
      let ss = timeCurrent.substr(6, 2);
      if (hh[0] === "0") {
        hh= hh[1];
      }
      if (mm[0] === "0") {
        mm = mm[1];
      }
      if (ss[0] === "0") {
        ss = ss[1];
      }
      let res = ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + "7-1";
      return res
    } 
    else {
      let timeCurrent = time;
      let hh = timeCurrent.substr(0, 2);
      let mm = timeCurrent.substr(3, 2);
      let ss = timeCurrent.substr(6, 2);
      if (hh[0] === "0") {
        hh = hh[1];
      }
      if (mm[0] === "0") {
        mm = mm[1];
      }
      if (ss[0] === "0") {
        ss = ss[1];
      }
      let week1 = week+1;
      let res = ss + " " + mm + " " + hh + " " + "?" + " " + "*" + " " + week1;
      return res
    }
  } 
  else {
    let date1 = date;
      let year = date1.substr(0, 4);
      let month = date1.substr(5, 2);
      let dd = date1.substr(8, 2);
      if (month[0] === "0") {
        month = month[1];
      }
      if (dd[0] === "0") {
        dd = dd[1];
      }
      let timeCurrent = time;
      let hh = timeCurrent.substr(0, 2);
      let mm = timeCurrent.substr(3, 2);
      let ss = timeCurrent.substr(6, 2);
      if (hh[0] === "0") {
        hh = hh[1];
      }
      if (mm[0] === "0") {
        mm = mm[1];
      }
      if (ss[0] === "0") {
        ss = ss[1];
      }
      let res =
        ss + " " + mm + " " + hh + " " + dd + " " + month + " " + "?" + " " + year;
        return res
  }
}