<template>
  <div>
    <!--面包屑-->
    <BreadParamsData></BreadParamsData>

    <div class="container-dictionary-data">
      <div class="container-total">
        <!--返回上一级-->
        <div class="title">
          <div class="back" @click="back">&lt;返回</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="build-list">
          <!--搜索框和添加参数还有全删-->
          <div class="build-manage d-f j-b a-c">
            <div></div>
            <div>
              <a-button type="primary" @click="showModal">
                <a-icon type="plus" />添加数据
              </a-button>
            </div>
          </div>
          <!--添加数据的弹出框-->
          <a-modal
            v-model="visible"
            title="添加数据"
            @ok="hideModal"
            @cancel="cancelModal"
          >
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="排序" prop="sort">
                <a-input v-model="form.sort" placeholder="请输入排序" type="number" />
              </a-form-model-item>
              <a-form-model-item label="数据名称" prop="vname">
                <a-input v-model="form.vname" placeholder="请输入数据名称" />
              </a-form-model-item>
              <a-form-model-item label="数据值" prop="value">
                <a-input v-model="form.value" placeholder="请输入数据值" />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!--列表-->
          <div class="build-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="paramsData"
              :pagination="false"
            >
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改数据信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="排序" prop="sort">
                  <a-input
                    v-model="editForm.sort"
                    placeholder="请输入排序"
                    type="number"
                  />
                </a-form-model-item>
                <a-form-model-item label="数据名称" prop="vname">
                  <a-input v-model="editForm.vname" placeholder="请输入数据名称" />
                </a-form-model-item>
                <a-form-model-item label="数据值" prop="value">
                  <a-input v-model="editForm.value" placeholder="请输入数据值" />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadParamsData from "~c/bread/BreadParamsData.vue";
const columns = [
  {
    title: "数据排序",
    dataIndex: "sort",
    width: "10%",
    align: "center",
  },
  {
    title: "数据名称",
    dataIndex: "vname",
    width: "20%",
    align: "center",
  },
  {
    title: "数据值",
    dataIndex: "value",
    width: "25%",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "gmtCreate",
    width: "20%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadParamsData,
  },
  props: ["id"],
  data() {
    return {
      value: "",
      visible: false,
      editVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        sort: "",
        vname: "",
        value: "",
      },
      editForm: {
        sort: "",
        vname: "",
        value: "",
      },
      rules: {
        sort: [
          {
            required: true,
            message: "请输入排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        vname: [
          {
            required: true,
            message: "请输入数据名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        value: [
          {
            required: true,
            message: "请输入数据值",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        sort: [
          {
            required: true,
            message: "请输入排序",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        vname: [
          {
            required: true,
            message: "请输入数据名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        value: [
          {
            required: true,
            message: "请输入数据值",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      paramsData: [],
      columns,
    };
  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },
    //新增数据
    showModal() {
      this.visible = true;
    },
    //新增数据确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.form.sort = Number(this.form.sort);
          this.form.paramId = this.id;
          this.$api.addParamValue(this.form).then((res) => {
            if (res.success) {
              this.$message.success("新增成功");
              this.getParamValueList();
              this.visible = false;
              this.form = {
                value: "",
                vname: "",
                sort: "",
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        value: "",
        vname: "",
        sort: "",
      };
    },
    //编辑参数
    edit(record) {
      this.editForm.value = record.value;
      this.editForm.vname = record.vname;
      this.editForm.sort = record.sort + "";
      this.editForm.id = record.id;
      this.editForm.paramId = record.paramId;
      this.editVisible = true;
    },
    //删除参数
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          // console.log(record);
          this.$api.delParamValue({ id: record.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("删除成功");
              this.getParamValueList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          // console.log(this.editForm);
          this.editForm.sort = Number(this.editForm.sort);
          this.$api.updateParamValue(this.editForm).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("编辑成功");
              this.getParamValueList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        value: "",
        vname: "",
        sort: "",
      };
    },
    //获取参数列表
    getParamValueList() {
      this.$api
        .getParamValueList({
          id: this.id,
        })
        .then((res) => {
          if (res.success) {
            this.paramsData = res.data.list;
          }
        });
    },
  },
  created() {
    this.getParamValueList();
  },
};
</script>

<style lang="scss" scoped src="./paramsData.scss"></style>
