<template>
  <div>
    <!--面包屑-->
    <BreadBuild></BreadBuild>

    <div class="container-build">
      <div class="container-total">
        <!--楼栋总数-->
        <div class="title">
          <div class="title-text">楼栋（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="build-list">
          <!--搜索框和添加楼栋还有全删-->
          <div class="build-manage d-f j-b a-c">
            <div class="search">
              <a-input-search
                placeholder="请输入楼栋名称"
                style="width: 188px"
                @search="onSearch"
                v-model="value"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <a-button type="primary" @click="showModal"
                ><a-icon type="plus" /> 添加楼栋
              </a-button>
              <a-button @click="delAll" class="del-all"> 批量删除 </a-button>
            </div>
          </div>
          <!--添加楼栋的弹出框-->
          <a-modal
            v-model="visible"
            title="添加楼栋"
            @ok="hideModal"
            @cancel="cancelModal"
          >
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="楼栋名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入楼栋名称" />
              </a-form-model-item>
              <a-form-model-item label="楼层数" prop="totalFloor">
                <a-input v-model="form.totalFloor" placeholder="请输入楼层数" />
              </a-form-model-item>
              <a-form-model-item label="教室数" prop="totalRoom">
                <a-input v-model="form.totalRoom" placeholder="请输入教室数" />
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!--列表-->
          <div class="build-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="buildData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改楼栋信息"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="楼栋名称" prop="name">
                  <a-input v-model="editForm.name" placeholder="请输入楼栋名称" />
                </a-form-model-item>
                <a-form-model-item label="楼层数" prop="totalFloor">
                  <a-input v-model="editForm.totalFloor" placeholder="请输入楼层数" />
                </a-form-model-item>
                <a-form-model-item label="教室数" prop="totalRoom">
                  <a-input v-model="editForm.totalRoom" placeholder="请输入教室数" />
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadBuild from "~c/bread/BreadBuild.vue";
const columns = [
  {
    title: "楼栋名称",
    dataIndex: "name",
    width: "25%",
    align: "center",
  },
  {
    title: "楼层数（层）",
    dataIndex: "totalFloor",
    width: "25%",
    align: "center",
  },
  {
    title: "教室数（个）",
    dataIndex: "totalRoom",
    width: "25%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadBuild,
  },
  data() {
    return {
      value: "",
      visible: false,
      editVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        totalFloor: null,
        totalRoom: null,
      },
      editForm: {
        name: "",
        totalFloor: null,
        totalRoom: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入楼栋名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "楼栋名称不能超过20个字符",
            trigger: ["change", "blur"],
          },
        ],
        totalFloor: [
          {
            required: true,
            message: "请输入楼层数",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^([1-9][0-9]{0,2})$/;
              if (!reg.test(value)) {
                callbackFn("楼层数只能输入3位以内的正整数");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        totalRoom: [
          {
            required: true,
            message: "请输入教室数",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^([1-9][0-9]{0,3})$/;
              if (!reg.test(value)) {
                callbackFn("教室数只能输入4位以内的正整数");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        name: [
          {
            required: true,
            message: "请输入楼栋名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "姓名不能超过20个字符",
            trigger: ["change", "blur"],
          },
        ],
        totalFloor: [
          {
            required: true,
            message: "请输入楼层数",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^([1-9][0-9]{0,2})$/;
              if (!reg.test(value)) {
                callbackFn("楼层数只能输入3位以内的正整数");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
        totalRoom: [
          {
            required: true,
            message: "请输入教室数",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^([1-9][0-9]{0,3})$/;
              if (!reg.test(value)) {
                callbackFn("教室数只能输入4位以内的正整数");
                return;
              }
              callbackFn();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      buildData: [],
      columns,
      selectedRowKeys: [],
      record: {},
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
    };
  },
  methods: {
    //搜索框
    onSearch() {
      if (this.value.length > 20) {
        this.$message.error("搜索内容保持在20个字符以内");
      } else {
        // console.log(this.value);
        this.getBuildList();
      }
    },
    //新增楼栋
    showModal() {
      this.visible = true;
    },
    //新增楼栋确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.$api.buildAdd(this.form).then((res) => {
            if (res.success) {
              this.$message.success("新增成功");
              this.getBuildList();
              this.visible = false;
              this.form = {
                name: "",
                totalFloor: null,
                totalRoom: null,
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        name: "",
        totalFloor: null,
        totalRoom: null,
      };
    },
    //编辑楼栋
    edit(record) {
      this.editForm.name = record.name;
      this.editForm.totalFloor = record.totalFloor + "";
      this.editForm.totalRoom = record.totalRoom + "";
      this.editForm.id = record.id;
      // console.log(record);
      this.record = record;
      this.editVisible = true;
    },
    //删除楼栋
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          // console.log(record);
          this.$api.buildDel({ id: record.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("删除成功");
              this.getBuildList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          // console.log(this.editForm);
          this.$api.buildUpdate(this.editForm).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("编辑成功");
              this.getBuildList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        name: "",
        totalFloor: null,
        totalRoom: null,
      };
    },
    //全删
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            // console.log(this.selectedRowKeys);
            this.$api.buildDelAll(this.selectedRowKeys).then((res) => {
              // console.log(res);
              if (res.success) {
                this.$message.success("批量删除成功");
                this.getBuildList();
              } else {
                this.$message.error(res.message);
              }
            });
          },
        });
      } else {
        this.$message.error("请选择你想删除的楼栋");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getBuildList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取楼栋列表
    getBuildList() {
      this.$api
        .getBuildList({
          page: this.pageIndex,
          limit: this.pageSize,
          search: this.value,
        })
        .then((res) => {
          if (res.success) {
            if (!res.data.list.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getBuildList({
                  page: this.pageIndex,
                  limit: this.pageSize,
                  search: this.value,
                })
                .then((res) => {
                  this.totalCount = res.data.total;
                  this.buildData = res.data.list;
                });
            } else {
              this.totalCount = res.data.total;
              this.buildData = res.data.list;
            }
          }
        });
    },
  },
  created() {
    this.getBuildList();
  },
};
</script>

<style lang="scss" scoped src="./build.scss"></style>
