<!--
 * @Author: 庄志莹
 * @Date: 2021-02-04 09:24:34
 * @LastEditTime: 2021-04-12 16:57:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\equipment\info-push\file-push\file-push.vue
-->
<template>
  <div>
    <!-- 文件下发 -->
    <!-- 文件下发的整体页面-->
    <div v-if="waitPage">
      <!--搜索框和日期还有全删-->
      <div class="d-f a-c search">
        <div class="search-content d-f a-c">
          <div class="modal-region">
            <a-range-picker @change="selectDate2" />
          </div>
          <a-input-search
            placeholder="请输入文件名称关键字"
            style="width: 200px; margin-left: 12px"
            @change="onSearch2"
            v-model="value2"
          />
        </div>
      </div>
      <!-- 文件下发的表格和分页 -->
      <div class="done-page">
        <!--表格-->
        <a-table :columns="columns2" rowKey="id" :data-source="data2" :pagination="false">
          <span slot="fileName" slot-scope="text">
            <span :title="text | handeleFileName">{{ text | handeleFileName }}</span>
          </span>
          <span slot="status" slot-scope="text, record">
            <a-badge status="success" />{{ record.successCount }}台成功
            <span style="margin-left: 10px"></span>
            <a-badge status="error" />{{ record.failureCount }}台失败
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="downLoad(record)">下载</a>
            <a-divider type="vertical" />
            <a @click="desc2(record)">查看详情</a>
          </span>
        </a-table>
        <!--分页-->
        <div class="pagination d-f a-c">
          <a-pagination
            v-model="pageIndex2"
            :pageSize="pageSize2"
            :default-current="1"
            :total="totalCount2"
            :hideOnSinglePage="true"
            showLessItems
            @change="onChange2"
            :item-render="itemRender"
            class="pagination"
          />
        </div>
      </div>
    </div>

    <!--文件下发查看详情的弹出框-->
    <a-modal
      v-model="visible2"
      title="查看详情"
      :body-style="{ height: '300px', overflowY: 'auto' }"
      :footer="null"
      @cancel="hideVisible2"
      class="modal-status"
    >
      <button @click="unnormalToo" ref="unnormalToo" class="btn unnormal">异常</button>
      <button @click="successToo" ref="successToo" class="btn success">成功</button>
      <!-- 异常的表格 -->
      <div v-if="unnormalPageToo">
        <a-table
          :columns="columns21"
          rowKey="id"
          :data-source="data21"
          :pagination="false"
          size="small"
          :row-selection="rowSelectionToo"
        >
          <span slot="device" slot-scope="text, record">
            <a-badge :status="record.isClientOnline === true ? 'success' : 'error'" />{{
              record.buildingName + record.classroomName
            }}
          </span>
          <span slot="failureReason" slot-scope="text" style="color: red">
            {{ text }}
          </span>
          <span slot="way" slot-scope="text, record">
            <span v-if="record.isClientOnline === true"
              ><a @click="reonToo(record)">重发文件</a></span
            >
            <span v-else>——</span>
          </span>
        </a-table>
        <div class="all-reon-con">
          <a-button type="primary" class="all-reon" @click="allOnToo"
            >批量重发文件</a-button
          >
        </div>
      </div>
      <!-- 异常的表格 -->

      <!-- 成功的表格 -->
      <div v-if="successPageToo">
        <a-table
          :columns="columns22"
          rowKey="id"
          :data-source="data22"
          :pagination="false"
          size="small"
        >
          <span slot="device" slot-scope="text, record">
            {{ record.buildingName + record.classroomName }}
          </span>
          <span slot="isClientOnline" slot-scope="text">{{
            text | handeleIsClientOnline
          }}</span>
          <span slot="way"> —— </span>
        </a-table>
      </div>
      <!-- 成功的表格 -->
    </a-modal>
    <!-- 文件下发  -->
  </div>
</template>

<script>
const columns2 = [
  {
    title: "文件名",
    dataIndex: "fileName",
    width: "20%",
    align: "center",
    scopedSlots: { customRender: "fileName" },
    ellipsis: true,
  },
  {
    title: "发布者",
    dataIndex: "createUserName",
    width: "10%",
    align: "center",
  },
  {
    title: "执行时间",
    dataIndex: "gmtCreate",
    width: "20%",
    align: "center",
  },
  {
    title: "执行状态",
    dataIndex: "status",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const columns21 = [
  {
    title: "设备",
    dataIndex: "device",
    width: "30%",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "异常原因",
    dataIndex: "failureReason",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "failureReason" },
  },
  {
    title: "解决方案",
    dataIndex: "way",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "way" },
  },
];
const columns22 = [
  {
    title: "设备",
    dataIndex: "device",
    width: "30%",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "设备状态",
    dataIndex: "isClientOnline",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "isClientOnline" },
  },
  {
    title: "解决方案",
    dataIndex: "way",
    width: "35%",
    align: "center",
    scopedSlots: { customRender: "way" },
  },
];
export default {
  filters: {
    handeleIsClientOnline(val) {
      if (val === true) {
        return "在线";
      } else {
        return "离线";
      }
    },
    handeleFileName(val) {
      let i = val.indexOf("_");
      return val.substring(i + 1);
    },
  },
  computed: {
    rowSelectionToo() {
      const { selectedRowKeysToo } = this;
      return {
        selectedRowKeysToo,
        onChange: (selectedRowKeysToo) => {
          this.selectedRowKeysToo = selectedRowKeysToo;
        },
        getCheckboxProps: (record) => ({
          props: {
            // 全部默认禁止选中
            // disabled: true,
            // 某几项默认禁止选中(R: 当state等于1时)
            disabled: record.isClientOnline === false,
            // 某几项默认选中(R: 当state等于1时) // defaultChecked: record.state == 1,
          },
        }),
      };
    },
  },
  data() {
    return {
      //文件下发根据关键字搜索
      value2: "",
      //文件下发根据日期搜索
      startTime2: "",
      endTime2: "",
      //文件下发的页面显示
      waitPage: false,
      data2: [],
      columns2,
      totalCount2: null,
      pageIndex2: 1,
      pageSize2: 6,
      visible2: false,
      unnormalPageToo: true,
      successPageToo: false,
      columns21,
      data21: [],
      columns22,
      data22: [],
      selectedRowKeysToo: [],
      websocketToo: null,
      timerToo: null,
      recordToo: {},
    };
  },
  methods: {
    //文件下发日期选择器
    selectDate2(date, dateString) {
      this.startTime2 = dateString[0];
      this.endTime2 = dateString[1];
      this.getPushFileLog();
    },
    //文件下发搜索框
    onSearch2() {
      this.getPushFileLog();
    },
    //文件下发分页状态改变
    onChange2(pageNumber) {
      this.pageIndex2 = pageNumber;
      this.getPushFileLog();
    },
    //文件下发下载附件
    downLoad(record) {
      this.$api.getPushFileUrl({ fileName: record.fileName }).then((res) => {
        console.log(res);
        const elink = document.createElement("a");
        elink.style.display = "none";
        elink.href = res.data.url;
        // elink.download = record.fileName;
        document.body.appendChild(elink);
        elink.click();
        // URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      });
    },
    //文件下发查看详情
    desc2(record) {
      this.visible2 = true;
      this.recordToo = record;
      this.initWebSocketToo();
    },
    //关闭文件下发查看详情
    hideVisible2() {
      this.websocketcloseToo();
    },
    //文件下发的查看详情中异常按钮
    unnormalToo() {
      (this.unnormalPageToo = true), (this.successPageToo = false);
      this.$refs.successToo.classList.add("success");
      this.$refs.successToo.classList.remove("unnormal");
      this.$refs.unnormalToo.classList.add("unnormal");
      this.$refs.unnormalToo.classList.remove("success");
    },
    //文件下发的查看详情中成功按钮
    successToo() {
      (this.successPageToo = true), (this.unnormalPageToo = false);
      this.$refs.successToo.classList.add("unnormal");
      this.$refs.successToo.classList.remove("success");
      this.$refs.unnormalToo.classList.remove("unnormal");
      this.$refs.unnormalToo.classList.add("success");
    },
    //文件下发的查看详情中异常重发指令
    reonToo(record) {
      this.$confirm({
        title: "您确认重发文件吗",
        onOk: () => {
          let userInfo = this.$ls.get("userInfo");
          let obj3 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "发送文件下发重发文件",
              msgType: 13,
              data: { messageId: "", ids: [] },
            },
          };
          obj3.chatMsg.data.messageId = record.messageId;
          obj3.chatMsg.data.ids.push(record.id);
          console.log(obj3);
          this.websocketToo.send(JSON.stringify(obj3));
          this.websocketsendSecondToo();
        },
      });
    },
    //文件下发的查看详情异常批量重发指令
    allOnToo() {
      if (this.selectedRowKeysToo.length) {
        this.$confirm({
          title: "您确认批量重发文件吗",
          onOk: () => {
            let userInfo = this.$ls.get("userInfo");
            let obj3 = {
              action: 2,
              extand: "user",
              chatMsg: {
                senderId: userInfo.id,
                msg: "发送文件下发批量重发文件",
                msgType: 13,
                data: { messageId: "", ids: [] },
              },
            };
            obj3.chatMsg.data.messageId = this.recordToo.id;
            obj3.chatMsg.data.ids = this.selectedRowKeysToo;
            console.log(obj3);
            this.websocketToo.send(JSON.stringify(obj3));
            this.websocketsendSecondToo();
          },
        });
      } else {
        this.$message.error("请选择你想重发指令的设备");
      }
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取文件下拉列表
    getPushFileLog() {
      this.$api
        .getPushFileLog({
          page: this.pageIndex2,
          limit: this.pageSize2,
          search: this.value2,
          startTime: this.startTime2,
          endTime: this.endTime2,
        })
        .then((res) => {
          this.data2 = res.data.logList;
          this.totalCount2 = res.data.total;
          // console.log(res);
        });
    },
    //webscoket 初始化
    initWebSocketToo() {
      const wsurl = this.$globalVariable.wsurl; //ws 相当于http 而wss 相当于https
      this.websocketToo = new WebSocket(wsurl); //实例对象
      this.websocketToo.onmessage = this.websocketonmessageToo;
      this.websocketToo.onopen = this.websocketonopenToo;
      this.websocketToo.onerror = this.websocketonerrorToo;
      this.websocketToo.onclose = this.websocketcloseToo;
    },
    //连接建立
    websocketonopenToo() {
      console.log("前端连接建立成功");
      //连接建立之后执行send方法发送数据
      this.websocketsendFirstToo();
      this.websocketsendSecondToo();
      this.timerToo = setInterval(() => {
        this.doSendToo();
      }, 10 * 1000);
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSendToo() {
      console.log("发送心跳");
      this.websocketsendSecondToo();
    },
    //首次发送数据 建立连接
    websocketsendFirstToo() {
      console.log("首次向后端发送数据");
      let userInfo = this.$ls.get("userInfo");
      let obj1 = {
        action: 1,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          receiverId: "",
          msg: "建立连接",
          msgType: 0,
        },
      };
      this.websocketToo.send(JSON.stringify(obj1)); //发送数据，传什么数据由实际需求决定
    },
    //查看设备状态
    websocketsendSecondToo() {
      console.log("查询设备状态");
      let userInfo = this.$ls.get("userInfo");
      let obj2 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "查询设备状态",
          msgType: 12,
          data: {},
        },
      };
      obj2.chatMsg.data.pushFileLogId = this.recordToo.id;
      // console.log(obj2);
      this.websocketToo.send(JSON.stringify(obj2));
    },
    //数据接收
    websocketonmessageToo(e) {
      console.log("接收后端返回数据");
      var result = JSON.parse(e.data);
      console.log(result);
      if (result.data) {
        let data = result.data.detailList;
        if (data != undefined) {
          this.data21 = data.filter((r) => r.isSuccess === false);
          this.data22 = data.filter((r) => r.isSuccess === true);
        }
      }
    },
    //连接建立失败重连
    websocketonerrorToo() {
      this.initWebSocketToo();
    },
    //连接关闭
    websocketcloseToo(e) {
      console.log("websocket断开连接");
      clearInterval(this.timerToo);
    },
  },
  created() {
    this.getPushFileLog();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped src="./file-push.scss"></style>
