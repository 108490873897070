<template>
  <div>
    <!--面包屑-->
    <BreadSchool></BreadSchool>

    <div class="container-school">
      <div class="container-total">
        <!--学校总数-->
        <div class="title">
          <div class="title-text">学校管理（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="school-list">
          <!--搜索框和添加学校还有全删-->
          <div class="school-manage d-f j-b a-c">
            <div class="search">
              <a-input-search
                placeholder="请输入学校名称"
                style="width: 188px"
                v-model="value"
                @pressEnter="onSearch"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <a-button type="primary" @click="showModal"
                ><a-icon type="plus" />新增学校</a-button
              >

              <a-button @click="delAll" class="del-all"> 批量删除 </a-button>
              <!--新增学校的弹出框-->
              <a-modal
                v-model="visible"
                title="新增学校"
                @ok="hideModal"
                @cancel="cancelModal"
                class="school-modal"
              >
                <a-form-model
                  ref="ruleForm"
                  :model="form"
                  :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item label="名称" prop="schoolName">
                    <a-input v-model="form.schoolName" placeholder="请输入学校名称" />
                  </a-form-model-item>
                  <a-form-model-item label="地址" prop="address">
                    <a-input
                      v-model="form.address"
                      placeholder="请输入学校名或学校的详细地址"
                      @focus="focusAddress"
                      ><a-icon
                        slot="prefix"
                        type="search"
                        style="color: rgba(0, 0, 0, 0.25)"
                      />
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item>
                    <baidu-map
                      class="map"
                      :center="location"
                      :zoom="15"
                      :scroll-wheel-zoom="true"
                    >
                      <bm-geolocation
                        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                        :showAddressBar="true"
                        :autoLocation="true"
                      ></bm-geolocation>
                      <bm-local-search
                        :keyword="form.address"
                        :pageCapacity="3"
                        class="map-location"
                        @infohtmlset="searchComplete"
                        :panel="true"
                        :auto-viewport="true"
                      ></bm-local-search>
                    </baidu-map>
                  </a-form-model-item>
                </a-form-model>
              </a-modal>
            </div>
          </div>
          <!--列表-->
          <div class="school-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              :data-source="schoolData"
              :pagination="false"
              rowKey="id"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="schoolName" slot-scope="text">
                <span :title="text">{{ text }}</span>
              </span>
              <span slot="address" slot-scope="text">
                <span :title="text">{{ text }}</span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改学校"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="学校名称" prop="schoolName">
                  <a-input v-model="editForm.schoolName" placeholder="请输入学校名称" />
                </a-form-model-item>
                <a-form-model-item label="学校地址" prop="address">
                  <a-input
                    v-model="editForm.address"
                    placeholder="请输入学校名或学校的详细地址"
                    @focus="focusAddressToo"
                    ><a-icon
                      slot="prefix"
                      type="search"
                      style="color: rgba(0, 0, 0, 0.25)"
                    />
                  </a-input>
                </a-form-model-item>
                <a-form-model-item>
                  <baidu-map
                    class="map-too"
                    :center="location"
                    :zoom="15"
                    :scroll-wheel-zoom="true"
                  >
                    <bm-geolocation
                      anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                      :showAddressBar="true"
                      :autoLocation="true"
                    ></bm-geolocation>
                    <bm-local-search
                      :keyword="editForm.address"
                      :pageCapacity="3"
                      class="map-location-too"
                      @infohtmlset="searchCompleteToo"
                      :panel="true"
                      :auto-viewport="true"
                    ></bm-local-search>
                  </baidu-map>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadSchool from "~c/bread/BreadSchool.vue";
const columns = [
  {
    title: "名称",
    dataIndex: "schoolName",
    width: "15%",
    align: "center",
    scopedSlots: { customRender: "schoolName" },
    ellipsis: true,
  },
  {
    title: "地址",
    dataIndex: "address",
    width: "15%",
    align: "center",
    scopedSlots: { customRender: "address" },
    ellipsis: true,
  },
  {
    title: "学校编号",
    dataIndex: "schoolNum",
    width: "20%",
    align: "center",
  },
  {
    title: "总设备数（台）",
    dataIndex: "deviceCount",
    width: "15%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadSchool,
  },
  data() {
    return {
      location: {
        //经度
        lng: 116.404,
        //纬度
        lat: 39.915,
      },
      value: "",
      visible: false,
      editVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        schoolName: "",
        latitude: "",
        longitude: "",
        province: "",
        city: "",
        county: "",
        address: "",
      },
      editForm: {
        schoolName: "",
        latitude: "",
        longitude: "",
        province: "",
        city: "",
        county: "",
        address: "",
      },
      rules: {
        schoolName: [
          {
            required: true,
            message: "请输入学校名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        address: [
          {
            required: true,
            message: "请输入学校地址",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        schoolName: [
          {
            required: true,
            message: "请输入学校名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        address: [
          {
            required: true,
            message: "请输入学校地址",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      schoolData: [],
      columns,
      selectedRowKeys: [],
      record: {},
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
    };
  },
  methods: {
    //搜索框
    onSearch() {
      if (this.value.length > 20) {
        this.$message.error("搜索内容保持在20个字符以内");
      } else {
        // console.log(this.value);
        this.getSchoolList();
      }
    },
    //新增学校
    showModal() {
      this.visible = true;
    },
    //新增学校确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.$api.schoolAdd(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("新增成功");
              this.getSchoolList();
              this.visible = false;
              this.form = {
                schoolName: "",
                address: "",
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        schoolName: "",
        address: "",
      };
    },
    //编辑学校
    edit(record) {
      this.editForm.schoolName = record.schoolName;
      this.editForm.address = record.address;
      this.editForm.id = record.id;
      // console.log(record);
      this.editVisible = true;
    },
    //删除学校
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          // console.log(record.id);
          this.$api.schoolDel({ id: record.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("删除成功");
              this.getSchoolList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          // console.log(this.editForm);
          this.$api.schoolUpdate(this.editForm).then((res) => {
            if (res.success) {
              this.$message.success("编辑成功");
              this.getSchoolList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        schoolName: "",
        address: "",
      };
    },
    //全删
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            // console.log(this.selectedRowKeys);
            this.$api.schoolDelAll(this.selectedRowKeys).then((res) => {
              if (res.success) {
                this.$message.success("批量删除成功");
                this.getSchoolList();
              } else {
                this.$message.error(res.message);
              }
            });
          },
        });
      } else {
        this.$message.error("请选择你要删除的学校");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getSchoolList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //新建学校地图完成搜索后的回调
    searchComplete(e) {
      // console.log(e);
      this.form.latitude = e.point.lat;
      this.form.longitude = e.point.lng;
      // this.form.province = e.point.province;
      // this.form.longitude = e.point.city;
      if (e.province != null && e.city != null) {
        this.form.address = e.province + e.city + e.address;
        this.form.province = e.province;
        this.form.city = e.city;
        this.form.county = e.address;
      } else {
        if (e.province != null && e.city == null) {
          this.form.address = e.province + e.address;
          this.form.province = e.province;
          this.form.county = e.address;
        } else if (e.province == null && e.city != null) {
          this.form.address = e.city + e.address;
          this.form.city = e.city;
          this.form.county = e.address;
        } else {
          this.form.address = e.address;
          this.form.county = e.address;
        }
      }
      let mapLocation = document.getElementsByClassName("map-location");
      mapLocation[0].style = "display:none";
    },
    //编辑学校地图完成搜索后的回调
    searchCompleteToo(e) {
      // console.log(e);
      this.editForm.latitude = e.point.lat;
      this.editForm.longitude = e.point.lng;
      // this.form.province = e.point.province;
      // this.form.longitude = e.point.city;
      if (e.province != null && e.city != null) {
        this.editForm.address = e.province + e.city + e.address;
        this.editForm.province = e.province;
        this.editForm.city = e.city;
        this.editForm.county = e.address;
      } else {
        if (e.province != null && e.city == null) {
          this.editForm.address = e.province + e.address;
          this.editForm.province = e.province;
          this.editForm.county = e.address;
        } else if (e.province == null && e.city != null) {
          this.editForm.address = e.city + e.address;
          this.editForm.city = e.city;
          this.editForm.county = e.address;
        } else {
          this.editForm.address = e.address;
          this.editForm.county = e.address;
        }
      }
      let mapLocation = document.getElementsByClassName("map-location-too");
      mapLocation[0].style = "display:none";
    },
    //新建学校地图input获取焦点
    focusAddress() {
      let mapLocation = document.getElementsByClassName("map-location");
      mapLocation[0].style = "display:block";
    },
    //编辑学校地图input获取焦点
    focusAddressToo() {
      let mapLocation = document.getElementsByClassName("map-location-too");
      mapLocation[0].style = "display:block";
    },
    //获取所有学校列表
    getSchoolList() {
      this.$api
        .getSchoolList({
          page: this.pageIndex,
          limit: this.pageSize,
          search: this.value,
        })
        .then((res) => {
          if (!res.data.schools.length && this.pageIndex > 1) {
            this.pageIndex--;
            this.$api
              .getSchoolList({
                page: this.pageIndex,
                limit: this.pageSize,
                search: this.value,
              })
              .then((res) => {
                this.totalCount = res.data.total;
                this.schoolData = res.data.schools;
              });
          } else {
            this.totalCount = res.data.total;
            this.schoolData = res.data.schools;
          }
        });
    },
  },
  created() {
    this.getSchoolList();
  },
  mounted() {
    // console.log(this.$ls.get('token'))
  },
};
</script>

<style lang="scss" scoped src="./school.scss"></style>
