<!--
 * @Author: 庄志莹
 * @Date: 2020-12-26 17:01:31
 * @LastEditTime: 2021-04-22 20:06:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: 
-->
<template>
  <div>
    <!--面包屑-->
    <BreadLog></BreadLog>

    <div class="container-operation-log">
      <div class="container-total">
        <!--导航栏-->
        <div class="title d-f a-c">
          <nav>操作日志</nav>
        </div>
        <!--一根线条-->
        <div class="line"></div>

        <!--下边的部分-->
        <div>
          <!--日期和指令选择-->
          <div class="d-f a-c search">
            <div class="search-content d-f a-c">
              <a-form-model
                :model="statusForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                class="status-dev"
              >
                <a-form-model-item label="指令名称">
                  <a-select
                    v-model="statusForm.region"
                    @change="changeSelect"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option :value="0">全部</a-select-option>
                    <a-select-option :value="1">关机</a-select-option>
                    <a-select-option :value="2">息屏</a-select-option>
                    <a-select-option :value="3">重启</a-select-option>
                    <a-select-option :value="19">亮屏</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
              <div class="modal-region">
                <a-range-picker @change="selectDate" />
              </div>
            </div>
          </div>
          <!-- 操作日志的表格和分页 -->
          <div class="done-page">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :data-source="data"
              :pagination="false"
            >
              <span slot="device" slot-scope="text, record">
                {{ record.buildingName + record.classroomName }}
              </span>
              <span slot="status" slot-scope="text">
                {{ text | handeleSuccess }}
              </span>
            </a-table>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadLog from "~c/bread/BreadLog.vue";
const columns = [
  {
    title: "指令名称",
    dataIndex: "action",
    width: "20%",
    align: "center",
  },
  {
    title: "发布者",
    dataIndex: "userName",
    width: "20%",
    align: "center",
  },
  {
    title: "操作设备",
    dataIndex: "device",
    width: "20%",
    align: "center",
    scopedSlots: { customRender: "device" },
  },
  {
    title: "执行时间",
    dataIndex: "gmtCreate",
    width: "25%",
    align: "center",
  },
  {
    title: "执行状态",
    dataIndex: "status",
    width: "15%",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
];
export default {
  components: {
    BreadLog,
  },
  filters: {
    handeleSuccess(val) {
      if (val === true) {
        return "成功";
      } else {
        return "失败";
      }
    },
  },
  data() {
    return {
      //消息推送根据指令名称搜索
      statusForm: {
        region: 0,
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      //消息推送根据日期搜索
      startTime: "",
      endTime: "",
      //消息推送的页面显示
      data: [],
      columns,
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
    };
  },
  methods: {
    //操作日志日期选择器改变
    selectDate(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.getOperationLogList();
    },
    //操作日志指令名称改变
    changeSelect() {
      this.getOperationLogList();
    },
    //操作日志分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getOperationLogList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取操作日志列表
    getOperationLogList() {
      this.$api
        .getOperationLogList({
          page: this.pageIndex,
          limit: this.pageSize,
          operationType: this.statusForm.region,
          startTime: this.startTime,
          endTime: this.endTime,
        })
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.data = res.data.logList;
            this.totalCount = res.data.total;
          }
        });
    },
  },
  created() {
    this.getOperationLogList();
  },
};
</script>

<style lang="scss" scoped src="./operation-log.scss"></style>
