<template>
  <div>
    <div class="spaceapp-rank d-f j-b">
      <!--第三行左边-->
      <div class="spaceapp-rank-left">
        <div class="d-f j-b">
          <div class="title-date">设备在线时长排名</div>
          <a-range-picker
            class="rank-data-picker"
            @change="dataPicker"
            :allowClear="false"
            :defaultValue="[
              moment(getCurrentData(), 'YYYY-MM-DD'),
              moment(getCurrentData(), 'YYYY-MM-DD'),
            ]"
            :disabledDate="disabledDate"
          />
        </div>
        <div class="d-f spaceapp-rank-detail-total">
          <ul class="third-ul d-f f-d j-a">
            <li
              v-for="(item, index) in classroomList"
              :key="index"
              :class="[index < 3 ? 'li-active' : 'li-common']"
            >
              {{ index + 1 }}
            </li>
          </ul>
          <div class="spaceapp-rank-detail" ref="rankTime"></div>
        </div>
      </div>
      <!--第三行右边-->
      <div class="spaceapp-rank-right">
        <div class="d-f j-b">
          <div class="title-date">
            设备状态评分排名
            <a-tooltip placement="right">
              <template slot="title">
                <span>
                  设备状态评分 =
                  设备cpu利用率记录均值、设备内存利用率均值、设备硬盘使用情况均值做的加权算法运算
                </span>
              </template>
              <a-icon type="question-circle" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </div>
          <div>
            <button @click="down" ref="down" class="button-bac">降序</button>
            <button @click="up" ref="up">升序</button>
          </div>
          <a-range-picker
            class="rank-data-picker"
            @change="dataPicker1"
            :allowClear="false"
            :defaultValue="[
              moment(getCurrentData(), 'YYYY-MM-DD'),
              moment(getCurrentData(), 'YYYY-MM-DD'),
            ]"
            :disabledDate="disabledDate"
          />
        </div>
        <div class="last-rank-detail" ref="rankHealth"></div>
        <div class="export" @click="exportExcel" v-if="isXiaoniu"></div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require("echarts");
import moment from "moment";
export default {
  data() {
    return {
      startTime: moment().format("YYYY-MM-DD"),
      endTime: moment().format("YYYY-MM-DD"),
      startTime1: moment().format("YYYY-MM-DD"),
      endTime1: moment().format("YYYY-MM-DD"),
      classroomList: [],
      classroomList1: [],
      totalOnlineList: [],
      scoreList: [],
      timeData: [],
      order: "DESC",
    };
  },
  watch: {
    classroomList(val, oldVal) {
      this.classroomList = val;
      setTimeout(() => {
        this.myCharts3();
      });
    },
    classroomList1(val, oldVal) {
      this.classroomList1 = val;
      setTimeout(() => {
        this.myCharts4();
      });
    },
    scoreList(val, oldVal) {
      this.scoreList = val;
      setTimeout(() => {
        this.myCharts4();
      });
    },
    timeData(val, oldVal) {
      this.timeData = val;
      setTimeout(() => {
        this.myCharts3();
      });
    },
  },
  computed: {
    isXiaoniu() {
      let schoolNum = this.$ls.get("userInfo").schoolNum;
      return schoolNum === "888899";
    },
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    getCurrentData() {
      return new Date();
    },
    moment,
    dataPicker(dates, dateStrings) {
      this.startTime = dateStrings[0];
      this.endTime = dateStrings[1];
      this.getOnlineList();
    },
    dataPicker1(dates, dateStrings) {
      this.startTime1 = dateStrings[0];
      this.endTime1 = dateStrings[1];
      this.getScoreList();
    },
    myCharts3() {
      let myChart = echarts.init(this.$refs.rankTime);
      let option = {
        grid: {
          top: "1%",
          bottom: "-10%",
          left: "3%",
          right: "10%",
          containLabel: true,
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0],
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          inverse: true, //倒序
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(0,0,0,.45)",
            },
          },
          type: "category",
          data: this.classroomList,
        },
        series: [
          {
            type: "bar",
            emphasis: {
              itemStyle: {
                color: "rgba(90, 216, 166, 1)",
              },
            },
            data: this.totalOnlineList,
            barWidth: 14,
            z: 10,
            tooltip: { show: false },
            itemStyle: {
              // 柱子样式
              normal: {
                color: "rgba(90, 216, 166, 1)", // 柱状图颜色
                barBorderRadius: [20],
                legendHoverLink: false,
                label: {
                  show: true, // 显示文本
                  position: ["100%", 2], // 数据值位置
                  formatter: "{c}小时",
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.65)",
                    fontSize: 12,
                  },
                },
              },
            },
          },
          {
            type: "bar",
            emphasis: {
              itemStyle: {
                color: "rgba(219, 255, 224, 1)",
              },
            },
            data: this.timeData,
            tooltip: { show: false },
            barWidth: 14,
            barGap: "-100%", // 两个柱子之间的距离，如果要重叠设置为-100%
            itemStyle: {
              normal: {
                barBorderRadius: [20],
                color: "rgba(219, 255, 224, 1)", // 柱子颜色，作为底层背景
                label: {
                  show: false,
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    myCharts4() {
      let myChart = echarts.init(this.$refs.rankHealth);
      let option = {
        color: ["rgba(0, 159, 232, 1)"],
        grid: {
          left: "0",
          right: "0%",
          top: "13%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              show: false,
            },
            data: this.classroomList1,
            axisLabel: {
              interval: 0, //interval为x轴两相邻数据之间所包含数据条数，num为x轴总条数除以24所得结果
              show: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 26,
            data: this.scoreList,
            // showAllSymbol: true, //标注所有数据点
            itemStyle: {
              normal: {
                label: {
                  formatter: "{c}" + "分",
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "rgba(0, 0, 0, 0.65)",
                    fontSize: 12,
                  },
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    down() {
      this.$refs.down.classList.add("button-bac");
      this.$refs.up.classList.remove("button-bac");
      this.order = "DESC";
      this.getScoreList();
    },
    up() {
      this.$refs.up.classList.add("button-bac");
      this.$refs.down.classList.remove("button-bac");
      this.order = "ASC";
      this.getScoreList();
    },
    getOnlineList() {
      this.$api
        .getOnlineList({ startTime: this.startTime, endTime: this.endTime })
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.classroomList = res.data.list.map((r) => r.classroomName);
            let arr = res.data.list.map((r) => r.totalOnline);
            let arr1 = arr.map((r) => {
              if (r === null) {
                r = 0;
              } else {
                r = r.toFixed(1);
              }
              return r;
            });
            this.totalOnlineList = arr1;
            let arr2 = [];
            let startTime = new Date(this.startTime).getTime();
            let endTime = new Date(this.endTime).getTime();
            let days =
              (parseInt(endTime) - parseInt(startTime)) / (24 * 60 * 60 * 1000) + 1;
            for (let i = 0; i < this.totalOnlineList.length; i++) {
              arr2[i] = days * 24;
            }
            this.timeData = arr2;
          }
        });
    },
    getScoreList() {
      this.$api
        .getScoreList({
          startTime: this.startTime1,
          endTime: this.endTime1,
          order: this.order,
        })
        .then((res) => {
          if (res.success) {
            this.classroomList1 = res.data.list.map((r) => r.classroomName);
            this.scoreList = res.data.list.map((r) => r.score);
          }
        });
    },
    exportExcel() {
      const elink = document.createElement("a");
      elink.style.display = "none";
      elink.href =
        "https://xn-smart-platform.oss-cn-hangzhou.aliyuncs.com/dataAnalysis/%E6%95%B0%E6%8D%AE%E7%BB%9F%E8%AE%A1.xlsx";
      document.body.appendChild(elink);
      elink.click();
      document.body.removeChild(elink);
    },
  },
  mounted() {
    setTimeout(() => {
      this.myCharts3();
      this.myCharts4();
      const resizeOb = new ResizeObserver((entries) => {
        for (const entry of entries) {
          echarts.getInstanceByDom(entry.target).resize();
        }
      });
      resizeOb.observe(this.$refs.rankTime);
      resizeOb.observe(this.$refs.rankHealth);
    });
  },
  created() {
    this.getOnlineList();
    // this.getScoreList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-calendar-picker-input {
  font-size: 12px;
  height: 22px;
  line-height: 12px;
}
.spaceapp-rank {
  height: 254px;
  width: 100%;
  margin-top: 24px;
  position: relative;
  .spaceapp-rank-left {
    width: 31.3%;
    min-width: 367px;
    height: 100%;
    background: #ffffff;
    opacity: 1;
    border-radius: 16px;
    padding: 16px;
    .title-date {
      font-size: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    .rank-data-picker {
      display: block;
      z-index: 99;
      width: 180px;
      background: #ffffff;
      opacity: 1;
      color: #d9dee5;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
    }
    // >>> deep .ant-input {
    //   z-index: 999;
    //   font-size: 12px;
    // }
    .spaceapp-rank-detail-total {
      height: 210px;
      width: 100%;
      margin-left: 3px;
      .spaceapp-rank-detail {
        width: 93%;
        height: 100%;
      }
      .third-ul {
        height: 100%;
        li {
          line-height: 19px;
          text-align: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          &.li-common {
            background: #f0f2f5;
            color: rgba(0, 0, 0, 0.65);
          }
          &.li-active {
            background: rgba(0, 159, 232, 1);
            color: #fff;
          }
        }
      }
    }
  }
  .spaceapp-rank-right {
    width: 67.5%;
    min-width: 793px;
    height: 100%;
    padding: 16px;
    background: #ffffff;
    opacity: 1;
    border-radius: 16px;
    button {
      width: 48px;
      height: 21px;
      background: rgba(232, 234, 238, 1);
      border: none;
      outline: none;
      border-radius: 2px 0px 0px 2px;
      &.button-bac {
        background-color: rgba(0, 159, 232, 1);
        color: #fff;
      }
    }
    .title-date {
      font-size: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    .rank-data-picker {
      display: block;
      z-index: 99;
      width: 180px;
      background: #ffffff;
      opacity: 1;
      color: #d9dee5;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
    }
    // >>> deep .ant-input {
    //   z-index: 999;
    //   font-size: 12px;
    // }
    .last-rank-detail {
      height: 95%;
      width: 100%;
    }
    .export {
      position: absolute;
      right: -10px;
      top: -40px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: url("~@/assets/img/analysis_export.png") no-repeat 50%, 50% #009fe8;
      cursor: pointer;
    }
  }
}
</style>
