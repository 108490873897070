<template>
  <div>
    <!--面包屑-->
    <BreadClass></BreadClass>

    <div class="container-class">
      <div class="container-total">
        <!--班级总数-->
        <div class="title">
          <div class="title-text">班级（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="class-list">
          <!--搜索框和添加班级还有全删-->
          <div class="class-manage d-f j-b a-c">
            <div class="search">
              <a-input-search
                placeholder="请输入班级名称"
                style="width: 188px"
                @search="onSearch"
                v-model="value"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <a-button type="primary" @click="showModal"
                ><a-icon type="plus" /> 添加班级
              </a-button>
              <a-button @click="delAll" class="del-all"> 批量删除 </a-button>
            </div>
          </div>
          <!--添加班级的弹出框-->
          <a-modal
            v-model="visible"
            title="添加班级"
            @ok="hideModal"
            @cancel="cancelModal"
          >
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="班级名称" prop="xnClassname">
                <a-input
                  v-model="form.xnClassname"
                  placeholder="建议“一年级1班”，“一年级（一）班”"
                />
              </a-form-model-item>
              <a-form-model-item label="学段" prop="rankId">
                <a-select
                  v-model="form.rankId"
                  placeholder="请选择学段"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  @change="getGrade"
                >
                  <a-select-option :value="item.id" :key="item.id" v-for="item in rank">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="年级" prop="gradeId">
                <a-select
                  v-model="form.gradeId"
                  placeholder="请选择学段后选择年级"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option :value="item.id" :key="item.id" v-for="item in grade">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="班级" prop="classNumId">
                <a-select
                  v-model="form.classNumId"
                  placeholder="请选择班级"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.id"
                    :key="item.id"
                    v-for="item in classNum"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="教室所在楼栋">
                <a-select
                  v-model="form.buildingId"
                  placeholder="请选择楼栋"
                  @change="selectClassroom"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option value=""> 不选择楼栋 </a-select-option>
                  <a-select-option
                    :value="item.id"
                    :key="item.id"
                    v-for="item in buildNum"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="绑定教室">
                <a-select
                  v-model="form.classroomId"
                  placeholder="请选择楼栋后再选择教室"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option value=""> 不选择教室 </a-select-option>
                  <a-select-option
                    :value="item.id"
                    :key="item.id"
                    v-for="item in classroomNum"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!--列表-->
          <div class="class-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              :data-source="classData"
              :pagination="false"
              rowKey="id"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="classroomName" slot-scope="text, record">
                <span v-if="record.classroomName === null"
                  >无
                  <a @click="bind(record)">绑定教室</a>
                </span>
                <span v-else
                  >{{ text }}
                  <a @click="unbind(record)">解除绑定</a>
                </span>
              </span>
              <span slot="teacher" slot-scope="text, record">
                <span>
                  <a @click="bindTeacher(record)">查看详情</a>
                </span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="edit(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
            <!--绑定教室的弹出框-->
            <a-modal
              v-model="bindVisible"
              title="绑定教室"
              @ok="hidebind"
              @cancel="cancelbind"
            >
              <a-form-model
                ref="ruleBindForm"
                :model="bindForm"
                :rules="bindRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="教室所在楼栋" prop="buildingId">
                  <a-select
                    v-model="bindForm.buildingId"
                    placeholder="请选择楼栋"
                    @change="selectClassroomToo"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.id"
                      :key="item.id"
                      v-for="item in buildNum"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="绑定教室" prop="classroomId">
                  <a-select
                    v-model="bindForm.classroomId"
                    placeholder="请选择楼栋后再选择教室"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.id"
                      :key="item.id"
                      v-for="item in classroomNumToo"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--绑定老师的弹出框-->
            <a-modal
              v-model="bindTeacherVisible"
              title="绑定老师详情"
              :footer="null"
              :body-style="{ height: '400px', overflowY: 'auto' }"
              class="bind-teacher"
            >
              <div class="add-bind-teacher">
                <a-button type="primary" @click="addBindTeacher"
                  ><a-icon type="plus" />添加老师</a-button
                >
              </div>
              <!--表格-->
              <a-table
                :columns="columns1"
                :data-source="bindTeacherData"
                :pagination="false"
                rowKey="id"
                size="small"
              >
                <span slot="action" slot-scope="text, record">
                  <a @click="unbindTeacher(record)">解除绑定</a>
                </span>
              </a-table>
            </a-modal>
            <!--添加绑定老师的弹出框-->
            <a-modal
              v-model="addbindTeacherVisible"
              title="绑定老师"
              @ok="hideAddbindTeacher"
              @cancel="cancelAddbindTeacher"
            >
              <a-form-model
                ref="ruleAddbindTeacherForm"
                :model="addbindTeacherForm"
                :rules="addbindTeacherRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="选择老师" prop="teacherIds">
                  <a-select
                    mode="multiple"
                    v-model="addbindTeacherForm.teacherIds"
                    placeholder="请选择老师"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    optionFilterProp="children"
                  >
                    <a-select-option
                      :value="item.id"
                      :key="item.id"
                      v-for="item in teacherList"
                    >
                      {{ item.name + "（" + item.username + "）" }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--编辑的弹出框-->
            <a-modal
              v-model="editVisible"
              title="修改班级信息"
              ok-text="确认"
              cancel-text="取消"
              @ok="hideEdit"
              @cancel="cancelEdit"
            >
              <a-form-model
                ref="ruleEditForm"
                :model="editForm"
                :rules="editRules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="班级名称" prop="xnClassname">
                  <a-input
                    v-model="editForm.xnClassname"
                    placeholder="建议“一年级1班”，“一年级（一）班”"
                  />
                </a-form-model-item>
                <a-form-model-item label="学段" prop="rankId">
                  <a-select
                    v-model="editForm.rankId"
                    placeholder="请选择学段"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    @change="getGradeToo"
                  >
                    <a-select-option :value="item.id" :key="item.id" v-for="item in rank">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="年级" prop="gradeId">
                  <a-select
                    v-model="editForm.gradeId"
                    placeholder="请选择学段后选择年级"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.id"
                      :key="item.id"
                      v-for="item in gradeToo"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="班级" prop="classNumId">
                  <a-select
                    v-model="editForm.classNumId"
                    placeholder="请选择班级"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option
                      :value="item.id"
                      :key="item.id"
                      v-for="item in classNum"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadClass from "~c/bread/BreadClass.vue";
const columns = [
  {
    title: "班级名称",
    dataIndex: "xnClassname",
    width: "15%",
    align: "center",
  },
  {
    title: "学段",
    dataIndex: "rankName",
    width: "10%",
    align: "center",
  },
  {
    title: "年级",
    dataIndex: "gradeName",
    width: "10%",
    align: "center",
  },
  {
    title: "班级",
    dataIndex: "classNumName",
    width: "10%",
    align: "center",
  },
  {
    title: "绑定教室",
    dataIndex: "classroomName",
    width: "25%",
    align: "center",
    scopedSlots: { customRender: "classroomName" },
  },
  {
    title: "绑定老师",
    dataIndex: "teacher",
    width: "10%",
    align: "center",
    scopedSlots: { customRender: "teacher" },
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const columns1 = [
  {
    title: "老师姓名",
    dataIndex: "teacherName",
    width: "35%",
    align: "center",
  },
  {
    title: "老师用户名",
    dataIndex: "username",
    width: "35%",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "30%",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    BreadClass,
  },
  data() {
    return {
      value: "",
      visible: false,
      editVisible: false,
      bindVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        xnClassname: "",
        rankId: undefined,
        gradeId: undefined,
        classNumId: undefined,
        buildingId: undefined,
        classroomId: undefined,
      },
      editForm: {
        xnClassname: "",
        rankId: undefined,
        gradeId: undefined,
        classNumId: undefined,
        id: "",
      },
      bindForm: {
        buildingId: undefined,
        classroomId: undefined,
      },
      bindRules: {
        buildingId: [
          {
            required: true,
            message: "请选择绑定教室所在楼栋",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classroomId: [
          {
            required: true,
            message: "请选择绑定的教室",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      rules: {
        xnClassname: [
          {
            required: true,
            message: "请输入班级名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "班级名称不能超过20个字符",
            trigger: ["change", "blur"],
          },
        ],
        rankId: [
          {
            required: true,
            message: "请选择学段",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        gradeId: [
          {
            required: true,
            message: "请选择年级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classNumId: [
          {
            required: true,
            message: "请选择班级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      editRules: {
        xnClassname: [
          {
            required: true,
            message: "请输入班级名称",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 20,
            message: "班级名称不能超过20个字符",
            trigger: ["change", "blur"],
          },
        ],
        rankId: [
          {
            required: true,
            message: "请选择学段",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        gradeId: [
          {
            required: true,
            message: "请选择年级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
        classNumId: [
          {
            required: true,
            message: "请选择班级",
            whitespace: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      classData: [],
      columns,
      selectedRowKeys: [],
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
      rank: [],
      grade: [],
      gradeToo: [],
      classNum: [],
      buildNum: [],
      classroomNum: [],
      classroomNumToo: [],
      bindTeacherVisible: false,
      bindTeacherData: [],
      columns1,
      addbindTeacherVisible: false,
      addbindTeacherForm: {
        classId: "",
        teacherIds: undefined,
      },
      addbindTeacherRules: {
        teacherIds: [
          {
            required: true,
            message: "请选择老师",
            trigger: ["change", "blur"],
          },
        ],
      },
      teacherList: [],
    };
  },
  methods: {
    //搜索框
    onSearch() {
      if (this.value.length > 20) {
        this.$message.error("搜索内容保持在20个字符以内");
      } else {
        // console.log(this.value);
        this.getClassList();
      }
    },
    //新增班级
    showModal() {
      this.visible = true;
    },
    //新增班级确定
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.$api.classAdd(this.form).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("新增成功");
              this.getClassList();
              this.visible = false;
              this.form = {
                xnClassname: "",
                rankId: undefined,
                gradeId: undefined,
                classNumId: undefined,
                buildingId: undefined,
                classroomId: undefined,
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增
    cancelModal() {
      this.form = {
        xnClassname: "",
        rankId: undefined,
        gradeId: undefined,
        classNumId: undefined,
        buildingId: undefined,
        classroomId: undefined,
      };
    },
    //编辑班级
    edit(record) {
      this.editForm.classroomId = record.classroomId;
      this.editForm.xnClassname = record.xnClassname;
      this.editForm.rankId = record.rankId;
      this.editForm.gradeId = record.gradeId;
      this.editForm.classNumId = record.classNumId;
      this.editForm.id = record.id;
      this.$api.getGrade({ rankId: this.editForm.rankId }).then((res) => {
        if (res.success) {
          this.gradeToo = res.data.list;
          this.editVisible = true;
        }
      });
    },
    //删除班级
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          // console.log(record.id);
          this.$api.classDel({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getClassList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //确认编辑
    hideEdit() {
      this.$refs.ruleEditForm.validate((valid) => {
        if (valid) {
          // console.log(this.editForm);
          this.$api.classUpdate(this.editForm).then((res) => {
            if (res.success) {
              this.$message.success("编辑成功");
              this.getClassList();
              this.editVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消编辑
    cancelEdit() {
      this.editForm = {
        xnClassname: "",
        rankId: undefined,
        gradeId: undefined,
        classNumId: undefined,
      };
    },
    //全删
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            // console.log(this.selectedRowKeys);
            this.$api.classDelAll(this.selectedRowKeys).then((res) => {
              if (res.success) {
                this.$message.success("批量删除成功");
                this.getClassList();
              } else {
                this.$message.error(res.message);
              }
            });
          },
        });
      } else {
        this.$message.error("请选择你想删除的班级");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getClassList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取班级列表
    getClassList() {
      this.$api
        .getClassList({
          page: this.pageIndex,
          limit: this.pageSize,
          search: this.value,
        })
        .then((res) => {
          // console.log(res);
          if (res.success) {
            if (!res.data.xnClassList.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getClassList({
                  page: this.pageIndex,
                  limit: this.pageSize,
                  search: this.value,
                })
                .then((res) => {
                  this.classData = res.data.xnClassList;
                  this.totalCount = res.data.total;
                });
            } else {
              this.classData = res.data.xnClassList;
              this.totalCount = res.data.total;
            }
          }
        });
    },
    //获取学段
    getRank() {
      this.$api.getRank().then((res) => {
        if (res.success) {
          this.rank = res.data.list;
        }
      });
    },
    //新增获取年级
    getGrade() {
      this.form.gradeId = undefined;
      this.$api.getGrade({ rankId: this.form.rankId }).then((res) => {
        if (res.success) {
          this.grade = res.data.list;
        }
      });
    },
    //编辑获取年级
    getGradeToo() {
      this.editForm.gradeId = undefined;
      this.$api.getGrade({ rankId: this.editForm.rankId }).then((res) => {
        if (res.success) {
          console.log(res.data.list);
          this.gradeToo = res.data.list;
        }
      });
    },
    //获取班级
    getClass() {
      this.$api.getClass().then((res) => {
        if (res.success) {
          this.classNum = res.data.list;
        }
      });
    },
    //获取楼栋
    getBuild() {
      this.$api.getBuildList().then((res) => {
        // console.log(res);
        if (res.success) {
          this.buildNum = res.data.buildingList;
        }
      });
    },
    //新增班级时楼栋改变时获取教室
    selectClassroom() {
      (this.form.classroomId = undefined),
        this.$api
          .getUnbindClassroomList({ buildingId: this.form.buildingId })
          .then((res) => {
            if (res.success) {
              this.classroomNum = res.data.classrooms;
            }
          });
    },
    //班级绑定时楼栋改变时获取教室
    selectClassroomToo() {
      this.bindForm.classroomId = undefined;
      this.$api
        .getUnbindClassroomList({ buildingId: this.bindForm.buildingId })
        .then((res) => {
          if (res.success) {
            this.classroomNumToo = res.data.classrooms;
          }
        });
    },
    //班级绑定教室
    bind(record) {
      this.bindVisible = true;
      this.bindForm.classId = record.id;
      delete this.bindForm.buildingId;
    },
    //解除班级绑定教室
    unbind(record) {
      this.$confirm({
        title: "您确认解除绑定吗",
        onOk: () => {
          // console.log(record);
          this.$api.classUnbind({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success("解绑成功");
              this.getClassList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //班级绑定教室确定
    hidebind(record) {
      this.$refs.ruleBindForm.validate((valid) => {
        if (valid) {
          // console.log(this.bindForm);
          this.$api.classBind(this.bindForm).then((res) => {
            if (res.success) {
              this.$message.success("绑定成功");
              this.getClassList();
              this.bindVisible = false;
              this.bindForm = {
                buildingId: undefined,
                classroomId: undefined,
              };
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //班级绑定教室取消
    cancelbind() {
      this.bindVisible = false;
      this.bindForm = {
        buildingId: undefined,
        classroomId: undefined,
      };
    },
    //绑定老师查看详情
    bindTeacher(record) {
      this.addbindTeacherForm.classId = record.id;
      this.getBindTeacher();
      this.bindTeacherVisible = true;
    },
    //绑定某班级的老师列表
    getBindTeacher() {
      this.$api.getBindTeacher({ id: this.addbindTeacherForm.classId }).then((res) => {
        if (res.success) {
          this.bindTeacherData = res.data.list;
        }
      });
    },
    //老师解绑班级
    unbindTeacher(record) {
      this.$confirm({
        title: "您确认解除绑定吗",
        onOk: () => {
          this.$api
            .deleteBindTeacher({
              userId: record.teacherId,
              classId: this.addbindTeacherForm.classId,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("解绑成功");
                this.getBindTeacher();
              } else {
                this.$message.error(res.message);
              }
            });
        },
      });
    },
    //添加绑定老师
    addBindTeacher() {
      this.addbindTeacherVisible = true;
      this.getAllSchoolTeachers();
    },
    //添加绑定老师确定
    hideAddbindTeacher() {
      this.$refs.ruleAddbindTeacherForm.validate((valid) => {
        if (valid) {
          this.$api.addbindTeacher(this.addbindTeacherForm).then((res) => {
            if (res.success) {
              this.$message.success("添加成功");
              this.addbindTeacherVisible = false;
              this.getBindTeacher();
              this.addbindTeacherForm.teacherIds = undefined;
            } else {
              this.$message.success(res.message);
            }
          });
        }
      });
    },
    //添加绑定老师取消
    cancelAddbindTeacher() {
      this.addbindTeacherForm.teacherIds = undefined;
    },
    //获取老师列表
    getAllSchoolTeachers() {
      let userInfo = this.$ls.get("userInfo");
      this.$api
        .getAllSchoolTeachers({
          schoolNum: userInfo.schoolNum,
        })
        .then((res) => {
          if (res.success) {
            this.teacherList = res.data.list;
          }
        });
    },
  },
  created() {
    this.getClassList();
    this.getRank();
    this.getClass();
    this.getBuild();
  },
};
</script>

<style lang="scss" scoped src="./class.scss"></style>
