<template>
  <div>
    <!--面包屑-->
    <BreadRepair></BreadRepair>

    <div class="container-repair">
      <div class="container-total">
        <!--报修记录总数-->
        <div class="title">
          <div class="title-text">报修（{{ totalCount }}个）</div>
        </div>
        <!--一根线条-->
        <div class="line"></div>
        <!--下面的部分-->
        <div class="build-list">
          <!--搜索框和报修填写还有全删-->
          <div class="build-manage d-f j-b a-c">
            <div class="search d-f a-c">
              <a-form-model
                :model="statusForm"
                :label-col="labelCol1"
                :wrapper-col="wrapperCol1"
                class="modal"
              >
                <a-form-model-item label="解决状态">
                  <a-select
                    v-model="statusForm.region"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  >
                    <a-select-option :value="0">未解决</a-select-option>
                    <a-select-option :value="1">已解决</a-select-option>
                    <a-select-option :value="2">全部</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
              <a-input-search
                placeholder="请输入故障描述"
                style="width: 188px; margin-left: 40px"
                @search="onSearch"
                v-model="value"
              />
              <a-button type="primary" @click="onSearch">查询</a-button>
            </div>
            <div>
              <a-button @click="delAll" class="del-all"> 批量删除 </a-button>
            </div>
          </div>
          <!--列表-->
          <div class="build-space-list">
            <!--表格-->
            <a-table
              :columns="columns"
              rowKey="id"
              :scroll="{ x: 1400 }"
              :data-source="repairData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <span slot="schoolName" slot-scope="text">
                <span :title="text">{{ text }}</span>
              </span>
              <span slot="description" slot-scope="text">
                <span :title="text">{{ text }}</span>
              </span>
              <span slot="repairStatus" slot-scope="text">
                <span style="color: #ff4d4f" v-if="text === false">
                  {{ text | handleRepairStatus }}
                </span>
                <span style="color: #52c41a" v-else>{{ text | handleRepairStatus }}</span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="seeDetail(record)"> 查看详情 </a>
                <a-divider type="vertical" />
                <a @click="del(record)">删除</a>
              </span>
            </a-table>
            <!-- 查看图片/视频 -->
            <a-modal v-model="visible" title="查看详情" :footer="null">
              <div class="repair-note-detail d-f f-d">
                <div>
                  <p>故障描述</p>
                  <div class="desc">{{ desc }}</div>
                </div>
                <div v-if="images.length > 0">
                  <p>图片</p>
                  <div class="images d-f">
                    <img
                      :src="item"
                      v-for="item in images"
                      :key="item"
                      @click="seeBigImage(item)"
                    />
                  </div>
                </div>
                <div v-if="videos.length > 0">
                  <p>视频</p>
                  <div class="videos">
                    <div class="videos" v-for="item in videos" :key="item">
                      <video
                        :src="item"
                        controls="controls"
                        height="200px"
                        width="300px"
                      ></video>
                    </div>
                  </div>
                </div>
                <div class="enlarge-and-upload" v-if="enlargeVisible">
                  <div class="name-and-close d-f a-c j-b">
                    <div></div>
                    <div @click="closeBigImage">
                      <img src="@/assets/img/enlarge_close.png" />
                    </div>
                  </div>
                  <div class="line"></div>
                  <div class="enlarge-space d-f a-c j-c">
                    <img :src="src" />
                  </div>
                </div>
              </div>
            </a-modal>
            <!--分页-->
            <div class="pagination d-f a-c">
              <a-pagination
                v-model="pageIndex"
                :pageSize="pageSize"
                :default-current="1"
                :total="totalCount"
                :hideOnSinglePage="true"
                showLessItems
                @change="onChange"
                :item-render="itemRender"
                class="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadRepair from "~c/bread/BreadRepair.vue";
const columns = [
  {
    title: "学校名称",
    dataIndex: "schoolName",
    width: 150,
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "schoolName" },
  },
  {
    title: "设备编号",
    dataIndex: "deviceMark",
    width: 200,
    align: "center",
  },
  {
    title: "产品名称",
    dataIndex: "deviceName",
    width: 200,
    align: "center",
  },
  {
    title: "联系人",
    dataIndex: "linkManName",
    width: 100,
    align: "center",
  },
  {
    title: "故障描述",
    dataIndex: "description",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "description" },
    ellipsis: true,
  },
  {
    title: "期望服务时间",
    dataIndex: "serTime",
    width: 150,
    align: "center",
  },
  {
    title: "解决状态",
    dataIndex: "repairStatus",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "repairStatus" },
    fixed: "right",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 250,
    align: "center",
    scopedSlots: { customRender: "action" },
    fixed: "right",
  },
];
export default {
  components: {
    BreadRepair,
  },
  filters: {
    handleRepairStatus(val) {
      if (val === true) {
        return "已解决";
      } else {
        return "未解决";
      }
    },
  },
  data() {
    return {
      value: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      statusForm: {
        region: 2,
      },
      labelCol1: { span: 10 },
      wrapperCol1: { span: 14 },
      repairData: [],
      columns,
      selectedRowKeys: [],
      visible: false,
      desc: "",
      videos: [],
      images: [],
      totalCount: null,
      pageIndex: 1,
      pageSize: 6,
      src: "",
      enlargeVisible: false,
    };
  },
  methods: {
    //搜索框
    onSearch() {
      this.getRepairList();
    },
    //删除报修记录
    del(record) {
      this.$confirm({
        title: "您确认删除吗",
        onOk: () => {
          this.$api.delNotes({ id: record.id }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getRepairList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    //查看图片或视频
    seeDetail(record) {
      this.images = [];
      this.videos = [];
      this.desc = record.description;
      this.visible = true;
      this.$api
        .getMediaUrl({ imageIds: record.images, videoIds: record.videos })
        .then((res) => {
          if (res.success) {
            this.images = res.data.images;
            this.videos = res.data.videos;
          }
        });
    },
    //查看图片大图
    seeBigImage(item) {
      this.enlargeVisible = true;
      this.src = item;
    },
    //关闭图片大图
    closeBigImage() {
      this.enlargeVisible = false;
    },
    //全删
    delAll() {
      if (this.selectedRowKeys.length) {
        this.$confirm({
          title: "您确认删除吗",
          onOk: () => {
            this.$api.delNotesAll(this.selectedRowKeys).then((res) => {
              if (res.success) {
                this.$message.success("批量删除成功");
                this.getRepairList();
              } else {
                this.$message.error(res.message);
              }
            });
          },
        });
      } else {
        this.$message.error("请选择你想删除的报修记录");
      }
    },
    //复选框状态改变
    onSelectChange(selectedRowKeys, records) {
      // console.log(selectedRowKeys);
      // console.log(records);
      this.selectedRowKeys = selectedRowKeys;
    },
    //分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getRepairList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //获取设备报修列表
    getRepairList() {
      this.$api
        .getRepairList({
          page: this.pageIndex,
          limit: this.pageSize,
          search: this.value,
          status: this.statusForm.region,
        })
        .then((res) => {
          if (res.success) {
            if (!res.data.records.length && this.pageIndex > 1) {
              this.pageIndex--;
              this.$api
                .getRepairList({
                  page: this.pageIndex,
                  limit: this.pageSize,
                  search: this.value,
                  status: this.statusForm.region,
                })
                .then((res) => {
                  this.repairData = res.data.records;
                  this.totalCount = res.data.total;
                });
            } else {
              this.repairData = res.data.records;
              this.totalCount = res.data.total;
            }
          }
        });
    },
  },
  created() {
    this.getRepairList();
  },
};
</script>

<style lang="scss" scoped src="./repair.scss"></style>
