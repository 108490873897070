<!--
 * @Author: 庄志莹
 * @Date: 2020-12-09 10:20:35
 * @LastEditTime: 2021-03-16 17:57:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\components\analysis\analysisFirstLine.vue
-->
<template>
  <div>
    <ul class="d-f j-b a-c first-ul">
      <li class="first-li">
        <div class="detail-title">设备总数（台）</div>
        <div class="num">{{ obj.totalCount }}</div>
        <img src="@/assets/img/analysis11.png" alt="" />
      </li>
      <li class="second-li">
        <div class="detail-title">在线终端（台）</div>
        <div class="num">{{ obj.onlineCount }}</div>
        <img src="@/assets/img/analysis22.png" alt="" />
      </li>
      <li class="third-li">
        <div class="detail-title">故障终端（台）</div>
        <div class="num">{{ obj.failureCount }}</div>
        <img src="@/assets/img/analysis33.png" alt="" />
      </li>
      <li class="fourth-li">
        <div class="detail-title">用户数量（人）</div>
        <div class="num">{{ obj.userCount }}</div>
        <img src="@/assets/img/analysis44.png" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    obj: Object,
  },
  watch: {
    obj(val, oldVal) {
      let isEqual = JSON.stringify(val) == JSON.stringify(oldVal);
      if (!isEqual) {
        this.obj = val;
      }
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.first-ul {
  width: 100%;
  height: 168px;
  margin-bottom: 0;
}
li {
  width: 23.7%;
  height: 168px;
  min-width: 278px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0;
  position: relative;
  img {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 46px;
    height: 36px;
  }
}
.first-li {
  background-image: url(~@/assets/img/analysis1.png);
}
.second-li {
  background-image: url(~@/assets/img/analysis2.png);
}
.third-li {
  background-image: url(~@/assets/img/analysis3.png);
}
.fourth-li {
  background-image: url(~@/assets/img/analysis4.png);
}
.detail-title {
  height: 22px;
  margin-left: 16px;
  margin-top: 24px;
  font-size: 16px;
  font-family: "PingFang SC";
  font-weight: 400;
  line-height: 25px;
  color: #ffffff;
  opacity: 1;
}
.num {
  margin-left: 16px;
  margin-top: 22px;
  font-size: 38px;
  font-family: Arial;
  font-weight: 400;
  line-height: 59px;
  color: #ffffff;
  opacity: 1;
}
</style>
