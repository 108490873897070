<template>
  <div>
    <a-form id="components-form-demo-normal-login" :form="form" class="login-form">
      <a-form-item>
        <a-input
          v-decorator="[
            'mobile',
            {
              rules: [
                {
                  required: true,
                  message: '请输入你的手机号!',
                },
                {
                  pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                  message: '不是正确的手机格式!',
                },
              ],
            },
          ]"
          placeholder="手机号"
        >
          <a-icon slot="prefix" type="mobile" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-decorator="[
            'code',
            {
              rules: [
                { required: true, message: '请输入验证码!' },
                {
                  pattern: /^\d{4}$/,
                  message: '验证码格式不正确!',
                },
              ],
            },
          ]"
          class="CaptchaIpnt"
          type="code"
          placeholder="验证码"
        >
          <a-icon slot="prefix" type="code" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
        <div class="authCodeBtn-login">
          <a-button @click="sendVerifyCode" v-if="isSend">获取验证码</a-button>
          <a-button v-if="!isSend" disabled>{{ count }}s后可再次发送</a-button>
        </div>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="handleLogin()" class="login-form-button">
          下一步
        </a-button>
      </a-form-item>
      <div class="d-f a-c j-c">
        <span class="back-login" @click="backLogin">返回登录</span>
      </div>
    </a-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 60,
      isSend: true,
      timer: null,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    //发送验证码
    sendVerifyCode() {
      this.form.validateFields(["mobile"], (err, values) => {
        if (!err) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //验证手机号已经注册过
            this.$api.isExist({ phone: values.mobile }).then((res) => {
              // console.log(res)
              if (res.success) {
                //发送短信验证码
                this.$api
                  .sendSmsCode({
                    phone: values.mobile,
                    smsCodeType: 2,
                  })
                  .then((res) => {
                    // console.log(res)
                    this.$message.info(res.message);
                    return;
                  });
              } else {
                this.$message.error("该手机号还没被注册过");
              }
            });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //下一步
    handleLogin() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values);
          //验证手机号和验证码是否匹配
          this.$api
            .verifyCode({
              phone: values.mobile,
              smsCode: values.code,
              smsCodeType: 2,
            })
            .then((res) => {
              if (res.success) {
                this.$store.state.phone = values.mobile;
                this.$parent.showFromSgin();
              } else {
                this.$message.error(res.message);
              }
            });
        }
      });
    },
    //返回登录
    backLogin() {
      this.$router.push("/login");
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>
<style scoped>
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.CaptchaIpnt {
  width: 200px !important;
}
.authCodeBtn-login {
  position: absolute;
  top: -8px;
  right: -150px;
}
.login-form-forgot {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #009fe8;
  opacity: 1;
  text-decoration: none !important;
}
.login-form-button {
  width: 348px;
  height: 36px;
  font-size: 14px;
  line-height: 14px;
  margin-top: 20px;
  background: #009fe8;
  border-color: #009fe8;
  border-radius: 18px;
}
.back-login {
  font-size: 12px;
  font-weight: 400;
  color: #009fe8;
  cursor: pointer;
}
</style>
